import {
    TrackingAction,
    TrackingArea,
    TrackingCategory,
} from '@tracking-lib/tracking.model'

export const getEventName = (
    category: TrackingCategory,
    area: TrackingArea,
    action: TrackingAction
) => category + '.' + area + '.' + action
