import { Dayjs } from 'dayjs'

//-------------------------
//  Group
//-------------------------

export interface Group {
    groupId: string
    groupName: string
    isDefault: boolean
    isMember: boolean
    lastSynchronizedAt?: string // Date
    memberDetails: MemberDetails
    resources: Resources
    period: AccessPeriod
}

export interface GroupMetaData {
    id: string
    name: string
    isSelected: boolean
    isDefault: boolean
}

export interface MinimalGroup {
    id: string
    name: string
    isDefault: boolean
    isSynchronizedGroup?: boolean
}

export enum GroupSource {
    TOKEN = 'TOKEN',
    BACKEND = 'BACKEND',
}

//-------------------------
//  Colleague
//-------------------------

export interface Colleague {
    id: string
    firstName: string
    lastName: string
    email: string
    isAdmin: boolean
}

//-------------------------
//  Resource
//-------------------------

export interface Resources {
    wagons: Wagons
}

export interface Wagons {
    totalCount: number
    items: WagonItem[]
}

export interface WagonItem {
    uicClass: string
    count: number
}

//-------------------------
//  Wagon
//-------------------------

export interface Wagon {
    wagonId: string
    wagonNumber: string
    uicClass: string
    selected?: boolean
}

//-------------------------
//  Geofence
//-------------------------

export interface Geofence {
    geofenceId: string
    name: string
    selected: boolean
}

//-------------------------
//  Period
//-------------------------

export interface AccessPeriod {
    beginOn?: string
    endOn?: string
}

export interface ParsedAccessPeriod {
    beginOn: Dayjs | null
    endOn: Dayjs | null
}

//-------------------------
//  Member / User
//-------------------------

export interface User {
    userId: string
    firstName: string
    lastName: string
    changeable: boolean
    selected: boolean
}

export interface Member extends Omit<User, 'changeable' | 'selected'> {
    isAdmin: boolean
}

export interface MemberDetails {
    containsAllUsers: boolean
    members: Member[]
}

//-------------------------
//  Requests
//-------------------------

export interface CreateGroupRequest {
    name: string
}

export interface EditGroupRequest {
    name: string
}

export interface ChangeMembersRequest {
    members: UserId[]
}

export interface ChangeGroupResourcesRequest {
    resources: ResourceId[]
    resourceType: ResourceType
}

export enum ResourceType {
    WAGON = 'WAGON',
    GEOFENCE = 'GEOFENCE',
}

export interface ChangeAccessPeriodRequest {
    beginOn: string | null
    endOn: string | null
}

export interface ChangeGroupSelectionRequest {
    groups: GroupSelection[]
}

//-------------------------
//  Responses
//-------------------------

//group management - header
export interface MyGroupsMetadataResponse {
    groups: GroupMetaData[]
}

//group management - use case, creator, editor
export interface MyColleaguesResponse {
    users: Colleague[]
}

//group management - use case, creator, editor
export interface MyGroupsWithResourcesResponse {
    hasSynchronizedGroups: boolean
    groups: Group[]
}

// group management - creator, editor
export interface PossibleWagonsResponse {
    wagons: Wagon[]
}

// group management - editor
export interface SelectedWagonsResponse {
    wagons: Wagon[]
}

// group management - creator
export interface CreateGroupResponse {
    id: string
    name: string
}

//-------------------------
//  Components
//-------------------------

//group management - creator, editor
export interface UserSelection extends Member {
    isMember: boolean
}

// group management - wagon number input
export interface SelectedWagon extends Wagon {
    hasError: boolean
    isLoading: boolean
    isInEditMode: boolean
}

export interface WagonId {
    id: string
}

export interface UserId {
    id: string
}

export interface ResourceId {
    id: string
}

// group management - dropdown
export interface GroupSelection {
    id: string
    selected: boolean
}

// group management - creator / editor
export interface MyAvailableResources {
    periodVisible: boolean
    periodChangeable: boolean
    usersVisible: boolean
    users: User[]
    resources: {
        wagonsChangeable: boolean
        wagons: Wagon[]
        geofencesChangeable: boolean
        geofences: Geofence[]
    }
    // only set if requested from backend with group id
    period?: AccessPeriod
    groupId?: string
    groupName?: string
    groupNameChangeable?: boolean
    isSynchronized?: boolean
    defaultGroup?: boolean
}

//-------------------------
//  Table
//-------------------------

export enum GroupManagementColumnId {
    NAME = 'NAME',
    SYNCHRONIZATION = 'SYNCHRONIZATION',
    WAGONS = 'WAGONS',
    USERS = 'USERS',
    EDIT = 'EDIT',
}
