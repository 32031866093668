<div class="flex flex-col p-8 h-full">
    <div class="flex justify-between items-center">
        <div class="flex pb-4">
            <span translate class="pr-1 text-body-3">Wagons</span>
        </div>
    </div>

    @if (isDefaultGroup$ | async) {
        <app-inline-message [inlineMessageType]="InlineMessageType.INFO">
            <div content>
                <translate>AdminAllWagons</translate>
            </div>
        </app-inline-message>
    } @else {
        @if (
            (wagonsChangeable$ | async) &&
            (isSynchronizedGroup$ | async) === false
        ) {
            <span class="text-accent-medium pb-4 pt-2" translate>
                WagonNumberInputHint
            </span>
            <form>
                <mat-form-field appearance="outline" class="pb-4">
                    <input
                        matInput
                        type="text"
                        [formControl]="wagonNumberInputControl"
                        [errorStateMatcher]="matcher"
                        [matAutocomplete]="autocomplete"
                        (paste)="onPaste($event)"
                    />
                    <mat-label translate
                        >Enter or paste wagon numbers</mat-label
                    >
                    <mat-autocomplete
                        autoActiveFirstOption
                        #autocomplete="matAutocomplete"
                        (optionSelected)="
                            selectWagonFromSuggestion($event.option.value)
                        "
                    >
                        @for (
                            option of autocompleteSuggestions$ | async;
                            track option.value
                        ) {
                            <mat-option class="suggestion" [value]="option">
                                {{ option.label | uicWagonNumber }}
                            </mat-option>
                        }

                        @if (
                            (validSingleWagonInput$ | async) &&
                            (autocompleteSuggestions$ | async)?.length === 0
                        ) {
                            <mat-option disabled class="empty">
                                <span translate>No matches found</span>
                            </mat-option>
                        }
                    </mat-autocomplete>

                    @if (wagonNumberInputControl?.value) {
                        <app-button
                            matSuffix
                            aria-label="clear search input"
                            (buttonClick)="clearWagonInput()"
                            [trackingData]="{
                                category: trackingBaseData.category,
                                area: trackingBaseData.area,
                                action: GroupManagementAction.wagonNumberFieldReset,
                            }"
                            icon="close"
                            iconButton
                        >
                        </app-button>
                    }

                    @if (wagonNumberInputControl?.hasError('maxLength')) {
                        <mat-error>
                            <translate
                                >A wagon number can't have more than 12
                                digits</translate
                            >
                        </mat-error>
                    } @else if (wagonNumberInputControl?.hasError('pattern')) {
                        <mat-error>
                            <translate>
                                Only numbers from 0-9 and - are allowed as
                                inputs.
                            </translate>
                        </mat-error>
                    } @else if (validateMinLength(getSanitizedControlValue())) {
                        <mat-hint>
                            <translate
                                >Please enter at least 3 digits</translate
                            >
                        </mat-hint>
                    }
                </mat-form-field>
            </form>
        }

        @if ((isSynchronizedGroup$ | async) === true) {
            <app-inline-message
                [inlineMessageType]="InlineMessageType.INFO"
                class="mb-4"
            >
                <div content>
                    @if (isAdmin$ | async) {
                        <translate
                            >SynchronizedGroupsInlineMessageAdmin</translate
                        >
                    } @else {
                        <translate
                            >SynchronizedGroupsInlineMessageUser</translate
                        >
                    }
                </div>
            </app-inline-message>
        }

        @if ((invalidWagons$ | async)?.length > 0) {
            <div>
                <app-inline-message
                    [inlineMessageType]="InlineMessageType.WARNING"
                >
                    <span content>
                        @if ((invalidWagons$ | async)?.length > 1) {
                            <translate
                                [params]="{
                                    invalidWagonNumberCount: (
                                        invalidWagons$ | async
                                    )?.length.toString(),
                                }"
                                >$$invalidWagonNumberCount$$ items can not be
                                added to the list. Please edit or
                                delete.</translate
                            >
                        } @else if ((invalidWagons$ | async).length === 1) {
                            <translate
                                >WagonNumbersInvalidSingleItemHint</translate
                            >
                        }
                    </span>
                    <app-button
                        button
                        buttonType="basic-danger"
                        (buttonClick)="removeAllItemsFromInvalidList()"
                        ><translate>Delete invalid items</translate>
                    </app-button>
                </app-inline-message>

                <div class="pb-3 pt-3">
                    @for (
                        wagonEntry of invalidWagons$ | async;
                        track wagonEntry.wagonNumber;
                        let wagonNumberIndex = $index
                    ) {
                        <div
                            class="wagon-number-entry error-entry"
                            [ngClass]="{
                                'edit-entry':
                                    wagonEntry.wagonNumber ===
                                    wagonNumberInEditMode,
                            }"
                        >
                            <div
                                class="flex h-[40px] items-center justify-between"
                            >
                                <div class="flex">
                                    <div class="pr-8 min-w-[140px]">
                                        <div class="flex items-center pl-4">
                                            @if (
                                                wagonEntry.wagonNumber !==
                                                wagonNumberInEditMode
                                            ) {
                                                <mat-icon
                                                    svgIcon="warning"
                                                    class="mr-2 text-error"
                                                    [matTooltip]="
                                                        'WagonNumberInvalid'
                                                            | translate
                                                    "
                                                    matTooltipClass="tooltip"
                                                ></mat-icon>
                                                <span>
                                                    {{
                                                        wagonEntry.wagonNumber
                                                            | uicWagonNumber
                                                    }}
                                                </span>
                                            } @else {
                                                <mat-form-field
                                                    appearance="fill"
                                                    class="full-width"
                                                >
                                                    <input
                                                        autofocus
                                                        matInput
                                                        type="text"
                                                        class="min-w-[250px]"
                                                        [formControl]="
                                                            wagonNumberEditControl
                                                        "
                                                        (keydown)="
                                                            onKeyDown(
                                                                $event,
                                                                wagonNumberIndex
                                                            )
                                                        "
                                                    />
                                                </mat-form-field>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="justify-self-end">
                                    @if (
                                        wagonEntry.wagonNumber !==
                                        wagonNumberInEditMode
                                    ) {
                                        <app-button-group alignment="right">
                                            <app-button
                                                buttonType="ghost"
                                                iconButton
                                                icon="edit"
                                                (buttonClick)="
                                                    setWagonNumberToEditMode(
                                                        wagonEntry.wagonNumber
                                                    )
                                                "
                                                class="mr-0"
                                            ></app-button>
                                            @if (
                                                (wagonsChangeable$ | async) &&
                                                (isSynchronizedGroup$
                                                    | async) === false
                                            ) {
                                                <app-button
                                                    buttonType="ghost"
                                                    iconButton
                                                    class="pr-2 ml-0"
                                                    icon="delete-outline"
                                                    (buttonClick)="
                                                        removeItemFromInvalidList(
                                                            wagonEntry.wagonNumber
                                                        )
                                                    "
                                                ></app-button>
                                            }
                                        </app-button-group>
                                    } @else {
                                        <app-button-group alignment="right">
                                            <app-button
                                                iconButton
                                                icon="check"
                                                (buttonClick)="
                                                    updateInvalidWagonNumber(
                                                        wagonNumberIndex
                                                    )
                                                "
                                                class="mr-0"
                                            ></app-button>
                                            <app-button
                                                iconButton
                                                class="pr-2 ml-0"
                                                icon="close"
                                                (buttonClick)="
                                                    clearWagonsToEdit()
                                                "
                                            ></app-button>
                                        </app-button-group>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }

        <div>
            @for (
                wagonEntry of selectedWagons$ | async;
                track wagonEntry.wagonId;
                let wagonNumberIndex = $index
            ) {
                <div class="wagon-number-entry">
                    <div class="flex h-[40px] items-center justify-between">
                        <div class="flex">
                            <div class="pr-8 min-w-[140px]">
                                <a
                                    [class.disbaled]="disabled"
                                    target="_blank"
                                    rel="opener"
                                    [routerLink]="[
                                        '/wagon',
                                        wagonEntry.wagonId,
                                    ]"
                                    clickTracking
                                    [trackingCategory]="
                                        trackingBaseData.category
                                    "
                                    [trackingArea]="trackingBaseData.area"
                                    [trackingAction]="
                                        GroupManagementAction.wagonNumberClicked
                                    "
                                    class="text-body-3 cursor-pointer pl-4"
                                >
                                    {{
                                        wagonEntry.wagonNumber | uicWagonNumber
                                    }}
                                </a>
                            </div>
                            <span class="text-accent-medium">{{
                                formatUic(wagonEntry.uicClass)
                            }}</span>
                        </div>
                        <div class="justify-self-end">
                            <app-button-group alignment="right">
                                @if (
                                    (wagonsChangeable$ | async) &&
                                    (isSynchronizedGroup$ | async) === false
                                ) {
                                    <app-button
                                        buttonType="ghost"
                                        iconButton
                                        class="pr-2 ml-0"
                                        icon="delete-outline"
                                        (buttonClick)="
                                            removeWagonFromSelectedList(
                                                wagonEntry.wagonId
                                            )
                                        "
                                    ></app-button>
                                }
                            </app-button-group>
                        </div>
                    </div>
                </div>
            }
        </div>
    }
</div>
