export enum ServiceDeskArea {
    assistanceCard = 'assistanceCard',
    improvementCard = 'improvementCard',
    overview = 'overview',
}

export enum ServiceDeskAction {
    phoneNumberClicked = 'phoneNumberClicked',
    mailAdressClicked = 'mailAdressClicked',
    backToTopClicked = 'backToTopClicked',
}
