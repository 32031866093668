<header>
    <mat-toolbar class="top-menu" data-qa="top-menu">
        <div class="top-menu-button-wrapper">
            <a
                *ngIf="authenticated$ | async"
                matRipple
                class="sidebar-toggle"
                (click)="toggleSidebar()"
                clickTracking
                [trackingCategory]="TrackingCategory.navigation"
                [trackingArea]="NavigationArea.menu"
                [trackingAction]="
                    (sidebarOpen$ | async)
                        ? NavigationAction.closed
                        : NavigationAction.clicked
                "
            >
                <mat-icon
                    class="h-6 w-6 text-current"
                    [svgIcon]="(sidebarOpen$ | async) ? 'close' : 'menu'"
                >
                </mat-icon>
                <span class="ml-4 text-body-3 w-28">
                    <translate *ngIf="sidebarOpen$ | async; else openTpl">
                        Close menu
                    </translate>
                    <ng-template #openTpl>
                        <translate>Menu</translate>
                    </ng-template>
                </span>
            </a>

            <span
                *ngIf="impersonationLabel$ | async as label"
                class="impersonation-label"
                [matTooltip]="label.length > 25 ? label : null"
            >
                {{ label }}
            </span>
        </div>

        <div
            class="logo-wrapper"
            [class.developer-tools-visible]="hasDeveloperTools$ | async"
        >
            <a
                [routerLink]="['/dashboard']"
                clickTracking
                [trackingCategory]="TrackingCategory.navigation"
                [trackingArea]="NavigationArea.header"
                [trackingAction]="NavigationAction.traigoLogoClicked"
            >
                <img src="assets/images/traigo-logo.svg" class="logo" />
            </a>
        </div>

        <div class="top-menu-right-wrapper">
            <div class="top-menu-right" *ngIf="authenticated$ | async">
                <app-group-management-header
                    [headerActivated]="isActiveGroupMgmt"
                ></app-group-management-header>
                <app-notification-panel></app-notification-panel>
                <app-user-menu></app-user-menu>
                <app-developer-tools
                    *ngIf="hasDeveloperTools$ | async"
                ></app-developer-tools>
            </div>
        </div>
    </mat-toolbar>
</header>
