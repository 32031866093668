<div class="flex flex-row flex-0 items-center px-6 py-2">
    @if (isDefaultGroup$ | async) {
        <mat-icon svgIcon="company-filled" class="min-w-6 mr-2"></mat-icon>
    }

    @if (loading$ | async) {
        <div class="flex-1">
            <span class="skeleton-loading min-w-40">loading groupname</span>
        </div>
    } @else {
        <span class="text-subtitle-1 flex-1">{{ groupName$ | async }}</span>
    }

    <app-button
        buttonType="ghost"
        iconButton
        icon="close"
        class="close-dialog"
        [trackingData]="{
            category: TrackingCategory.groupManagement,
            area: GroupManagementArea.editor,
            action: GroupManagementAction.closeClicked,
        }"
        (buttonClick)="requestClose()"
    ></app-button>
</div>

@if (loading$ | async) {
    <div class="flex flex-col flex-1 justify-between">
        <div class="p-6">
            <div class="flex flex-col gap-2">
                <div class="skeleton-loading w-[336px] h-7"></div>
                <div class="skeleton-loading w-[336px] h-[60px] mb-12"></div>
                <div class="skeleton-loading w-162 h-7"></div>
                <div class="skeleton-loading w-162 h-7"></div>
                <div class="skeleton-loading w-162 h-[60px]"></div>
            </div>
        </div>
    </div>
} @else if (savingErrorOccurred$ | async) {
    <app-system-response
        small
        responseType="error"
        title="LoadPossibleWagonsError"
    >
    </app-system-response>
} @else if (editGroupError$ | async) {
    <app-system-response small responseType="error" title="EditGroupError">
    </app-system-response>
} @else if (
    (isGroupEmpty$ | async) && (areWagonsChangeable$ | async) === false
) {
    <div class="flex flex-col h-full justify-center overflow-hidden">
        <div class="flex flex-col grow justify-center items-center text-center">
            <app-system-response
                small
                title="Nothing to see here"
                message="This group is empty and does not contain any wagons"
                responseType="empty"
            >
            </app-system-response>
        </div>
        <div class="flex flex-col flex-0 p-6 text-center">
            <span translate class="">
                If you have any question, reach out to your admin
            </span>
        </div>
    </div>
} @else {
    <div class="flex flex-col flex-1 h-full content-wrapper">
        <form [formGroup]="formGroup" class="h-full">
            <mat-tab-group mat-stretch-tabs="true" class="h-full">
                <mat-tab [label]="'Basics'">
                    <div class="p-8 flex flex-col">
                        @if ((isDefaultGroup$ | async) === false) {
                            <span translate class="pb-6 text-body-3">Name</span>
                            <mat-form-field
                                appearance="outline"
                                class="w-80 pb-6"
                            >
                                <input
                                    matInput
                                    required
                                    [placeholder]="
                                        'Set a group name' | translate
                                    "
                                    formControlName="groupName"
                                    [maxLength]="30"
                                />

                                <mat-label translate
                                    >Set a group name
                                </mat-label>

                                @if (
                                    formGroup.get('groupName')?.errors
                                        ?.required ||
                                    formGroup.get('groupName')?.errors
                                        ?.validateGroupName
                                ) {
                                    <mat-error translate>
                                        Please provide a group name
                                    </mat-error>
                                }
                            </mat-form-field>

                            @if (isAccessPeriodVisible$ | async) {
                                <app-group-management-access-period-selection
                                    [setTrackingArea]="
                                        GroupManagementArea.editor
                                    "
                                    [accessPeriod]="parsedAccessPeriod$ | async"
                                    formControlName="accessPeriod"
                                >
                                </app-group-management-access-period-selection>
                            }
                        }

                        @if (usersVisible$ | async) {
                            <app-group-management-user-selection
                                [users]="users$ | async"
                                [setTrackingArea]="GroupManagementArea.editor"
                                formControlName="userIds"
                            ></app-group-management-user-selection>
                        }
                    </div>
                </mat-tab>

                <mat-tab
                    [label]="
                        'WagonsTab'
                            | translate
                                : {
                                      numberOfWagons: (selectedWagons$ | async)
                                          .length,
                                  }
                    "
                >
                    <app-group-management-wagon-number-input
                        formControlName="wagons"
                        [setTrackingArea]="GroupManagementArea.editor"
                    ></app-group-management-wagon-number-input>
                </mat-tab>

                <mat-tab
                    [label]="
                        'GeofenceTab'
                            | translate
                                : {
                                      numberOfGeofences:
                                          (numberOfSelectedGeofences$ | async),
                                  }
                    "
                >
                    <app-group-management-geofence-selection
                        [geofences]="geofences$ | async"
                        formControlName="geofenceIds"
                        [setTrackingArea]="GroupManagementArea.editor"
                    ></app-group-management-geofence-selection>
                </mat-tab>
            </mat-tab-group>
        </form>
    </div>
    <mat-divider />
    <div class="flex flex-col flex-0 p-6">
        @if (changeable$ | async) {
            <app-button-group
                [alignment]="
                    (isDefaultGroup$ | async) === false
                        ? 'space-between'
                        : 'right'
                "
            >
                @if ((isDefaultGroup$ | async) === false) {
                    <app-button
                        class="pr-6"
                        icon="delete-outline"
                        buttonType="basic-primary"
                        iconPosition="left"
                        [trackingData]="{
                            category: TrackingCategory.groupManagement,
                            area: GroupManagementArea.editor,
                            action: GroupManagementAction.deleteGroupClicked,
                        }"
                        (buttonClick)="deleteGroup()"
                        [disabled]="
                            (editGroupLoading$ | async) || (loading$ | async)
                        "
                    >
                        <translate>Delete group</translate>
                    </app-button>
                }

                <app-button
                    [buttonType]="'flat-primary'"
                    [trackingData]="{
                        category: TrackingCategory.groupManagement,
                        area: GroupManagementArea.editor,
                        action: GroupManagementAction.saveChangesClicked,
                    }"
                    (buttonClick)="handleFormSubmit()"
                    [status]="
                        (editGroupLoading$ | async) || (loading$ | async)
                            ? 'loading'
                            : 'default'
                    "
                    [disabled]="
                        (hasValueChanged$ | async) === false ||
                        formGroup.errors !== null
                    "
                >
                    <translate>Save changes</translate>
                </app-button>
            </app-button-group>
        } @else {
            <span translate class="text-center" *ngIf="!isSynchronizedGroup">
                If you have any question, reach out to your admin
            </span>
        }
    </div>
}
