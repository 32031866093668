import { Component, Inject, OnInit } from '@angular/core'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { AuthzService } from '@auth-util-lib/authz.service'
import { FeatureFlags } from '@feature-flag-lib/feature-flags'
import { FeatureFlagsService } from '@feature-flag-lib/feature-flags.service'
import { SidebarService } from '@platform-lib/services/sidebar.service'
import { map, shareReplay } from 'rxjs/operators'
import { GROUP_MANAGEMENT_CONFIG } from './header.token'
import {
    DashboardAction,
    DashboardArea,
} from '@tracking-lib/use-case-tracking-models/dashboard.tracking.model'
import { TrackingCategory } from '@tracking-lib/tracking.model'
import {
    NavigationAction,
    NavigationArea,
} from '@tracking-lib/use-case-tracking-models/navigation.tracking.model'

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    isActiveGroupMgmt = false
    sidebarOpen$ = this.sidebarService.isSidebarOpen$

    authenticated$ = this.authn
        .stateChanges()
        .pipe(
            map((authState) => authState === AuthenticationState.Authenticated)
        )

    hasDeveloperTools$ = this.featureFlagsService
        .getFeatureFlag(FeatureFlags.DeveloperTools)
        .pipe(shareReplay(1))

    readonly impersonationLabel$ = this.authZ.getClaim$('impersonation_label')

    constructor(
        private sidebarService: SidebarService,
        private authn: AuthnService,
        private authZ: AuthzService,
        private featureFlagsService: FeatureFlagsService,
        @Inject(GROUP_MANAGEMENT_CONFIG)
        private enableGroupManagementHeader: boolean
    ) {}

    ngOnInit(): void {
        this.isActiveGroupMgmt = this.enableGroupManagementHeader
    }

    toggleSidebar() {
        this.sidebarService.toggle({ belowHeader: true })
    }

    protected readonly TrackingCategory = TrackingCategory
    protected readonly NavigationArea = NavigationArea
    protected readonly NavigationAction = NavigationAction
}
