import { CommonModule } from '@angular/common'
import { Component, Input, OnDestroy, forwardRef } from '@angular/core'
import {
    FormControl,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { ParsedAccessPeriod } from '@group-management-lib/group-management.model'
import { dayjsHelper } from '@localization-lib/date-time/dayjs/dayjsHelper'
import { MaterialDayjsModule } from '@localization-lib/date-time/material-dayjs/material-dayjs.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { BaseControlValueAccessor } from '@shared-ui-lib/base-control-value-accessor/base-control-value-accessor.component'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import {
    TrackingBaseData,
    TrackingCategory,
} from '@tracking-lib/tracking.model'
import { TrackingService } from '@tracking-lib/tracking.service'
import {
    GroupManagementAction,
    GroupManagementArea,
} from '@tracking-lib/use-case-tracking-models/group-management.tracking.model'
import { Dayjs } from 'dayjs'
import {
    BehaviorSubject,
    Subscription,
    combineLatest,
    map,
    startWith,
} from 'rxjs'

@Component({
    selector: 'app-group-management-access-period-selection',
    templateUrl: './group-management-access-period-selection.component.html',
    standalone: true,
    imports: [
        ButtonModule,
        CommonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MaterialDayjsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslationModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(
                () => GroupManagementAccessPeriodSelectionComponent
            ),
            multi: true,
        },
    ],
})
export class GroupManagementAccessPeriodSelectionComponent
    extends BaseControlValueAccessor<ParsedAccessPeriod>
    implements OnDestroy
{
    accessPeriod$ = new BehaviorSubject<ParsedAccessPeriod>({
        beginOn: null,
        endOn: null,
    })

    @Input()
    set accessPeriod(value: ParsedAccessPeriod | null) {
        if (value) {
            this.accessPeriod$.next(value)
        }
    }

    trackingBaseData?: TrackingBaseData
    @Input()
    set setTrackingArea(area: GroupManagementArea) {
        this.trackingBaseData = {
            category: TrackingCategory.groupManagement,
            area,
        }
    }

    private readonly subscriptions = new Subscription()

    startDateControl = new FormControl<Dayjs | null>(null)
    endDateControl = new FormControl<Dayjs | null>(null)

    constructor(private trackingService: TrackingService) {
        super()

        this.subscriptions.add(
            this.accessPeriod$.subscribe((period) => {
                if (period.beginOn) {
                    this.startDateControl.setValue(period.beginOn)
                }

                if (period.endOn) {
                    this.endDateControl.setValue(period.endOn)
                }
            })
        )

        this.subscriptions.add(
            combineLatest([
                this.startDateControl.valueChanges.pipe(
                    startWith(null),
                    map((startDate) =>
                        startDate
                            ? dayjsHelper.overrideWithDefaultTimezone(startDate)
                            : null
                    )
                ),
                this.endDateControl.valueChanges.pipe(
                    startWith(null),
                    map((endDate) => {
                        if (endDate) {
                            // The end date should be inclusive,
                            // such that the group stays accessible until the end of the day.
                            // Also make sure the timezone is set to Berlin.
                            return dayjsHelper
                                .overrideWithDefaultTimezone(endDate)
                                .endOf('day')
                        }
                        return null
                    })
                ),
            ]).subscribe(([startDate, endDate]) => {
                if (!this.disabled) {
                    const selectedPeriod: ParsedAccessPeriod = {
                        beginOn: startDate,
                        endOn: endDate,
                    }
                    this.value = selectedPeriod
                    this.onChanged(selectedPeriod)
                    this.onTouched()
                }
            })
        )
    }

    isValidStartDate = (d: Dayjs | null): boolean => {
        const endDate = this.endDateControl.value
        return d === null || endDate === null || d.isSameOrBefore(endDate)
    }

    isValidEndDate = (d: Dayjs | null): boolean => {
        const startDate = this.startDateControl.value
        return d === null || startDate === null || startDate.isSameOrBefore(d)
    }

    resetPeriodSelection(event: MouseEvent, formControl: FormControl) {
        event.stopImmediatePropagation()
        formControl.setValue(null)
    }

    trackAction(action: GroupManagementAction) {
        if (this.trackingBaseData) {
            this.trackingService.track({
                category: this.trackingBaseData.category,
                area: this.trackingBaseData.area,
                action,
            })
        }
    }

    onStartDateChange() {
        this.trackAction(GroupManagementAction.accessPeriodStartEntered)
    }

    onEndDateChange() {
        this.trackAction(GroupManagementAction.accessPeriodEndEntered)
    }

    public setDisabledState(disabled: boolean): void {
        if (disabled) {
            this.startDateControl.disable()
            this.endDateControl.disable()
        } else {
            this.startDateControl.enable()
            this.endDateControl.enable()
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
