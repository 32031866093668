export enum NavigationArea {
    menu = 'menu',
    header = 'header',
    footer = 'footer',
}

export enum NavigationAction {
    clicked = 'clicked',
    useCaseClicked = 'useCaseClicked',
    closed = 'closed',
    traigoLogoClicked = 'traigoLogoClicked',
    nameClicked = 'nameClicked',
    accountSettingsClicked = 'accountSettingsClicked',
    logoutClicked = 'logoutClicked',
}
