import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    Output,
} from '@angular/core'
import { StatusType } from '@datadog/browser-logs'
import { DatadogService } from '@error-util-lib/datadog/datadog.service'
import { ErrorType } from '@error-util-lib/error.model'
import { getErrorMessage } from '@error-util-lib/getErrorMessage'
import { AppIcon } from '@portal-lib/app-icon.service'
import { ButtonStatus, IconPosition } from '@shared-ui-lib/button/button.model'
import { isTruthy } from '@util-lib/isTruthy'
import {
    BehaviorSubject,
    ReplaySubject,
    Subscription,
    combineLatest,
    filter,
    map,
} from 'rxjs'
import {
    SystemResponseTextWithParams,
    SystemResponseType,
} from './system-response.model'

@Component({
    selector: 'app-system-response',
    styleUrls: ['./system-response.component.scss'],
    templateUrl: './system-response.component.html',
})
export class SystemResponseComponent implements OnDestroy {
    @HostBinding('class.small')
    _small = false

    subscriptions = new Subscription()

    @Input()
    set small(small: boolean) {
        this._small = coerceBooleanProperty(small)
    }
    get small() {
        return this._small
    }

    responseType$ = new BehaviorSubject<SystemResponseType | null>(null)
    @Input({ required: true })
    set responseType(responseType: SystemResponseType) {
        this.responseType$.next(responseType)
    }

    customIcon$ = new BehaviorSubject<string | null>(null)
    @Input()
    set customIcon(icon: string) {
        this.customIcon$.next(icon)
    }

    title$ = new BehaviorSubject<SystemResponseTextWithParams | null>(null)
    @Input()
    set title(title: string | SystemResponseTextWithParams) {
        this.title$.next(typeof title === 'string' ? { text: title } : title)
    }

    message$ = new BehaviorSubject<SystemResponseTextWithParams | null>(null)
    @Input()
    set message(message: string | SystemResponseTextWithParams) {
        this.message$.next(
            typeof message === 'string' ? { text: message } : message
        )
    }

    flatPrimaryButtonText$ = new BehaviorSubject<string>('')
    @Input()
    set flatPrimaryButtonText(flatPrimaryButtonText: string) {
        this.flatPrimaryButtonText$.next(flatPrimaryButtonText)
    }

    flatPrimaryButtonIcon$ = new BehaviorSubject<AppIcon | undefined>(undefined)
    @Input()
    set flatPrimaryButtonIcon(flatPrimaryButtonIcon: AppIcon) {
        this.flatPrimaryButtonIcon$.next(flatPrimaryButtonIcon)
    }

    flatPrimaryButtonIconPosition$ = new BehaviorSubject<IconPosition | null>(
        null
    )
    @Input()
    set flatPrimaryButtonIconPosition(
        flatPrimaryButtonIconPosition: IconPosition
    ) {
        this.flatPrimaryButtonIconPosition$.next(flatPrimaryButtonIconPosition)
    }

    flatPrimaryButtonState$ = new BehaviorSubject<ButtonStatus>('default')
    @Input()
    set flatPrimaryButtonState(state: ButtonStatus) {
        this.flatPrimaryButtonState$.next(state)
    }

    @Output() readonly flatPrimaryButtonClick = new EventEmitter<void>()

    basicPrimaryButtonText$ = new BehaviorSubject<string>('')
    @Input()
    set basicPrimaryButtonText(basicPrimaryButtonText: string) {
        this.basicPrimaryButtonText$.next(basicPrimaryButtonText)
    }

    basicPrimaryButtonIcon$ = new BehaviorSubject<AppIcon | undefined>(
        undefined
    )
    @Input()
    set basicPrimaryButtonIcon(basicPrimaryButtonIcon: AppIcon) {
        this.basicPrimaryButtonIcon$.next(basicPrimaryButtonIcon)
    }

    basicPrimaryButtonIconPosition$ = new BehaviorSubject<IconPosition | null>(
        null
    )
    @Input()
    set basicPrimaryButtonIconPosition(
        basicPrimaryButtonIconPosition: IconPosition
    ) {
        this.basicPrimaryButtonIconPosition$.next(
            basicPrimaryButtonIconPosition
        )
    }

    basicPrimaryButtonState$ = new BehaviorSubject<ButtonStatus>('default')
    @Input()
    set basicPrimaryButtonState(state: ButtonStatus) {
        this.basicPrimaryButtonState$.next(state)
    }

    @Output() readonly basicPrimaryButtonClick = new EventEmitter<void>()

    error$ = new ReplaySubject<ErrorType>(1)
    @Input()
    set error(error: ErrorType) {
        this.error$.next(error)
    }

    errorMessage$ = this.error$.pipe(filter(isTruthy), map(getErrorMessage))

    constructor(private datadogService: DatadogService) {
        this.subscriptions.add(
            this.errorMessage$.subscribe((message) => {
                this.datadogService.log(message, StatusType.error)
            })
        )

        this.subscriptions.add(
            this.responseType$.subscribe((responseType) => {
                switch (responseType) {
                    case 'error':
                        if (!this.title$.getValue()) {
                            this.title$.next({ text: 'DefaultErrorTitle' })
                        }
                        if (!this.message$.getValue()) {
                            this.message$.next({ text: 'DefaultErrorMessage' })
                        }
                        break
                    case 'success':
                        if (!this.title$.getValue()) {
                            this.title$.next({ text: 'DefaultSuccessTitle' })
                        }
                        if (!this.message$.getValue()) {
                            this.message$.next({
                                text: 'DefaultSuccessMessage',
                            })
                        }
                        break
                    case 'permission':
                        if (!this.title$.getValue()) {
                            this.title$.next({
                                text: 'DefaultPermissionTitle',
                            })
                        }
                        if (!this.message$.getValue()) {
                            this.message$.next({
                                text: 'DefaultPermissionMessage',
                            })
                        }
                        break
                    case 'empty':
                        if (!this.title$.getValue()) {
                            this.title$.next({ text: 'DefaultEmptyTitle' })
                        }
                        if (!this.message$.getValue()) {
                            this.message$.next({ text: 'DefaultEmptyMessage' })
                        }
                        break
                    default:
                        return ''
                }
            })
        )
    }

    icon$ = combineLatest([this.responseType$, this.customIcon$]).pipe(
        map(([responseType, customIcon]) => {
            if (customIcon) return customIcon

            switch (responseType) {
                case 'error':
                    return 'attention'
                case 'success':
                    return 'check-filled'
                case 'permission':
                    return 'error-permission'
                case 'empty':
                    return 'empty-box'
                default:
                    return null
            }
        })
    )

    handleFilledPrimaryButtonClick() {
        this.flatPrimaryButtonClick.emit()
    }

    handleTextPrimaryButtonClick() {
        this.basicPrimaryButtonClick.emit()
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
