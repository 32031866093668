export enum ContractArea {
    contractOverview = 'contractOverview',
    contractDetails = 'contractDetails',
}

export enum ContractAction {
    quickFilterIssuesClicked = 'quickFilterIssuesClicked',
    quickFilterHintsClicked = 'quickFilterHintsClicked',
    tableResetClicked = 'tableResetClicked',
    tableFilterApplied = 'tableFilterApplied',
    tableFilterReset = 'tableFilterReset',
    tableFilterSorted = 'tableFilterSorted',
    contractClicked = 'contractClicked',
    exportClicked = 'exportClicked',
    nameEditClicked = 'nameEditClicked',
    nameEditSaved = 'nameEditSaved',
    nameEditCanceled = 'nameEditCanceled',
    toggleDocuments = 'toggleDocuments',
    documentDownloaded = 'documentDownloaded',
    quickFilterAssignedClicked = 'quickFilterAssignedClicked',
    quickFilterActiveClicked = 'quickFilterActiveClicked',
    quickFilterReturnedClicked = 'quickFilterReturnedClicked',
    wagonNumberClicked = 'wagonNumberClicked',
}
