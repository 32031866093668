export enum UserManagementArea {
    userManagement = 'userManagement',
    editor = 'editor',
    creator = 'creator',
    dialog = 'dialog',
    snackBar = 'snackBar',
    deleteDialog = 'deleteDialog',
    quitDialog = 'quitDialog',
}

export enum UserManagementAction {
    createUserButtonClicked = 'createUserButtonClicked',
    editButtonClicked = 'editButtonClicked',

    continueButtonClicked = 'continueButtonClicked',
    setUserPermissions = 'setUserPermissions',

    continueCreatingButtonClicked = 'continueCreatingButtonClicked',
    continueEditingButtonClicked = 'continueEditingButtonClicked',

    enterFirstName = 'enterFirstName',
    enterSurname = 'enterSurname',
    enterEmail = 'enterEmail',
    confirmQuitButtonClicked = 'confirmQuitButtonClicked',
    setUserGroups = 'setUserGroups',
    closeButtonClicked = 'closeButtonClicked',
    saveButtonClicked = 'saveButtonClicked',
    serviceDeskLinkClicked = 'serviceDeskLinkClicked',
    deleteUserButtonClicked = 'deleteUserButtonClicked',
    saveChangesButtonClicked = 'saveChangesButtonClicked',
    cancelButtonClicked = 'cancelButtonClicked',
    confirmDeleteButtonClicked = 'confirmDeleteButtonClicked',
}
