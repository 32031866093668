<header>
    <h4>traigo Plattform – Datenschutzerklärung</h4>
    <p class="subtitle">(Version 1.8 gültig ab 12.03.2025)</p>
    <p>
        Die traigo Plattform („<b>Plattform</b>“) ermöglicht es
        individualisierten Nutzern („<b>Nutzer</b>“ oder „<b>Sie</b>“), im Namen
        und im Auftrag von geschäftlichen Kunden der VTG Rail Europe GmbH,
        Nagelsweg 34, 20097 Hamburg, Tel.: +49 40 2354-0, E-Mail:
        info&#64;vtg.com („<b>VTG</b>“, „<b>wir</b>“ oder „<b>uns</b>“)
        verschiedene Tools der Plattform zu nutzen, sofern der jeweilige
        geschäftliche Kunde zuvor einen Vertrag über die Plattformnutzung
        („<b>Plattformvertrag</b>“) mit VTG abgeschlossen hat. Bei der Nutzung
        der Plattform zu diesem Zweck fallen personenbezogene Daten im Sinne der
        EU-Verordnung 679/2016 („<b>DSGVO</b>“) an.
    </p>
    <p>
        Diese Datenschutzerklärung gilt für die Verarbeitung von
        personenbezogenen Daten durch VTG im Rahmen des Betriebs der Plattform
        bzw. der vorgeschalteten Landing Page. Nachstehend informieren wir
        ausführlich über den Umgang mit personenbezogenen Daten. Die Erhebung
        und Verarbeitung personenbezogener Daten erfolgt dabei ausschließlich im
        Einklang mit den gesetzlichen Bestimmungen der DSGVO.
    </p>
    <p>
        Betreiberin der Plattform und der Landing Page und Verantwortliche im
        Sinne der DSGVO ist VTG.
    </p>
    <p>
        Bei Fragen zur Verarbeitung personenbezogener Daten durch VTG im Rahmen
        der Plattform kann der Nutzer die unter Ziffer 5 dieser
        Datenschutzerklärung aufgeführten Kontaktmöglichkeiten nutzen.
    </p>
</header>
<section>
    <ol>
        <li class="privacy__li__headline">
            <h4>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</h4>
            <ol>
                <li>
                    <p class="privacy__li__sub_headline">Technische Daten</p>
                    <p>
                        Der störungsfreie Betrieb der Plattform erfordert die
                        Erfassung bestimmter technischer Daten, die vom Rechner
                        des Nutzers bei der Nutzung der Plattform automatisch
                        mit übertragen werden. Wir speichern in diesem
                        Zusammenhang insbesondere Zugriffsdaten wie z. B.
                        IP-Adresse, Browsertyp und -Version, das verwendete
                        Betriebssystem, den Namen des Internet Service Providers
                        des Nutzers, die Seite, von der er auf die Plattform
                        zugreift oder den Namen der von ihm angeforderten
                        Dateien/Inhalte sowie Datum und Uhrzeit des Besuchs.
                        Diese Daten werden ausschließlich dazu genutzt, um dem
                        Nutzer den Zugang zu und die Nutzung der Plattform zu
                        ermöglichen und werden zur Verbesserung unseres
                        Angebotes ausgewertet, ohne einen direkten Rückschluss
                        auf die Person des Nutzers zu ermöglichen. Diese Daten
                        werden nicht mit anderen von Ihnen angegebenen Daten
                        zusammengeführt. Die Nutzung der Daten zu diesen Zwecken
                        ist sowohl zur Erfüllung unserer vertraglichen Pflichten
                        gegenüber dem Nutzer bzw. seinem Arbeitgeber nach Art. 6
                        Abs. 1 lit. b) DSGVO als auch zum Schutz unseres
                        berechtigten Interesses an der Bereitstellung und
                        Verbesserung der Plattform nach Art. 6 Abs. 1 lit. f)
                        DSGVO gerechtfertigt. Diese Daten werden von uns
                        gelöscht, sobald sie für die Zwecke, zu denen sie
                        erhoben wurden, nicht mehr erforderlich sind, also
                        üblicherweise wenn der Nutzer die Plattform verlassen
                        hat. Dies gilt auch für sonstige Besucher der Landing
                        Page der Plattform, die frei im Internet erreichbar ist.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">Account-Daten</p>
                    <p>
                        Die Nutzung der Plattform ist nur über einen
                        personalisierten Account des Nutzers („<b>Account</b>“)
                        möglich, den VTG für den Nutzer mit Abschluss des
                        Registrierungsprozesses freischaltet. Die Anfrage für
                        einen Account erfolgt über ein entsprechendes Formular
                        auf der Landing Page der Plattform. Hier muss der Name,
                        E-Mail-Adresse, Firmenname und gegebenenfalls die
                        VTG-Kundennummer und Telefonnummer des Nutzers angegeben
                        werden. Diese Daten werden ausschließlich verwendet, um
                        zu prüfen, ob Zugang zur Plattform gewährt wird (Art. 6
                        Abs. 1 lit. b) DSGVO). Wird dies von VTG abgelehnt,
                        werden die Daten schnellstmöglich wieder gelöscht.
                        Anderenfalls werden die Daten in den Account überführt.
                    </p>
                    <p>
                        Nach Freischaltung durch VTG werden in dem Account
                        personenbezogene Daten des Nutzers gespeichert. Diese
                        Daten umfassen den Namen, die geschäftliche
                        E-Mail-Adresse und Telefonnummer sowie Informationen zum
                        Arbeitgeber des Nutzers, sowie sein Benutzername und
                        Passwort für die Plattform.
                    </p>
                    <p>
                        Es ist auch möglich, weitere personenbezogene
                        Informationen in seinem Account zur Verfügung zu
                        stellen, z.B. Fotos o.ä. Der Nutzer stellt diese Daten
                        vollständig freiwillig zur Verfügung und sie sind für
                        die Nutzung der Plattform nicht erforderlich.
                    </p>
                    <p>
                        Es kann für unsere Support- bzw. Vertriebsmitarbeiter
                        erforderlich sein, zur Unterstützung des Nutzers auf den
                        Account zuzugreifen, um die dort sichtbaren
                        Informationen zu prüfen.
                    </p>
                    <p>
                        Die Verarbeitung dieser Daten erfolgt ausschließlich zum
                        Zweck der Zurverfügungstellung des Accounts und
                        Ermöglichung der Nutzung der Plattform und ist damit zur
                        Erfüllung und Abwicklung des Plattformvertrages nach
                        Art. 6 Abs. 1 lit. b) DSGVO bzw. zum Schutz unserer
                        berechtigten Interessen in der Zurverfügungstellung der
                        Plattform für die Nutzer nach Art. 6 Abs. 1 lit. f) DSGO
                        erforderlich.
                    </p>
                    <p>
                        Die Account-Daten des Nutzers werden gelöscht, sobald
                        der Account des Nutzers aufgelöst wird oder der Nutzer
                        bestimmte Daten korrigiert oder ändert.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">
                        Daten der Vertragsabwicklung
                    </p>
                    <p>
                        Für die Nutzung der Plattform ist es erforderlich, dass
                        sich der Nutzer mit seinem Account auf der Plattform
                        anmeldet. Hierfür ist die Angabe des Benutzernamens und
                        des persönlichen Passworts erforderlich.
                    </p>
                    <p>
                        Die einzelnen Aktivitäten des Nutzers im Rahmen der
                        Plattform (insbesondere der Erwerb von kostenpflichtigen
                        Tools oder Features) werden dem jeweiligen Account
                        zugeordnet.
                    </p>
                    <p>
                        Wir verwenden diese Daten ausschließlich zur Erfüllung
                        und Abwicklung des Plattformvertrages bzw. des über die
                        Plattform abgeschlossenen Einzelvertrages über Tools
                        entsprechend Art. 6 Abs. 1 lit. b) DSGVO.
                    </p>
                    <p>
                        Die Informationen über einzelne Aktivitäten des Nutzers
                        werden durch VTG über die Dauer der jeweiligen Aktivität
                        hinaus weiterhin gespeichert. Diese Informationen werden
                        erst nach Auflösung des Accounts des jeweiligen Nutzers
                        bzw. nach dem Ablauf der gesetzlichen
                        Aufbewahrungsfristen gelöscht (je nachdem was später
                        eintritt). Diese Daten können unter Umständen
                        anonymisiert bzw. aggregiert genutzt werden, um die
                        Nutzung der Plattform besser zu verstehen und die
                        Plattform zu verbessern.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">Zahlungsdaten</p>
                    <p>
                        Sofern von dem Account eines Nutzers kostenpflichtige
                        Tools gebucht wurden, ist es erforderlich, dass Angaben
                        zu dem Zahlungsmittel gemacht werden (z.B. Kreditkarten-
                        oder Kontonummer, Kreditinstitut, usw.). Wir werden
                        diese Daten ausschließlich dazu verwenden, um die
                        Zahlungen durchzuführen und wieder löschen sobald die
                        gesetzlichen Aufbewahrungsfristen abgelaufen sind.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">
                        Ort der Datenspeicherung
                    </p>
                    <p>
                        Die oben bezeichneten Daten werden von uns
                        ausschließlich auf Servern in Deutschland gespeichert.
                    </p>
                </li>
            </ol>
        </li>
        <li class="privacy__li__headline">
            <h4>Marketing</h4>
            <p>
                Gegebenenfalls verwenden wir die E-Mail-Adresse, die wir vom
                Nutzer im Rahmen der Vertragsabwicklung erhalten haben, um den
                Nutzer von Zeit zu Zeit über Neuigkeiten von uns und unseren
                Produkten zu informieren.
            </p>
            <p>
                Der Nutzer kann jederzeit durch Mitteilung per E-Mail zu
                erkennen geben, dass er keine weiteren Nachrichten erhalten
                möchte. Dies betrifft nicht solche Nachrichten, die für die
                Vertragsabwicklung erforderlich sind. Wir werden den Nutzer in
                jeder Marketing-E-Mail über diese Möglichkeit unterrichten.
            </p>
        </li>
        <li class="privacy__li__headline">
            <h4>Nutzerumfragen</h4>
            <p>
                Gegebenenfalls können wir Sie zu bestimmten Zeitpunkten
                ansprechen und fragen, ob Sie an einer Umfrage zu Ihren
                Erfahrungen mit der Plattform oder ähnlichen Themen teilnehmen
                möchten. Die Teilnahme an solchen Umfragen ist immer freiwillig.
                Einige der in einer Umfrage abgefragten Informationen sind
                freiwillig, andere sind Pflichtangaben, ohne die die Teilnahme
                an der Umfrage nicht möglich ist. Diese Pflichtfelder sind
                entsprechend gekennzeichnet.
            </p>
            <p>
                Wir verwenden die von Ihnen angegebenen Daten ausschließlich zu
                dem Zweck, die Nutzung der Plattform durch die einzelnen Nutzer
                zu analysieren und zu verbessern, sowie um gegebenenfalls mit
                Ihnen in Kontakt zu treten um weitere Fragen zu stellen oder
                Ihnen hilfreiche Informationen zur Nutzung der Plattform
                zukommen zu lassen. Wir werden Ihre personenbezogenen Daten, die
                Sie uns im Rahmen der Umfrage mitgeteilt haben, nicht mit
                Dritten teilen, sondern nur für VTG-interne Zwecke nutzen.
            </p>
            <p>
                Mit Absenden der Umfrage werden Sie gebeten, uns eine
                Einwilligung zur Nutzung der von Ihnen angegebenen
                personenbezogenen Daten zu den oben genannten Zwecken zu
                erteilen. Die Erteilung der Einwilligung ist freiwillig, ohne
                die Erteilung ist die Teilnahme an der Umfrage jedoch nicht
                möglich. Sofern Sie personenbezogene Daten von anderen Personen
                als Ihnen selbst angeben, sind Sie selbst verpflichtet
                sicherzustellen, dass diese Personen Ihnen gegenüber Ihre
                Einwilligung zur Nutzung ihrer Daten erteilt haben.
            </p>
            <p>
                Sie können Ihre Einwilligung jederzeit mit Wirkung für die
                Zukunft widerrufen durch Nachricht an datenschutz&#64;vtg.com.
                Sobald wir Ihren Widerruf erhalten haben, werden wir Ihre
                personenbezogenen Daten, die Sie uns im Rahmen der Umfrage
                mitgeteilt haben, löschen oder anonymisieren und Sie nicht mehr
                kontaktieren. Dies betrifft nicht Kontaktaufnahmen, die im
                Zusammenhang mit Ihrer gewöhnlichen Nutzung Ihres
                Nutzer-Accounts stehen.
            </p>
        </li>
        <li class="privacy__li__headline">
            <h4>Verwendung von Cookies</h4>
            <p>
                Um die Nutzung bestimmter Funktionen der Plattform zu
                ermöglichen, verwenden wir sogenannte Cookies. Hierbei handelt
                es sich um kleine Textdateien, die beim Besuch auf der Plattform
                auf dem Endgerät des Nutzers abgelegt werden und uns
                Informationen über die bisherigen Einstellungen und Nutzungen
                der Plattform zukommen lassen. Diese Cookies werden
                ausschließlich dazu benötigt, um die Plattform nutzen zu können
                und werden nach dem Ende der Browser-Sitzung, also nach
                Schließen des Browsers des Nutzers, wieder gelöscht
                (Sitzungs-Cookies). Andere Cookies verbleiben auf dem Endgerät
                des Nutzers und ermöglichen uns, den Browser des Nutzers beim
                nächsten Besuch wiederzuerkennen (persistente Cookies). Die
                Verwendung dieser Cookies ist zur Zurverfügungstellung der
                Plattform und damit sowohl zur Erfüllung der Pflichten aus dem
                Plattformvertrag, als auch zum Schutz der berechtigten
                Interessen von VTG an der Zurverfügungstellung der Plattform
                erforderlich und über Art. 6 Abs. 1 lit. b) bzw. lit. f) DSGVO
                gerechtfertigt. Dies umfasst auch Cookies von Microsoft Azure,
                die beim Einloggen in den Account gesetzt werden, um die
                zugriffsgesicherte Nutzung der Plattform zu ermöglichen. Diese
                Cookies bleiben für die Dauer von bis zu einer Stunde auf Ihrem
                Gerät gespeichert, nachdem Sie sich auf der Plattform eingeloggt
                haben.
            </p>
            <p>
                Wir verwenden ebenfalls einen Cookie, der die
                Privatsphäre-Einstellungen des Nutzers erfasst und uns bei
                Besuchen der Plattform in der Zukunft zu mitteilt, welche
                Privatsphäre-Einstellungen der Nutzer gewählt hat bzw. zu
                welchen Cookies er seine Einwilligung erteilt hat. Dieser
                Cookies ist erforderlich, um unsere gesetzlichen Verpflichtungen
                zur Dokumentation von Einwilligungserklärungen zu erfüllen (Art.
                6 Abs. 1 lit. c) DSGVO).
            </p>
            <p>
                Ferner verwenden wir Cookies, die von Klokan Technologies GmbH
                (maptiler) zur Verfügung gestellt werden und erforderlich sind,
                um unseren Karten- und Tracking-Dienst für Waggons anzubieten
                und somit für die Erfüllung unserer vertraglichen Pflichten
                erforderlich sind (Art. 6 Abs. 1 lit. b) DSGVO).
            </p>
            <p>
                Mixpanel setzt Cookies, um eindeutige Nutzer zu identifizieren,
                die die Plattform besuchen.
            </p>
            <p>
                Der Nutzer kann sämtliche auf seinem Endgerät abgelegten Cookies
                jederzeit löschen.
            </p>
            <p>
                Der Nutzer kann seinen Browser so einstellen, dass er über das
                Setzen von Cookies vorher informiert wird und einzeln über deren
                Annahme entscheiden oder die Annahme von Cookies für bestimmte
                Fälle oder generell ausschließen. Bei der Nichtannahme von
                Cookies kann die Funktionalität der Plattform eingeschränkt
                sein.
            </p>
        </li>
        <li class="privacy__li__headline">
            <h4>Weitergabe personenbezogener Daten</h4>
            <p>
                Die personenbezogenen Daten des Nutzers werden ausschließlich an
                technische Dienstleister weitergegeben, die bei der
                Zurverfügungstellung der Plattform unterstützen und Daten
                ausschließlich im Auftrag und nach den Weisungen von VTG
                verarbeiten (z.B. Hosting-Service Provider). Derzeit wird die
                Plattform von Amazon Web Services auf Servern in Deutschland
                gehostet. Sofern der Nutzer ein zahlungspflichtiges Tool oder
                Feature gebucht hat, können wir die mitgeilten Zahlungsdaten zur
                Abbuchung an ein Kreditinstitut oder einen
                Zahlungsdiensteanbieter weiterleiten.
            </p>
            <p>
                Wir verwenden die Dienste von Microsoft Azure, einem Dienst der
                Microsoft Corporation, als Identity Provider für unsere
                Plattform. Zu diesem Zweck ist es erforderlich, dass der Nutzer
                beim Login auf eine Landing Page von Microsoft weitergeleitet
                wird und Microsoft die Login-Daten mit den Daten in unserer
                Datenbank abgleicht. Hierfür kann es erforderlich sein, dass
                Microsoft die Login-Daten an Server in den USA übermittelt.
                Microsoft wird die Daten ausschließlich im Einklang mit unseren
                Weisungen und nur für den Zweck der Identitätsprüfung für den
                Zugang zur Plattform verwenden.
            </p>
            <p>
                Unsere Website verwendet Mixpanel, einen Webanalysedienst der
                Mixpanel, Inc. (nachfolgend "Mixpanel"), um das Nutzerverhalten
                zu analysieren und unsere Services kontinuierlich zu verbessern.
                Mixpanel ermöglicht uns die Erfassung von anonymisierten Daten
                wie Seitenaufrufen, Interaktionen mit der Website sowie
                Navigationsmustern. Diese Daten helfen uns, die
                Benutzererfahrung zu optimieren und Inhalte gezielt zu
                gestalten.
            </p>
            <ol>
                <li>
                    <p class="privacy__li__sub_headline">Verarbeitete Daten</p>
                    <p></p>
                    <p>Mixpanel verarbeitet dabei Daten wie:</p>
                    <ul>
                        <li>
                            <p>Browser-Typ und -Version</p>
                        </li>
                        <li>
                            <p>Betriebssystem</p>
                        </li>
                        <li>
                            <p>Besuchte Seiten und deren Dauer</p>
                        </li>
                        <li>
                            <p>
                                Klickverhalten und andere Interaktionen mit der
                                Website
                            </p>
                        </li>
                        <li>
                            <p>IP-Adresse (gekürzt und anonymisiert)</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">Einsatz von Cookies</p>
                    <p></p>
                    <p>
                        Mixpanel verwendet Cookies, um Informationen über das
                        Verhalten der Nutzer zu sammeln. Diese Cookies speichern
                        keine personenbezogenen Daten, sondern lediglich
                        anonymisierte Nutzungsdaten. Weitere Informationen zu
                        den von Mixpanel eingesetzten Cookies finden Sie in der
                        <a
                            target="_blank"
                            href="https://mixpanel.com/legal/privacy-policy/"
                            >Datenschutzerklärung von Mixpanel</a
                        >.
                    </p>
                </li>

                <li>
                    <p class="privacy__li__sub_headline">Opt-Out</p>
                    <p></p>
                    <p>
                        Sie können der Datenerfassung durch Mixpanel jederzeit
                        widersprechen. Um die Datenerfassung zu deaktivieren,
                        verwenden Sie bitte den folgenden Opt-Out-Link:
                        <a
                            (click)="deactivateTrackingOnThisDevice()"
                            class="deactivate-user-tracking-link"
                            >Hier klicken</a
                        >.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">Rechtsgrundlage</p>
                    <p></p>
                    <p>
                        Die Verarbeitung der Daten erfolgt auf Grundlage von
                        Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse
                        liegt in der Verbesserung und Analyse unserer Website
                        und Services.
                    </p>
                </li>

                <li>
                    <p>
                        Wir verwenden den Service LaunchDarkly des Unternehmens
                        Catamorphic, Co. („<b>Catamorphic</b>“), um unsere
                        Plattform aktuell zu halten, neue Features hinzuzufügen
                        und zur Versionskontrolle. Hierfür kann es erforderlich
                        sein, dass personenbezogene Daten (E-Mail, UserID von
                        Azure und Cognito, Sprache, Zeitzone, die berechtigten
                        Aktionen des Nutzers auf der Plattform und Auskunft über
                        aktivierte Feature Flags (die Auskunft über aktivierte
                        Teile des Quellcodes der Plattform geben)) an Server von
                        Catamorphic übertragen werden, die sich gegebenenfalls
                        in Ländern außerhalb des Europäischen Wirtschaftsraums
                        (EWR) befinden. Catamorphic wird die Daten
                        ausschließlich im Einklang mit unseren Weisungen und nur
                        für die beschriebenen Zwecke sowie in aggregierter Form
                        zur statistischen Auswertung verarbeiten. Die
                        Datenschutzerklärung für LaunchDarkly ist
                        <a
                            target="_blank"
                            href="https://launchdarkly.com/policies/privacy"
                            >hier</a
                        >
                        einzusehen.
                    </p>
                    <p>
                        Die Plattform verwendet ferner den Karten-Dienst
                        OpenStreetMap. Zur Anzeige der Kartenfunktion auf der
                        Plattform werden möglicherweise technische Daten
                        (insbesondere IP-Adresse, Browser- und Geräte-Art,
                        Betriebssystem, Referrer-Seite, Datum und Zeit des
                        Zugriffs) automatisch von dem Rechner des Nutzers an den
                        Betreiber von OpenStreetMap, die OpenStreetMap
                        Foundation übermittelt, um diesem die Nutzung des
                        Kartendienstes zu ermöglichen. Die Daten können hierzu
                        womöglich auch an einen Server außerhalb des
                        Europäischen Wirtschaftsraums übertragen werden. Diese
                        Datenübermittlung ist erforderlich, um dem Nutzer die
                        auf dem Kartendienst von OpenStreetMap aufsetzenden
                        Tools der Plattform darstellen zu können und somit
                        unsere vertraglichen Pflichten zu erfüllen (Art. 6 Abs.
                        1 lit. b) DSGVO). Die OpenStreetMap Foundation wird die
                        Daten nicht für andere Zwecke verwenden und
                        üblicherweise direkt nach der Erbringung des Dienstes
                        löschen oder anonymisieren. Weitere Informationen zur
                        Datenverarbeitungspraxis der OpenStreetMap Foundation
                        sind
                        <a
                            target="_blank"
                            href="https://wiki.osmfoundation.org/wiki/Privacy_Policy#Detailed_Information"
                            >hier</a
                        >
                        abrufbar.
                    </p>
                    <p>
                        Auf der Plattform werden auch Schriftarten über die
                        Google Fonts API, einem Dienst von Google LLC in
                        Mountain View, USA, eingebunden. Diese Schriftarten
                        werden von den Servern von Google in den USA direkt beim
                        Öffnen der Plattform an das Gerät des Nutzers
                        übermittelt, um dem Nutzer die korrekte Darstellung der
                        Plattform zu ermöglichen (Art. 6 Abs. 1 lit. f) DSGVO).
                        Hierfür wird u.a. Ihre IP-Adresse an den Google-Server
                        weitergeleitet. Diese Daten werden nicht mit anderen
                        Informationen, die Google ggf. über Sie sammelt,
                        zusammengeführt. Die Verwendung von bestimmten
                        Schriftarten wird von Google anonymisiert ausgewertet,
                        um die Beliebtheit von bestimmten Schriftarten zu
                        analysieren.
                    </p>
                    <p>
                        Wir verwenden ferner auf der Plattform auch den Dienst
                        Datadog, eine Cloud-Lösung von Datadog, Inc. in den USA
                        zur Funktionsüberwachung und zur Auswertung und
                        künftigen Vermeidung von Software-Fehlern auf der
                        Plattform. Hierfür ist es erforderlich, dass bestimmte
                        technische Daten von den Endgeräten der Nutzer, die
                        Fehler erleiden, automatisch an die Server von Datadog
                        in den USA weitergeleitet werden. Zu diesen Daten gehört
                        die IP-Adresse sowie die Browser- und
                        Betriebssystemversion des Endgeräts. Die übermittelnde
                        IP-Adresse des jeweiligen Nutzers wird von Datadog nicht
                        gespeichert und uns auch zu keinem Zeitpunkt zur
                        Verfügung gestellt. Die Erfassung und Auswertung dieser
                        Daten ist erforderlich, um die Funktionsfähigkeit und
                        Sicherheit der Plattform sicherzustellen (Art. 6 Abs. 1
                        lit. f) DSGVO). Ferner hat der Nutzer die Möglichkeit,
                        bei Störungen auf der Plattform ein Feedback (anonym
                        oder freiwillig mit Namensangabe) abzugeben. Dies ist
                        freiwillig. Sollte der Nutzer sich hierzu entscheiden,
                        wird dieses Feedback ebenfalls aufgrund Art. 6 Abs. 1
                        lit. f) DSGVO an Datadog übermittelt und hilft uns bei
                        der Auswertung und Beseitigung der Störung.
                    </p>
                    <p>
                        Der Nutzer kann auf seinen Wunsch auch ohne das
                        Vorliegen einer Störung freiwillig (anonym oder
                        freiwillig mit Namensangabe) ein Feedback zur Plattform
                        abgeben, das dann ebenfalls aufgrund Art. 6 Abs. 1 lit.
                        f) DSGVO mit den oben genannten anonymisierten
                        technischen Daten an Datadog übermittelt und von uns zur
                        Verbesserung der Plattform verwendet wird. Hierbei
                        werden keine personenbezogenen Daten erhoben oder mit
                        Datadog geteilt.
                    </p>
                    <p>
                        Soweit wir personenbezogene Daten an Empfänger außerhalb
                        des Europäischen Wirtschaftsraums (EWR) übertragen, und
                        das Empfängerland kein von der Europäischen Kommission
                        festgestelltes angemessenes Schutzniveau für
                        personenbezogene Daten gewährleistet, treffen wir selbst
                        angemessene Maßnahmen, um ein angemessenes Schutzniveau
                        zu gewährleisten. Diese Maßnahmen können beispielsweise
                        im Abschluss von sogenannten EU-
                        Standardvertragsklauseln liegen. Wo diese nicht
                        ausreichen, um ein angemessenes Schutzniveau zu
                        gewährleisten, treffen wir entsprechende zusätzliche
                        Maßnahmen. Wir stellen Ihnen Kopien der geschlossenen
                        Standardvertragsklauseln und Beschreibungen der
                        zusätzlichen Schutzmaßnahmen auf Anfrage gerne zur
                        Verfügung. Wenden Sie sich bei Interesse gerne an
                        <a href="mailto:datenschutz@vtg.com"
                            >datenschutz&#64;vtg.com</a
                        >.
                    </p>
                </li>
            </ol>
        </li>
        <li class="privacy__li__headline">
            <h4>Rechte des Nutzers</h4>
            <p>
                Der Nutzer hat das Recht, gemäß Art. 15 DSGVO Auskunft über die
                zu ihm bei uns gespeicherten Daten zu verlangen, gemäß Art. 16
                DSGVO die Berichtigung unrichtiger Daten zu verlangen sowie
                gemäß Art. 17 DSGVO die Löschung oder gemäß Art. 18 DSGVO die
                Einschränkung der Verarbeitung von Daten zu verlangen. Ferner
                steht dem Nutzer gemäß Art. 21 DSGVO das Recht zu, gegen die
                Verarbeitung seiner Daten gemäß Art. 21 Abs. 1 DSGVO Widerspruch
                einzulegen, sofern sich der Grund für den Widerspruch aus seiner
                besonderen Situation ergibt und es sich um Daten handelt, die
                wir zur Wahrung eines unserer schutzwürdigen Interessen (Art. 6
                Abs. 1 S. 2 lit. f) DSGVO) verarbeiten. Ein Widerspruchsrecht
                besteht ferner im Falle der Nutzung Ihrer Daten für
                Direktwerbung (Art. 21 Abs. 2 DSGVO). Der Nutzer hat ferner das
                Recht, die von ihm zur Verfügung gestellten Daten unter den
                Voraussetzungen des Art. 20 DSGVO in einem gängigen
                maschinenlesbaren Dateiformat ausgehändigt zu bekommen. Ferner
                steht dem Nutzer ein Beschwerderecht nach Art. 77 DSGVO zu, wenn
                er der Auffassung ist, dass wir seine personenbezogenen Daten
                nicht im Einklang mit geltendem Recht verarbeiten. Diese
                Beschwerde kann bei jeder zuständigen Aufsichtsbehörde
                eingereicht werden.
            </p>
        </li>
        <li class="privacy__li__headline">
            <h4>Ansprechpartner für Datenschutz</h4>
            <p>
                Bei Fragen zur Erhebung, Verarbeitung oder Nutzung
                personenbezogener Daten, bei Auskünften, Berichtigung,
                Einschränkung der Nutzung oder Löschung von Daten sowie Widerruf
                erteilter Einwilligungen oder sonstiger Geltendmachung von
                Rechten kann sich der Nutzer an folgende Adressen wenden:
            </p>
            <p>
                <a href="mailto:datenschutz@vtg.com">datenschutz&#64;vtg.com</a>
                oder unter unserer Postadresse mit dem Zusatz „der
                Datenschutzbeauftragte“
            </p>
        </li>
        <li class="privacy__li__headline">
            <h4>Datensicherheit</h4>
            <p>
                Die personenbezogenen Daten des Nutzers werden bei der Nutzung
                der Plattform verschlüsselt mittels SSL/TLS über das Internet
                übertragen.
            </p>
            <p>
                Wir sichern die Plattform und sonstigen Systeme durch technische
                und organisatorische Maßnahmen gegen Verlust, Zerstörung,
                Zugriff, Veränderung oder Verbreitung von Daten durch unbefugte
                Personen.
            </p>
        </li>
    </ol>
</section>
