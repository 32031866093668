@if ((authenticated$ | async) === AuthState.Authenticated) {
    <app-system-response
        small
        responseType="empty"
        title="There is nothing here for you yet. But we are working on it!"
        message="NotFoundAuthanticated"
        flatPrimaryButtonText="Log In"
        flatPrimaryButtonIcon="arrow-right"
        flatPrimaryButtonIconPosition="right"
        (flatPrimaryButtonClick)="signIn()"
    >
    </app-system-response>
} @else if ((authenticated$ | async) === AuthState.Unauthenticated) {
    <app-system-response
        small
        responseType="empty"
        customIcon="error-permission"
        title="Seems like you are not logged in."
        message="NotFoundUnauthanticated"
        flatPrimaryButtonText="Log In"
        flatPrimaryButtonIcon="arrow-right"
        flatPrimaryButtonIconPosition="right"
        (flatPrimaryButtonClick)="signIn()"
    >
    </app-system-response>
} @else {
    <app-loading-pin></app-loading-pin>
}
