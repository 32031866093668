export enum NotificationAction {
    notificationsClicked = 'notificationsClicked',
    settingsClicked = 'settingsClicked',
    notificationItemClicked = 'notificationItemClicked',
    notificationItemDeleted = 'notificationItemDeleted',
    notificationItemBookmarked = 'notificationItemBookmarked',
    notificationShowAllButton = 'notificationShowAllButton',
    checkboxInAppNotificationAllSelected = 'checkboxInAppNotificationAllSelected',
    checkboxMailNotificationAllSelected = 'checkboxMailNotificationAllSelected',
    changesResetted = 'changesResetted',
    changesSaved = 'changesSaved',
    useCaseInAppNotificationSelected = 'useCaseInAppNotificationSelected',
    useCaseEmailNotificationSelected = 'useCaseEmailNotificationSelected',
    useCaseEmailNotificationIntervalSelected = 'useCaseEmailNotificationIntervalSelected',
    switchShowBookmarkedNotificationsActivated = 'switchShowBookmarkedNotificationsActivated',
    switchShowBookmarkedNotificationsDeactivated = 'switchShowBookmarkedNotificationsDeactivated',
    filterSelected = 'filterSelected',
    monthClicked = 'monthClicked',
    loadMoreClicked = 'loadMoreClicked',
}

export enum NotificationArea {
    header = 'header',
    overlay = 'overlay',
    notificationSettings = 'notificationSettings',
    notifications = 'notifications',
}
