import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatRippleModule } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { GroupManagementHeaderComponent } from '@group-management-lib/components/group-management-header/group-management-header.component'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { DeveloperToolsModule } from '@platform-lib/components/developer-tools/developer-tools.module'
import { UserMenuModule } from '@platform-lib/components/user-menu/user-menu.module'
import { NotificationPanelModule } from '@platform-lib/notification/notification-panel/notification-panel.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { TrackingClickDirective } from '@tracking-lib/tracking-click/tracking-click.directive'
import { HeaderComponent } from './header.component'

@NgModule({
    declarations: [HeaderComponent],
    exports: [HeaderComponent],
    imports: [
        CommonModule,
        DeveloperToolsModule,
        GroupManagementHeaderComponent,
        RouterModule,
        MatIconModule,
        MatRippleModule,
        MatToolbarModule,
        NotificationPanelModule,
        TranslationModule,
        UserMenuModule,
        MatTooltipModule,
        ButtonModule,
        TrackingClickDirective,
    ],
})
export class HeaderModule {}
