import { Component, ElementRef, HostListener, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AuthnService } from '@auth-util-lib/authn.service'
import { UserProfileService } from '@auth-util-lib/user-profile.service'
import { Animations } from '@shared-util-lib/animations/animations'
import {
    NavigationAction,
    NavigationArea,
} from '@tracking-lib/use-case-tracking-models/navigation.tracking.model'
import { TrackingCategory } from '@tracking-lib/tracking.model'

@Component({
    animations: [Animations.flipIcon, Animations.fadeIn],
    selector: 'app-user-menu',
    styleUrls: ['./user-menu.component.scss'],
    templateUrl: './user-menu.component.html',
})
export class UserMenuComponent {
    @ViewChild('userMenu', { static: false }) public userMenu?: ElementRef

    readonly user$ = this.profile.profileChanges()
    expanded = false

    @HostListener('document:click', ['$event'])
    onClick(event: Event) {
        if (
            this.userMenu &&
            !this.userMenu.nativeElement.contains(event.target)
        ) {
            this.expanded = false
        }
    }
    constructor(
        private authn: AuthnService,
        private profile: UserProfileService,
        private router: Router
    ) {}

    signOut() {
        this.authn.initSignOutFlow()
        this.expanded = false
    }

    handleAccountSettingsClick() {
        this.router.navigate(['/account'])
        this.toggleMenu()
    }

    toggleMenu() {
        this.expanded = !this.expanded
    }

    protected readonly NavigationArea = NavigationArea
    protected readonly NavigationAction = NavigationAction
    protected readonly TrackingCategory = TrackingCategory
}
