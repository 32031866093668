import {
    CdkOverlayOrigin,
    ConnectedPosition,
    OverlayModule,
} from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { GroupManagementDropdownComponent } from '@group-management-lib/components/group-management-dropdown/group-management-dropdown.component'
import {
    loadGroupsFromBackend,
    refreshBackend,
} from '@group-management-lib/redux/group-management.actions'
import {
    hasGroupLoadingError,
    selectGroupOptions,
    selectGroupsFromBackendLoading,
    selectIsMemberOfDefaultGroup,
    selectRefreshBackend,
    selectSelectedGroups,
} from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { LoadingModule } from '@loading-lib/loading.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { TranslationService } from '@localization-lib/language/translation.service'
import { Store, select } from '@ngrx/store'
import { Animations } from '@shared-util-lib/animations/animations'
import { DataQaTagPipeModule } from '@shared-util-lib/pipes/dataQaTag/data-qa-tag-pipe.module'
import { TrackingCategory } from '@tracking-lib/tracking.model'
import { TrackingService } from '@tracking-lib/tracking.service'
import {
    GroupManagementAction,
    GroupManagementArea,
} from '@tracking-lib/use-case-tracking-models/group-management.tracking.model'
import { Subscription, combineLatest, last, map, throttleTime } from 'rxjs'

@Component({
    selector: 'app-group-management-header',
    styleUrls: ['./group-management-header.component.scss'],
    templateUrl: './group-management-header.component.html',
    animations: [Animations.flipIcon, Animations.overlay],
    standalone: true,
    imports: [
        CommonModule,
        DataQaTagPipeModule,
        GroupManagementDropdownComponent,
        LoadingModule,
        MatIconModule,
        MatTooltipModule,
        OverlayModule,
        TranslationModule,
    ],
})
export class GroupManagementHeaderComponent implements OnDestroy, OnInit {
    subscriptions = new Subscription()
    isOverlayOpen = false
    loading = false

    @Input() headerActivated: boolean | undefined
    @ViewChild('overlayDropdown') overlayDropdownRef?: ElementRef<HTMLElement>

    constructor(
        private translationService: TranslationService,
        public elementRef: ElementRef,

        private renderer: Renderer2,
        private store: Store<GroupManagementState>,
        private trackingService: TrackingService
    ) {
        // listen to refresh action and dispatch backend call
        // can't be in service, because services can't dispatch effects
        this.subscriptions.add(
            this.store
                .pipe(select(selectRefreshBackend))
                .subscribe((triggerRefresh) => {
                    if (triggerRefresh) {
                        this.store.dispatch(loadGroupsFromBackend())
                        this.store.dispatch(refreshBackend({ refresh: false }))
                    }
                })
        )

        this.subscriptions.add(
            this.loading$.subscribe((isLoading) => (this.loading = isLoading))
        )
    }

    //#####################
    // Dropdown
    //#####################

    overlayOrigin = new CdkOverlayOrigin(this.elementRef)

    readonly overlayPositions: ConnectedPosition[] = [
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
        },
    ]

    //#####################
    // Header
    //#####################

    tooltipText$ = this.translationService.translator$.pipe(
        map((translate) =>
            this.headerActivated
                ? null
                : translate('GroupManagementHeaderTooltip')
        )
    )

    groupNames$ = combineLatest([
        this.store.pipe(select(selectSelectedGroups)),
        this.store.pipe(select(selectGroupOptions)),
        this.store.pipe(select(selectIsMemberOfDefaultGroup)),
        this.translationService.translator$,
    ]).pipe(
        map(
            ([
                selectedGroups,
                selectableGroups,
                isMemberOfDefault,
                translate,
            ]) => {
                // Note: selectedGroups and selectableGroups do NOT contain the default group

                // if users cannot select groups and are no members of the default group, there is just nothing to see
                if (selectableGroups.length === 0 && !isMemberOfDefault) {
                    return translate('No groups')
                }

                // if users selects nothing, they will see wagons from all their groups implicitly
                if (selectedGroups?.length === 0) {
                    return translate('All wagons')
                }

                // users selected all groups explicitly
                if (selectedGroups?.length === selectableGroups.length) {
                    return translate('All groups')
                }

                // else just show the selected groups
                return selectedGroups
                    ?.map((group) => group.name)
                    .sort((a, b) => a.localeCompare(b))
                    .join(', ')
            }
        )
    )

    loading$ = this.store.pipe(select(selectGroupsFromBackendLoading))

    error$ = this.store
        .pipe(select(hasGroupLoadingError))
        .pipe(throttleTime(300), last())

    handleHeaderClick() {
        if (this.headerActivated && !this.loading) {
            this.trackingService.track({
                category: TrackingCategory.groupManagement,
                area: GroupManagementArea.header,
                action: GroupManagementAction.clicked,
                attributes: { state: this.isOverlayOpen ? 'close' : 'open' },
            })
            this.toggleOverlay()
        }
    }

    toggleOverlay() {
        this.isOverlayOpen = !this.isOverlayOpen
    }

    onWindowClick(event: MouseEvent) {
        const path = event.composedPath()
        const header = this.elementRef.nativeElement
        const dropdown = this.overlayDropdownRef?.nativeElement

        if (
            this.isOverlayOpen &&
            dropdown &&
            path.indexOf(dropdown) === -1 &&
            path.indexOf(header) === -1
        ) {
            this.toggleOverlay()
        }
    }

    ngOnInit(): void {
        this.renderer.listen(window, 'click', this.onWindowClick.bind(this))
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
