export enum DispatchManagementArea {
    adminForecast = 'adminForecast',
    adminScreen = 'adminScreen',
    adminTrackList = 'adminTrackList',
    alreadyShippedTable = 'alreadyShippedTable',
    commissionedTransportsTable = 'commissionedTransportsTable',
    declaredCargoTable = 'declaredCargoTable',
    inboundWagonsTable = 'inboundWagonsTable',
    overviewScreen = 'overviewScreen',
    stationOverviewPlanning = 'stationOverviewPlanning',
    stationOverviewScreen = 'stationOverviewScreen',
    stationOverviewWagonOverview = 'stationOverviewWagonOverview',
    wagonsOnSiteTable = 'wagonsOnSiteTable',
}

export enum DispatchManagementAction {
    alreadyShippedTabClicked = 'alreadyShippedTabClicked',
    commissionedTransportsTabClicked = 'commissionedTransportsTabClicked',
    declaredCargoTabClicked = 'declaredCargoTabClicked',
    documentDownloaded = 'documentDownloaded',
    fileUploaded = 'fileUploaded',
    forecastTabClicked = 'forecastTabClicked',
    inboundWagonsTabClicked = 'inboundWagonsTabClicked',
    manageDataClicked = 'manageDataClicked',
    monthSelected = 'monthSelected',
    planningRefreshClicked = 'planningRefreshClicked',
    planningStationClicked = 'planningStationClicked',
    planningStationSwitched = 'planningStationSwitched',
    planningTabClicked = 'planningTabClicked',
    reserveRefreshClicked = 'reserveRefreshClicked',
    reserveStationSwitched = 'reserveStationSwitched',
    saveClicked = 'saveClicked',
    stationAdded = 'stationAdded',
    stationRemoved = 'stationRemoved',
    stationSelected = 'stationSelected',
    stationSwitched = 'stationSwitched',
    tableFilterApplied = 'tableFilterApplied',
    tableFilterReset = 'tableFilterReset',
    tableFilterSorted = 'tableFilterSorted',
    tableHeaderNameClicked = 'tableHeaderNameClicked',
    tableResetClicked = 'tableResetClicked',
    trackListTabClicked = 'trackListTabClicked',
    wagonOverviewTabClicked = 'wagonOverviewTabClicked',
    wagonRefreshClicked = 'wagonRefreshClicked',
    wagonsOnSiteTabClicked = 'wagonsOnSiteTabClicked',
    wagonStationClicked = 'wagonStationClicked',
    wagonStationSwitched = 'wagonStationSwitched',
}
