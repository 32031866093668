export enum WagonHireArea {
    newRequest = 'newRequest',
    sentRequests = 'sentRequests',
}

export enum WagonHireAction {
    brakeShoeFilterClicked = 'brakeShoeFilterClicked',
    checkSelectedWagonsClicked = 'checkSelectedWagonsClicked',
    connectorFilterClicked = 'connectorFilterClicked',
    dialogDiscardSelectionCanceled = 'dialogDiscardSelectionCanceled',
    dialogDiscardSelectionClicked = 'dialogDiscardSelectionClicked',
    discardRequestButtonClicked = 'discardRequestButtonClicked',
    locationFilterClicked = 'locationFilterClicked',
    numberOfWagonsDeselected = 'numberOfWagonsDeselected',
    sendRequestButtonClicked = 'sendRequestButtonClicked',
    tabClicked = 'tabClicked',
    uicClassFilterClicked = 'uicClassFilterClicked',
    vtgTypeFilterClicked = 'vtgTypeFilterClicked',
}
