export enum AccountSettingsArea {
    personalData = 'personalData',
    notificationSettings = 'notificationSettings',
    preferences = 'preferences',
    loginData = 'loginData',
}

export enum AccountSettingsAction {
    serviceDeskClicked = 'serviceDeskClicked',
    settingsChangedClicked = 'settingsChangedClicked',
    selectLanguageClicked = 'selectLanguageClicked',
    englishClicked = 'englishClicked',
    germanCLicked = 'germanCLicked',
    passwordChanged = 'passwordChanged',
}
