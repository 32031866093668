export enum GroupManagementArea {
    header = 'header',
    overlay = 'overlay',
    groupManagement = 'groupManagement',
    editor = 'editor',
    creator = 'creator',
    deleteDialog = 'deleteDialog',
    quitDialog = 'quitDialog',
}

export enum GroupManagementAction {
    clicked = 'clicked',
    settingsClicked = 'settingsClicked',
    filterValueEntered = 'filterValueEntered',
    filterValueDeleted = 'filterValueDeleted',
    applied = 'applied',
    reset = 'reset',
    groupDetailsClicked = 'groupDetailsClicked',
    newGroupCreated = 'newGroupCreated',
    groupEditClicked = 'groupEditClicked',

    tableReset = 'tableReset',
    tableFilterApplied = 'tableFilterApplied',
    tableFilterReset = 'tableFilterReset',
    tableFilterSorted = 'tableFilterSorted',

    newGroupClicked = 'newGroupClicked',
    nameEntered = 'nameEntered',
    wagonNumberPasted = 'wagonNumberPasted',
    wagonNumberSelected = 'wagonNumberSelected',
    invalidItemsDeleted = 'invalidItemsDeleted',
    invalidItemDeleted = 'invalidItemDeleted',
    invalidItemEditClicked = 'invalidItemEditClicked',
    invalidItemEdited = 'invalidItemEdited',
    invalidItemCanceled = 'invalidItemCanceled',
    itemDeleted = 'itemDeleted',

    wagonNumberFieldReset = 'wagonNumberFieldReset',
    wagonNumberClicked = 'wagonNumberClicked',
    createClicked = 'createClicked',
    nextClicked = 'nextClicked',
    groupUsersSelected = 'groupUsersSelected',
    accessPeriodStartEntered = 'accessPeriodStartEntered',
    accessPeriodEndEntered = 'accessPeriodEndEntered',
    backClicked = 'backClicked',

    deleteGroupClicked = 'deleteGroupClicked',
    wagonItemDeleted = 'wagonItemDeleted',
    saveChangesClicked = 'saveChangesClicked',

    closeClicked = 'closeClicked',

    canceled = 'canceled',
    confirmed = 'confirmed',
    continued = 'continued',
    quitted = 'quitted',
}
