<div
    [ngClass]="
        isPrimarySnackbar() ? 'snackbar--primary' : 'snackbar--secondary'
    "
    class="flex gap-4 items-center min-w-[344px]"
>
    <mat-icon
        *ngIf="data.iconName"
        [svgIcon]="data.iconName"
        [ngClass]="{
            'primary-color': data.iconColor === 'primary',
            'success-color': data.iconColor === 'success',
            'warning-color': data.iconColor === 'warning',
            'error-color': data.iconColor === 'error',
        }"
        class="snackbar-icon"
    ></mat-icon>
    <div class="snackbar-body">
        <span
            matSnackBarLabel
            class="snackbar-label"
            [translate]="(contentText$ | async)?.text"
            [params]="(contentText$ | async)?.parameter"
        >
        </span>

        <span matSnackBarActions>
            <app-button
                *ngIf="data.dismissActionButtonText"
                matSnackBarAction
                [buttonType]="
                    isPrimarySnackbar() ? 'basic-highlight' : 'basic-primary'
                "
                (buttonClick)="snackbarRef.dismissWithAction()"
            >
                {{ data.dismissActionButtonText | translate }}
            </app-button>
            <app-button
                *ngIf="!data.dismissActionButtonText"
                [buttonType]="
                    isPrimarySnackbar() ? 'basic-inverted' : 'basic-primary'
                "
                matSnackBarAction
                icon="close"
                iconButton
                class="icon"
                (buttonClick)="onCloseClicked()"
            >
            </app-button>
        </span>
    </div>
</div>
