import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from '@env-lib/api/api.service'
import { Observable } from 'rxjs'
import {
    ChangeAccessPeriodRequest,
    ChangeGroupResourcesRequest,
    ChangeGroupSelectionRequest,
    ChangeMembersRequest,
    CreateGroupRequest,
    CreateGroupResponse,
    EditGroupRequest,
    MyAvailableResources,
    MyColleaguesResponse,
    MyGroupsMetadataResponse,
    MyGroupsWithResourcesResponse,
    ParsedAccessPeriod,
} from './group-management.model'

@Injectable({
    providedIn: 'root',
})
export class GroupManagementApiService {
    constructor(
        private api: ApiService,
        private http: HttpClient
    ) {}

    getMyGroupsMetaData(): Observable<MyGroupsMetadataResponse> {
        const url = `${this.api.auth.backendUrl}/v2/users/me/groups/metadata`
        return this.http.get<MyGroupsMetadataResponse>(url)
    }

    getMyColleagues(): Observable<MyColleaguesResponse> {
        const url = `${this.api.auth.backendUrl}/v2/users/me/colleagues`
        return this.http.get<MyColleaguesResponse>(url)
    }

    getMyGroupsWithResources(): Observable<MyGroupsWithResourcesResponse> {
        const url = `${this.api.auth.backendUrl}/v2/users/me/groups`
        return this.http.get<MyGroupsWithResourcesResponse>(url)
    }

    createGroup(request: CreateGroupRequest): Observable<CreateGroupResponse> {
        return this.http.post<CreateGroupResponse>(
            `${this.api.auth.backendUrl}/v2/groups`,
            request
        )
    }

    editGroup(groupId: string, request: EditGroupRequest) {
        return this.http.put(
            `${this.api.auth.backendUrl}/v2/groups/${groupId}`,
            request
        )
    }

    deleteGroup(groupId: string) {
        const url = `${this.api.auth.backendUrl}/v2/groups/${groupId}`
        return this.http.delete(url)
    }

    changeGroupSelection(
        changeGroupSelectionRequest: ChangeGroupSelectionRequest
    ): Observable<MyGroupsMetadataResponse> {
        return this.http.put<MyGroupsMetadataResponse>(
            `${this.api.auth.backendUrl}/v2/users/me/groups/selections`,
            changeGroupSelectionRequest
        )
    }

    changeMembersGroup(
        groupId: string,
        changeMembersRequest: ChangeMembersRequest
    ): Observable<void> {
        return this.http.put<void>(
            `${this.api.auth.backendUrl}/v2/groups/${groupId}/members`,
            changeMembersRequest
        )
    }

    changeAccessPeriod(
        groupId: string,
        accessPeriod: ParsedAccessPeriod
    ): Observable<void> {
        const request = {
            beginOn: accessPeriod.beginOn?.toISOString(),
            endOn: accessPeriod.endOn?.toISOString(),
        } as ChangeAccessPeriodRequest

        return this.http.put<void>(
            `${this.api.auth.backendUrl}/v2/groups/${groupId}/access-period`,
            request
        )
    }

    changeResourcesForGroup(
        groupId: string,
        changeResourcesRequest: ChangeGroupResourcesRequest
    ): Observable<void> {
        return this.http.put<void>(
            `${this.api.auth.backendUrl}/v2/groups/${groupId}/resources`,
            changeResourcesRequest
        )
    }

    getMyAvailableResourcesForGroup(
        groupId?: string
    ): Observable<MyAvailableResources> {
        let url: string

        if (groupId) {
            url = `${this.api.auth.backendUrl}/v2/users/me/groups/${groupId}/available-resources`
        } else {
            url = `${this.api.auth.backendUrl}/v2/users/me/groups/available-resources`
        }

        return this.http.get<MyAvailableResources>(url)
    }
}
