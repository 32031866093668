import { Component } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute } from '@angular/router'
import { SnackbarComponent } from '@shared-ui-lib/snackbar/snackbar.component'
import { SnackbarData } from '@shared-ui-lib/snackbar/snackbar.model'
import { TrackingService } from '@tracking-lib/tracking.service'

@Component({
    selector: 'app-privacy-content',
    template: '',
})
export class PrivacyContentComponent {
    constructor(
        private trackingService: TrackingService,
        private snackBar: MatSnackBar,
        private route: ActivatedRoute
    ) {
        if (this.route.snapshot.queryParams?.deactivateTracking === '1') {
            this.deactivateTrackingOnThisDevice()
        }
    }

    deactivateTrackingOnThisDevice() {
        this.trackingService.deactivateTrackingOnThisDevice()
        this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 5000,
            panelClass: ['primary'],
            data: {
                contentText: 'PrivacyCookieOptOutSuccess',
                snackbarType: 'primary',
                iconName: 'check-filled',
            } as SnackbarData,
        })
    }
}
