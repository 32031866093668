<app-dialog-wrapper>
    <div title>
        <translate [translate]="data.title"></translate>
    </div>
    <div class="pb-4" body>
        <span [translate]="data.bodyText"></span>
    </div>
    <app-button-group actions alignment="right">
        <app-button
            buttonType="basic-danger"
            [breakLines]="true"
            (buttonClick)="onCancelClick()"
            [trackingData]="data?.cancelButtonTracking"
        >
            <span [translate]="data.cancelButtonText"></span>
        </app-button>

        <app-button
            buttonType="flat-danger"
            [breakLines]="true"
            (buttonClick)="onCloseClick()"
            [trackingData]="data?.actionButtonTracking"
        >
            <span [translate]="data.actionButtonText"></span>
        </app-button>
    </app-button-group>
</app-dialog-wrapper>
