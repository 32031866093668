import { createFeatureFlag } from '@feature-flag-lib/createFeatureFlag'

export const FeatureFlags = {
    DashboardNewsWidget: createFeatureFlag('dashboard-news-widget', false),
    DeveloperTools: createFeatureFlag('developer-tools', false),
    ExtendedSensorTimeWindows: createFeatureFlag('sensor-time-windows', false),
    FastTrackFilter: createFeatureFlag('fast-track-filter', false),
    FastTrackWidget: createFeatureFlag('fast-track-widget', false),
    FleetOverviewCes: createFeatureFlag('fleet-overview-ces', false),
    FleetOverviewRatingDialog: createFeatureFlag(
        'fleet-management-show-rating-popup',
        { enablePopup: false }
    ),
    FleetTablesExport: createFeatureFlag('fleet-tables-export', false),
    FleetWorkshopTargetDeliveryDate: createFeatureFlag(
        'fleet-workshop-target-delivery-date',
        false
    ),
    FleetWorkshopTargetDeliveryDateOnboardingPopup: createFeatureFlag(
        'fleet-workshop-target-delivery-date-onboarding-popup',
        false
    ),
    MixpanelDebugMode: createFeatureFlag('mixpanel-debug-mode', false),
    OperationalDocumentCes: createFeatureFlag(
        'operational-document-ces',
        false
    ),
    SensorsExtendedTimeWindows: createFeatureFlag('sensor-time-windows', false),
    WagonTrackingCes: createFeatureFlag('wagon-tracking-ces', false),
    WagonTrackingRatingPopup: createFeatureFlag('pathfinder-rating-popup', {
        enablePopup: false,
    }),
}
