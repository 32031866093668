import { ComponentType } from '@angular/cdk/overlay'
import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Action } from '@auth-util-lib/auth.model'
import { BannerService } from '@banner-lib/banner.service'
import { generateHiddenStorageKey } from '@banner-lib/util/generateHiddenStorageKey'
import {
    Language,
    MultiLanguageString,
} from '@localization-lib/language/models/Language'
import { TranslationService } from '@localization-lib/language/translation.service'
import { BehaviorSubject, Subscription, combineLatest, switchMap } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, OnDestroy {
    @HostBinding('class.hidden') _hideBanner = false

    Language = Language

    private subscriptions = new Subscription()

    action$ = new BehaviorSubject<Action | undefined>(undefined)

    @Input() set action(action: Action) {
        this.action$.next(action)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() dialogToOpen?: ComponentType<any>

    @Input() id?: string

    @Input() routerLink?: string

    @Input() icon = 'star'
    @Input() iconTextColor = 'text-caution'

    bannerTextInput$ = new BehaviorSubject<string | MultiLanguageString>('')

    @Input() set bannerText(input: string | MultiLanguageString) {
        this.bannerTextInput$.next(input)
    }

    buttonTextInput$ = new BehaviorSubject<
        string | MultiLanguageString | undefined
    >(undefined)

    @Input() set buttonText(input: string | MultiLanguageString | undefined) {
        this.buttonTextInput$.next(input)
    }

    @Input() buttonIcon?: string

    @Input() closeable = false

    constructor(
        private bannerService: BannerService,
        private router: Router,

        private matDialog: MatDialog,
        private translationService: TranslationService
    ) {}

    locale$ = this.translationService.selectedLanguage$

    bannerText$ = combineLatest([
        this.translationService.selectedLanguage$,
        this.translationService.translator$,
        this.bannerTextInput$,
    ]).pipe(
        map(([locale, translator, bannerTextInput]) => {
            if (typeof bannerTextInput === 'string') {
                return translator(bannerTextInput)
            } else {
                return locale === Language.DE
                    ? bannerTextInput.de
                    : bannerTextInput.en
            }
        })
    )

    buttonText$ = combineLatest([
        this.translationService.selectedLanguage$,
        this.translationService.translator$,
        this.buttonTextInput$,
    ]).pipe(
        map(([locale, translator, buttonTextInput]) => {
            if (!buttonTextInput) return null

            if (typeof buttonTextInput === 'string') {
                return translator(buttonTextInput)
            } else {
                return locale === Language.DE
                    ? buttonTextInput.de
                    : buttonTextInput.en
            }
        })
    )

    ngOnInit() {
        this.subscriptions.add(
            this.action$
                .pipe(
                    switchMap((action) =>
                        this.bannerService.showBanner$(action, this.id)
                    )
                )
                .subscribe((visible) => {
                    if (visible) {
                        this._hideBanner = false
                    } else {
                        this._hideBanner = true
                    }
                })
        )
    }

    handleMoreClick() {
        if (this.routerLink) {
            this.router.navigate([this.routerLink])
        } else if (this.dialogToOpen) {
            this.matDialog.open(this.dialogToOpen, {
                autoFocus: false,
                panelClass: 'cookie-dialog',
                width: '400px',
                height: 'auto',
            })
        }
    }

    handleCloseClick() {
        this.bannerService.hideBanner(this.action$.getValue(), this.id)

        this._hideBanner = true
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
