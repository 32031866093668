import { SnackBarAction } from '@tracking-lib/component-tracking-models/snackbar.tracking.model'
import { TableAction } from '@tracking-lib/component-tracking-models/table.tracking.model'
import {
    AccountSettingsAction,
    AccountSettingsArea,
} from '@tracking-lib/use-case-tracking-models/account-settings.tracking.model'
import {
    ContractAction,
    ContractArea,
} from '@tracking-lib/use-case-tracking-models/contract.tracking.model'
import {
    DashboardAction,
    DashboardArea,
} from '@tracking-lib/use-case-tracking-models/dashboard.tracking.model'
import {
    DataForwardingAction,
    DataForwardingArea,
} from '@tracking-lib/use-case-tracking-models/dataForwarding.tracking.model'
import {
    DispatchManagementAction,
    DispatchManagementArea,
} from '@tracking-lib/use-case-tracking-models/dispatchManagement.tracking.model'
import {
    EtaAction,
    EtaArea,
} from '@tracking-lib/use-case-tracking-models/eta.tracking.model'
import {
    FastTrackAction,
    FastTrackArea,
} from '@tracking-lib/use-case-tracking-models/fast-track.tracking.model'
import {
    FeedbackAction,
    FeedbackArea,
} from '@tracking-lib/use-case-tracking-models/feedback.tracking.model'
import {
    FleetOverviewAction,
    FleetOverviewArea,
} from '@tracking-lib/use-case-tracking-models/fleet-overview.tracking.model'
import {
    GroupManagementAction,
    GroupManagementArea,
} from '@tracking-lib/use-case-tracking-models/group-management.tracking.model'
import {
    NavigationAction,
    NavigationArea,
} from '@tracking-lib/use-case-tracking-models/navigation.tracking.model'
import {
    NotificationAction,
    NotificationArea,
} from '@tracking-lib/use-case-tracking-models/notification.tracking.model'
import {
    ServiceDeskAction,
    ServiceDeskArea,
} from '@tracking-lib/use-case-tracking-models/service-desk.tracking.model'
import {
    UserManagementAction,
    UserManagementArea,
} from '@tracking-lib/use-case-tracking-models/user-management.tracking.model'
import {
    WagonDetailsAction,
    WagonDetailsArea,
} from '@tracking-lib/use-case-tracking-models/wagon-details.tracking.model'
import {
    WagonHireAction,
    WagonHireArea,
} from '@tracking-lib/use-case-tracking-models/wagon-hire.tracking.model'
import {
    WagonTrackingAction,
    WagonTrackingArea,
} from '@tracking-lib/use-case-tracking-models/wagon-tracking.tracking.model'

export interface TrackingAttributes {
    [key: string]: string | boolean
}

export enum TrackingCategory {
    accountSettings = 'ac',
    contract = 'cm', // Contract Management
    dashboard = 'dash',
    dataForwarding = 'df',
    dispatchManagement = 'dm',
    eta = 'eta',
    fastTrack = 'ft',
    feedback = 'fb',
    fleetOverview = 'fo',
    groupManagement = 'gm',
    navigation = 'nav',
    notification = 'nf',
    serviceDesk = 'sd',
    userManagement = 'um',
    wagonDetails = 'wd',
    wagonHire = 'wh',
    wagonTracking = 'wt',
}

export const TrackingArea = {
    ...AccountSettingsArea,
    ...ContractArea,
    ...DashboardArea,
    ...DataForwardingArea,
    ...DispatchManagementArea,
    ...EtaArea,
    ...FastTrackArea,
    ...FeedbackArea,
    ...FleetOverviewArea,
    ...GroupManagementArea,
    ...NavigationArea,
    ...NavigationArea,
    ...NotificationArea,
    ...ServiceDeskArea,
    ...UserManagementArea,
    ...WagonDetailsArea,
    ...WagonHireArea,
    ...WagonTrackingArea,
}
export type TrackingArea =
    | AccountSettingsArea
    | ContractArea
    | DashboardArea
    | DataForwardingArea
    | DispatchManagementArea
    | EtaArea
    | FastTrackArea
    | FeedbackArea
    | FleetOverviewArea
    | GroupManagementArea
    | NavigationArea
    | NotificationArea
    | ServiceDeskArea
    | UserManagementArea
    | WagonDetailsArea
    | WagonHireArea
    | WagonTrackingArea

export const TrackingAction = {
    ...AccountSettingsAction,
    ...ContractAction,
    ...DashboardAction,
    ...DataForwardingAction,
    ...DispatchManagementAction,
    ...EtaAction,
    ...FastTrackAction,
    ...FeedbackAction,
    ...FleetOverviewAction,
    ...GroupManagementAction,
    ...NavigationAction,
    ...NavigationAction,
    ...NotificationAction,
    ...ServiceDeskAction,
    ...SnackBarAction,
    ...TableAction,
    ...TableAction,
    ...UserManagementAction,
    ...WagonDetailsAction,
    ...WagonHireAction,
    ...WagonTrackingAction,
}
export type TrackingAction =
    | AccountSettingsAction
    | ContractAction
    | DashboardAction
    | DataForwardingAction
    | DispatchManagementAction
    | EtaAction
    | FastTrackAction
    | FeedbackAction
    | FleetOverviewAction
    | GroupManagementAction
    | NavigationAction
    | NotificationAction
    | ServiceDeskAction
    | SnackBarAction
    | TableAction
    | UserManagementAction
    | WagonHireAction
    | WagonTrackingAction
    | WagonDetailsAction

export interface TrackingData {
    category: TrackingCategory
    area: TrackingArea
    action: TrackingAction
    attributes?: TrackingAttributes
}

export type TrackingBaseData = Omit<TrackingData, 'action' | 'attributes'>
