<span class="text-body-3 mt-6" translate>Access Period</span>
<div class="flex flex-col flex-1 justify-between overflow-auto py-4">
    <span translate class="text-accent py-2"> AccessPeriodDescription </span>

    <div class="flex flex-row pt-4">
        <mat-form-field appearance="outline" class="pr-4">
            <mat-label translate>Start</mat-label>
            <input
                matInput
                [matDatepicker]="startPicker"
                [formControl]="startDateControl"
                [matDatepickerFilter]="isValidStartDate"
                (focus)="startPicker.open()"
                autocomplete="off"
                (dateChange)="onStartDateChange()"
            />
            <app-button
                *ngIf="startDateControl.value"
                (buttonClick)="resetPeriodSelection($event, startDateControl)"
                matSuffix
                icon="close"
                iconButton
            ></app-button>
            <mat-datepicker-toggle
                *ngIf="!startDateControl.value"
                matSuffix
                [for]="startPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="pr-1">
            <mat-label translate>End</mat-label>
            <input
                matInput
                [matDatepicker]="endPicker"
                [formControl]="endDateControl"
                [matDatepickerFilter]="isValidEndDate"
                (focus)="endPicker.open()"
                autocomplete="off"
                (dateChange)="onEndDateChange()"
            />
            <app-button
                *ngIf="endDateControl.value"
                (buttonClick)="resetPeriodSelection($event, endDateControl)"
                matSuffix
                icon="close"
                iconButton
            ></app-button>
            <mat-datepicker-toggle
                *ngIf="!endDateControl.value"
                matSuffix
                [for]="endPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
    </div>
</div>
