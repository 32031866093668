<div class="flex flex-col px-8 py-4 h-full">
    @if (noGeofencesAvailable$ | async) {
        <app-system-response
            responseType="empty"
            small
            title="No geofences available"
            message="NoGeofencesAvailableText"
        >
        </app-system-response>
    } @else {
        <span class="text-body-3" translate>Geofences</span>
        <div class="flex flex-col flex-1 overflow-auto py-4">
            @if (isDefaultGroup$ | async) {
                <app-inline-message
                    class="pb-4"
                    [inlineMessageType]="InlineMessageType.INFO"
                >
                    <div content>
                        <translate>AdminAllGeofences</translate>
                    </div>
                </app-inline-message>
            }
            <span translate class="text-accent pb-4">
                Specify which geofences are linked to this group
            </span>
            <div (click)="onAnyCheckBoxClick()">
                <mat-checkbox
                    color="primary"
                    [checked]="allSelected$ | async"
                    [indeterminate]="someSelected$ | async"
                    [disabled]="
                        (selectAllDisabled$ | async) ||
                        (allCheckboxesDisabled$ | async)
                    "
                    (change)="onSelectAllClick($event.checked)"
                >
                    <translate class="text-body-3">Select all</translate>
                </mat-checkbox>
                @for (
                    geofence of selectedGeofences$ | async;
                    track geofence.geofenceId
                ) {
                    <div class="pl-10">
                        <mat-checkbox
                            color="primary"
                            [checked]="geofence.selected"
                            [disabled]="allCheckboxesDisabled$ | async"
                            (change)="
                                onCheckBoxClick(
                                    geofence.geofenceId,
                                    $event.checked
                                )
                            "
                        >
                            {{ geofence.name }}
                        </mat-checkbox>
                    </div>
                }
            </div>
        </div>
    }
</div>
