import { Injectable } from '@angular/core'
import { ApiService } from '@env-lib/api/api.service'
import { SessionStorageService } from '@storage-lib/session-storage.service'
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc'
import { BehaviorSubject, Observable, ReplaySubject, from } from 'rxjs'
import { map, mapTo, tap } from 'rxjs/operators'
import { AuthStorageService } from './auth-storage.service'

export enum AuthenticationState {
    Authenticated = 'AUTHENTICATED',
    Unauthenticated = 'UNAUTHENTICATED',
}

@Injectable({
    providedIn: 'root',
})
export class AuthnService {
    private readonly SIGNIN_REDIRECT_PATH_STORAGE_KEY = 'signin_redirect_path'
    private readonly SIGNOUT_REDIRECT_PATH_STORAGE_KEY = 'signout_redirect_path'

    private authState$: BehaviorSubject<AuthenticationState> =
        new BehaviorSubject<AuthenticationState>(
            this.authClient.hasValidIdToken()
                ? AuthenticationState.Authenticated
                : AuthenticationState.Unauthenticated
        )

    private authToken$ = new BehaviorSubject<string | null>(null)

    constructor(
        private api: ApiService,
        private authClient: OAuthService,
        private storage: SessionStorageService,
        private authStorage: AuthStorageService
    ) {
        this.authClient.configure({
            clientId: this.api.auth.clientId,
            issuer: this.api.auth.issuer,
            loginUrl: this.api.auth.loginUrl,
            logoutUrl: this.api.auth.cognitoLogoutUrl,
            redirectUri: this.api.auth.redirectUrl,
            responseType: 'code',
            scope: 'openid profile email',
            tokenEndpoint: this.api.auth.tokenEndpoint,
            // disablePKCE if needed
            // @see https://jira.myvtg.com/browse/DIP-9344
            // conditional object copy because this setting
            // results in erratic behavior when the value isn't `true`
            ...(this.api.auth?.disablePKCE ? { disablePKCE: true } : {}),
        })
        this.authClient.setupAutomaticSilentRefresh()
        this.authClient.tokenValidationHandler = new NullValidationHandler()
        if (this.authState$.getValue() === AuthenticationState.Authenticated) {
            this.updateAccessToken(this.authClient.getIdToken())
        }
    }

    private updateAuthenticationState(nextState: AuthenticationState) {
        this.authState$.next(nextState)
    }

    private updateAccessToken(nextToken: string | null) {
        this.authToken$.next(nextToken)
    }

    stateChanges(): Observable<AuthenticationState> {
        return this.authState$.asObservable()
    }

    tokenChanges(): Observable<string | null> {
        return this.authToken$.asObservable()
    }

    refreshAccessToken(): Observable<void> {
        return from(
            this.authClient
                .refreshToken()
                .then((_) => this.authToken$.next(this.authClient.getIdToken()))
                .catch(() => this.initSignOutFlow())
        ).pipe(mapTo(void 0))
    }

    initSignInFlow(returnToPath?: string) {
        if (returnToPath) {
            this.storage.set(
                this.SIGNIN_REDIRECT_PATH_STORAGE_KEY,
                returnToPath
            )
        }
        this.authClient.initCodeFlow()
    }

    finishSignIn(): Observable<string | null> {
        return from(this.authClient.tryLoginCodeFlow()).pipe(
            map(() => this.popLoginRedirectPath()),
            tap(() => this.updateAccessToken(this.authClient.getIdToken())),
            tap(() =>
                this.updateAuthenticationState(
                    AuthenticationState.Authenticated
                )
            )
        )
    }

    initSignOutFlow(callbackPath?: string) {
        if (callbackPath) {
            this.authStorage.setItem(
                this.SIGNOUT_REDIRECT_PATH_STORAGE_KEY,
                callbackPath
            )
        }
        this.updateAccessToken(null)
        this.authState$.next(AuthenticationState.Unauthenticated)

        this.authClient.logOut()
    }

    popLogoutRedirectPath(): string | null {
        const path = this.authStorage.getItem(
            this.SIGNOUT_REDIRECT_PATH_STORAGE_KEY
        )
        this.authStorage.removeItem(this.SIGNOUT_REDIRECT_PATH_STORAGE_KEY)
        return path ? path : null
    }

    popLoginRedirectPath(): string | null {
        const path = this.authStorage.getItem(
            this.SIGNIN_REDIRECT_PATH_STORAGE_KEY
        )
        this.authStorage.removeItem(this.SIGNIN_REDIRECT_PATH_STORAGE_KEY)
        return path ? path : null
    }

    get authenticationState(): AuthenticationState {
        return this.authState$.value
    }
}
