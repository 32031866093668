export enum FastTrackArea {
    locations = 'locations',
    locationOverview = 'locationOverview',
    bookingRequest = 'bookingRequest',
    requestConfirmation = 'requestConfirmation',
    myBookings = 'myBookings',
}

export enum FastTrackAction {
    filterWagonTypeSelected = 'filterWagonTypeSelected',
    filterTimePeriodSelected = 'filterTimePeriodSelected',
    filterLocationSelected = 'filterLocationSelected',
    filterReset = 'filterReset',
    locationSelected = 'locationSelected',
    adminAreaClicked = 'adminAreaClicked',
    wagonCounterChanged = 'wagonCounterChanged',
    departureTimeChanged = 'departureTimeChanged',
    arrivalTimeChanged = 'arrivalTimeChanged',
    selectDateClicked = 'selectDateClicked',
    selectMonthsClicked = 'selectMonthsClicked',
    proceedToBookingButtonClicked = 'proceedToBookingButtonClicked',
    backButtonClicked = 'backButtonClicked',
    getAccessBannerClicked = 'getAccessBannerClicked',
    requestAccessDialogClicked = 'requestAccessDialogClicked',
    requestAccessDialogCanceled = 'requestAccessDialogCanceled',
    wagonSpecificDetailsClicked = 'wagonSpecificDetailsClicked',
    configurationDownloaded = 'configurationDownloaded',
    technicalDetailsDownloaded = 'technicalDetailsDownloaded',
    locationSpecificDetailsClicked = 'locationSpecificDetailsClicked',
    infrastructureManagerClicked = 'infrastructureManagerClicked',
    locationSpecificDetailsDownloaded = 'locationSpecificDetailsDownloaded',
    acceptAllClicked = 'acceptAllClicked',
    sendRequestButtonClicked = 'sendRequestButtonClicked',
    viewMyBookingsButtonClicked = 'viewMyBookingsButtonClicked',
    documentDownloaded = 'documentDownloaded',
    generalTermsDownloaded = 'generalTermsDownloaded',
    tabClicked = 'tabClicked',
}
