<footer class="md:h-18">
    <a
        *ngFor="let entry of entries"
        class="md:mx-8"
        (click)="router.navigate([entry.link])"
        clickTracking
        [trackingCategory]="TrackingCategory.navigation"
        [trackingArea]="NavigationArea.footer"
        [trackingAction]="NavigationAction.clicked"
        [trackingAttributes]="{ link: entry.translationKey }"
    >
        <translate>{{ entry.translationKey }}</translate>
    </a>
</footer>
