import { Component } from '@angular/core'
import { Router } from '@angular/router'
import {
    NavigationAction,
    NavigationArea,
} from '@tracking-lib/use-case-tracking-models/navigation.tracking.model'
import { TrackingCategory } from '@tracking-lib/tracking.model'

@Component({
    selector: 'app-footer',
    styleUrls: ['./footer.component.scss'],
    templateUrl: './footer.component.html',
})
export class FooterComponent {
    entries: FooterEntry[] = [
        {
            translationKey: 'Contact',
            link: '/legal/contact',
        },
        {
            translationKey: 'Imprint',
            link: '/legal/imprint',
        },
        {
            translationKey: 'Privacy',
            link: '/legal/privacy',
        },
        {
            translationKey: 'Terms of Use',
            link: '/legal/terms-and-conditions',
        },
    ]

    constructor(public router: Router) {}

    protected readonly NavigationArea = NavigationArea
    protected readonly NavigationAction = NavigationAction
    protected readonly TrackingCategory = TrackingCategory
}

interface FooterEntry {
    translationKey: string
    link: string
}
