export enum DataForwardingArea {
    editConfiguration = 'editConfiguration',
    emptyScreen = 'emptyScreen',
    newConfiguration = 'newConfiguration',
    overviewScreen = 'overviewScreen',
}

export enum DataForwardingAction {
    cancelClicked = 'cancelClicked',
    configurationDisabled = 'configurationDisabled',
    configurationEditClicked = 'configurationEditClicked',
    configurationEnabled = 'configurationEnabled',
    dataSourceAllWagonsChecked = 'dataSourceAllWagonsChecked',
    dataSourceAllWagonsUnchecked = 'dataSourceAllWagonsUnchecked',
    dataSourceCustomGroupClicked = 'dataSourceCustomGroupClicked',
    dataSourceCustomGroupChecked = 'dataSourceCustomGroupChecked',
    dataSourceCustomGroupUnchecked = 'dataSourceCustomGroupUnchecked',
    deleteCanceled = 'deleteCanceled',
    deleteClicked = 'deleteClicked',
    deleteConfirmed = 'deleteConfirmed',
    geofenceEventTestRequestClicked = 'geofenceEventTestRequestClicked',
    lastPositionTestRequestClicked = 'lastPositionTestRequestClicked',
    newConfigurationClicked = 'newConfigurationClicked',
    notificationGeofenceEventChecked = 'notificationGeofenceEventChecked',
    notificationGeofenceEventUnchecked = 'notificationGeofenceEventUnchecked',
    notificationLastPositionChecked = 'notificationLastPositionChecked',
    notificationLastPositionUnchecked = 'notificationLastPositionUnchecked',
    saveClicked = 'saveClicked',
}
