import { CommonModule } from '@angular/common'
import {
    Component,
    forwardRef,
    HostBinding,
    Input,
    OnDestroy,
} from '@angular/core'
import {
    AbstractControl,
    FormBuilder,
    FormGroupDirective,
    FormsModule,
    NG_VALUE_ACCESSOR,
    NgForm,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { Wagon } from '@group-management-lib/group-management.model'
import { GroupManagementService } from '@group-management-lib/group-management.service'
import { selectAvailableResources } from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { formatUicClass } from '@group-management-lib/util/formatUicClass'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { Store } from '@ngrx/store'
import { BaseControlValueAccessor } from '@shared-ui-lib/base-control-value-accessor/base-control-value-accessor.component'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { InlineMessageComponent } from '@shared-ui-lib/inline-message/inline-message.component'
import { InlineMessageType } from '@shared-ui-lib/inline-message/inline-message.model'
import { Suggestion } from '@shared-util-lib/models/suggestion.interface'
import { UicWagonNumberPipeModule } from '@shared-util-lib/pipes/uic-wagon-number/uic-wagon-number-pipe.module'
import {
    TrackingBaseData,
    TrackingCategory,
} from '@tracking-lib/tracking.model'
import { TrackingService } from '@tracking-lib/tracking.service'
import {
    GroupManagementAction,
    GroupManagementArea,
} from '@tracking-lib/use-case-tracking-models/group-management.tracking.model'
import { isTruthy } from '@util-lib/isTruthy'
import { wagonNumberValidationRegex } from '@util-lib/validation/wagonNumberValidationRegex'
import {
    BehaviorSubject,
    combineLatest,
    filter,
    map,
    startWith,
    Subject,
    Subscription,
    withLatestFrom,
} from 'rxjs'

class ShowImmediatelyErrorStateMatcher extends ShowOnDirtyErrorStateMatcher {
    isErrorState(
        control: AbstractControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        return !!control?.errors
    }
}

@Component({
    selector: 'app-group-management-wagon-number-input',
    templateUrl: './group-management-wagon-number-input.component.html',
    styleUrls: ['./group-management-wagon-number-input.component.scss'],
    standalone: true,
    imports: [
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        FormsModule,
        InlineMessageComponent,
        MatAutocompleteModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule,
        TranslationModule,
        UicWagonNumberPipeModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(
                () => GroupManagementWagonNumberInputComponent
            ),
            multi: true,
        },
    ],
})
export class GroupManagementWagonNumberInputComponent
    extends BaseControlValueAccessor<Wagon[]>
    implements OnDestroy
{
    @HostBinding('class.disabled') classDisabled: boolean = false

    subscriptions = new Subscription()

    InlineMessageType = InlineMessageType
    GroupManagementAction = GroupManagementAction

    trackingBaseData?: TrackingBaseData

    @Input()
    set setTrackingArea(area: GroupManagementArea) {
        this.trackingBaseData = {
            category: TrackingCategory.groupManagement,
            area,
        }
    }

    constructor(
        private groupManagementService: GroupManagementService,
        private store: Store<GroupManagementState>,
        private formBuilder: FormBuilder,
        private trackingService: TrackingService
    ) {
        super()

        this.subscriptions.add(
            this.alreadySelectedWagonNumbers$.subscribe(
                (alreadySelectedWagons) => {
                    this.selectedWagons$.next(alreadySelectedWagons)
                }
            )
        )

        this.subscriptions.add(
            this.selectedWagons$.subscribe((selectedWagons) => {
                this.selectedWagons = selectedWagons

                if (!this.disabled) {
                    this.value = this.selectedWagons
                    this.onChanged(selectedWagons)
                    this.onTouched()
                }
            })
        )

        this.subscriptions.add(
            this.triggerSelectWagon$
                .pipe(withLatestFrom(this.availableWagonsWithoutSelected$))
                .subscribe(([selectedWagonId, availableWagons]) => {
                    const selectedWagon = availableWagons?.find(
                        (availableWagon) =>
                            availableWagon.wagonId === selectedWagonId
                    )

                    if (selectedWagon) {
                        this.selectedWagons$.next([
                            ...this.selectedWagons$.value,
                            selectedWagon,
                        ])
                    }
                })
        )

        // split multiwagon input into selected or invalid wagons
        this.subscriptions.add(
            this.multiWagonInput$
                .pipe(
                    withLatestFrom(this.availableWagons$, this.selectedWagons$)
                )
                .subscribe(
                    ([multiWagonInput, availableWagons, selectedWagons]) => {
                        const inputList = multiWagonInput.split(
                            this.separatorRegex
                        )

                        const newWagons = new Set<Wagon>()

                        inputList.forEach((listElement) => {
                            // check if valid wagon number, else add to invalid wagons
                            if (
                                wagonNumberValidationRegex.test(
                                    listElement.trim()
                                )
                            ) {
                                // find matching available wagon for input
                                const availableWagon = availableWagons?.find(
                                    (wagon) =>
                                        wagon.wagonNumber ===
                                        this.sanitize(listElement)
                                )

                                // if available and not already selected,
                                // add to selected wagons
                                if (
                                    availableWagon &&
                                    !selectedWagons.find(
                                        (selectedWagon) =>
                                            selectedWagon.wagonId ===
                                            availableWagon.wagonId
                                    )
                                ) {
                                    newWagons.add(availableWagon)
                                } else if (!availableWagon) {
                                    this.addToInvalidWagonsIfNotIncluded(
                                        listElement
                                    )
                                }
                            } else if (listElement) {
                                this.addToInvalidWagonsIfNotIncluded(
                                    listElement
                                )
                            }
                        })

                        this.selectedWagons$.next([
                            ...selectedWagons,
                            ...Array.from(newWagons),
                        ])
                    }
                )
        )

        // after invalid wagon number was edited,
        // we validate it and update the selected as well as the invalid list
        this.subscriptions.add(
            this.triggerEditWagonNumber$
                .pipe(
                    withLatestFrom(
                        this.availableWagonsWithoutSelected$,
                        this.selectedWagons$,
                        this.invalidWagons$
                    )
                )
                .subscribe(
                    ([
                        editedWagonIndex,
                        availableWagons,
                        selectedWagons,
                        invalidWagons,
                    ]) => {
                        const updatedWagonNumber = this.sanitize(
                            this.wagonNumberEditControl.value ?? ''
                        )
                        const matchedWagon = availableWagons?.find(
                            (availableWagon) =>
                                availableWagon.wagonNumber ===
                                updatedWagonNumber
                        )

                        if (matchedWagon) {
                            // if already in list, do not add, but remove from invalid list
                            if (
                                selectedWagons.findIndex(
                                    (selectedWagon) =>
                                        selectedWagon.wagonId ===
                                        matchedWagon.wagonId
                                ) === -1
                            ) {
                                this.selectedWagons$.next([
                                    ...selectedWagons,
                                    matchedWagon,
                                ])
                            }

                            this.invalidWagons$.next(
                                invalidWagons.filter(
                                    (invalidWagon, index) =>
                                        index !== editedWagonIndex
                                )
                            )
                        } else {
                            this.invalidWagons$.next(
                                invalidWagons.map((invalidWagon, index) =>
                                    index === editedWagonIndex
                                        ? {
                                              ...invalidWagon,
                                              wagonNumber: updatedWagonNumber,
                                          }
                                        : invalidWagon
                                )
                            )
                        }

                        this.wagonNumberInEditMode = undefined
                    }
                )
        )
    }

    private addToInvalidWagonsIfNotIncluded(wagonInput: string) {
        const currentInvalidWagons = this.invalidWagons$.value

        if (
            currentInvalidWagons.find(
                (wagon) => wagon.wagonNumber === wagonInput
            )
        ) {
            return
        }

        this.invalidWagons$.next([
            ...currentInvalidWagons,
            {
                wagonNumber: wagonInput,
                uicClass: '',
                wagonId: '',
            },
        ])
    }

    //-------------------------
    // general data
    //-------------------------
    isAdmin$ = this.groupManagementService.isAdmin$

    availableResources$ = this.store.select(selectAvailableResources)

    isDefaultGroup$ = this.availableResources$.pipe(
        map((response) => response?.defaultGroup),
        startWith(true)
    )

    isSynchronizedGroup$ = this.availableResources$.pipe(
        map((response) => response?.isSynchronized === true)
    )

    //-------------------------
    // wagon
    //-------------------------

    alreadySelectedWagonNumbers$ = this.availableResources$.pipe(
        map((response) =>
            response?.resources.wagons.filter((wagon) => wagon.selected)
        ),
        filter(isTruthy)
    )

    wagonsChangeable$ = this.availableResources$.pipe(
        map((response) => response?.resources.wagonsChangeable)
    )

    triggerSelectWagon$ = new Subject<string>()
    selectedWagons: Wagon[] = []
    selectedWagons$ = new BehaviorSubject<Wagon[]>([])

    availableWagons$ = this.availableResources$.pipe(
        map((response) => response?.resources.wagons)
    )
    availableWagonsWithoutSelected$ = combineLatest([
        this.selectedWagons$.pipe(
            map((selectedWagons) =>
                selectedWagons.map((selectedWagon) => selectedWagon.wagonId)
            )
        ),
        this.availableWagons$,
    ]).pipe(
        map(([selectedWagonIds, availableWagons]) =>
            availableWagons?.filter(
                (availableWagon) =>
                    !selectedWagonIds.includes(availableWagon.wagonId)
            )
        )
    )

    removeWagonFromSelectedList(wagonId: string) {
        this.trackAction(GroupManagementAction.itemDeleted)

        this.selectedWagons$.next(
            this.selectedWagons.filter(
                (selectedWagon) => selectedWagon.wagonId !== wagonId
            )
        )
    }

    //--------------------------------
    // edit invalid wagonnnumber
    //--------------------------------
    invalidWagons$ = new BehaviorSubject<Wagon[]>([])

    wagonNumberInEditMode?: string

    setWagonNumberToEditMode(wagonNumber: string) {
        this.trackAction(GroupManagementAction.invalidItemEditClicked)
        this.wagonNumberEditControl.setValue(wagonNumber)
        this.wagonNumberInEditMode = wagonNumber
    }

    clearWagonsToEdit() {
        this.trackAction(GroupManagementAction.invalidItemCanceled)
        this.wagonNumberInEditMode = undefined
    }

    removeItemFromInvalidList(wagonNumber: string) {
        this.trackAction(GroupManagementAction.invalidItemDeleted)
        this.invalidWagons$.next(
            this.invalidWagons$.value.filter(
                (invalidWagon) => invalidWagon.wagonNumber !== wagonNumber
            )
        )
    }

    removeAllItemsFromInvalidList() {
        this.trackAction(GroupManagementAction.invalidItemsDeleted)
        this.invalidWagons$.next([])
    }

    triggerEditWagonNumber$ = new Subject<number>()

    onKeyDown(event: KeyboardEvent, index: number) {
        if (event.key === 'Enter') {
            this.updateInvalidWagonNumber(index)
        }
    }

    updateInvalidWagonNumber(index: number) {
        this.trackAction(GroupManagementAction.invalidItemEdited)
        this.triggerEditWagonNumber$.next(index)
    }

    //-------------------------
    // form
    //-------------------------

    wagonNumberInputControl = this.formBuilder.control<
        Suggestion<string> | string
    >('', [this.maxLengthValidator(), this.patternValidator()])

    wagonNumberEditControl = this.formBuilder.control<string>('')

    //-------------------------
    // controlvalueaccessor
    //-------------------------

    public setDisabledState(disabled: boolean): void {
        if (disabled) {
            this.wagonNumberEditControl.disable()
            this.wagonNumberInputControl.disable()
            this.classDisabled = true
        } else {
            this.wagonNumberEditControl.enable()
            this.wagonNumberInputControl.enable()
            this.classDisabled = false
        }
    }

    //-------------------------
    // input data
    //-------------------------

    separatorRegex: RegExp = /\r\n|\r|\n|,|;/

    searchTerm$ = this.wagonNumberInputControl.valueChanges.pipe(
        map((input) => this.suggestionToString(input))
    )

    sanitizedSearchTerm$ = this.searchTerm$.pipe(
        map((searchTerm) => this.sanitize(searchTerm))
    )

    patternValidationError: ValidationErrors | null = null

    suggestionToString(input: string | Suggestion<string> | null): string {
        return typeof input === 'string' ? input : input?.value || ''
    }

    sanitize(value: string) {
        let sanitizedValue = value
        if (sanitizedValue.includes('"')) {
            sanitizedValue = sanitizedValue.replace('"', '')
        }
        return sanitizedValue.replace(/[ -]/g, '')
    }

    getSanitizedControlValue(): string {
        return this.sanitize(
            this.suggestionToString(this.wagonNumberInputControl?.value)
        )
    }

    singleWagonInput$ = this.sanitizedSearchTerm$.pipe(
        map((sanitizedSearchTerm) =>
            this.isSingleInput(sanitizedSearchTerm) ? sanitizedSearchTerm : ''
        )
    )

    multiWagonInput$ = new Subject<string>()

    isSingleInput(input: string): boolean {
        return !this.separatorRegex.test(input)
    }

    validSingleWagonInput$ = this.singleWagonInput$.pipe(
        map((singleWagonInput) => {
            if (this.validateMinLength(singleWagonInput)) return false

            if (this.validateMaxLength(singleWagonInput)) return false

            if (this.validatePattern(singleWagonInput)) return false

            return true
        })
    )

    // show messages directly before input field loses focus the first time
    matcher = new ShowImmediatelyErrorStateMatcher()

    autocompleteSuggestions$ = combineLatest([
        this.availableWagonsWithoutSelected$,
        this.selectedWagons$,
        this.validSingleWagonInput$,
        this.singleWagonInput$,
    ]).pipe(
        map(([availableWagons, selectedWagons, isValid, input]) => {
            if (!isValid) return []

            return availableWagons
                ?.filter(
                    (availableWagon) =>
                        !selectedWagons
                            .map((selectedWagon) => selectedWagon.wagonId)
                            .includes(availableWagon.wagonId)
                )
                .filter((wagons) =>
                    input.length === 0
                        ? true
                        : wagons.wagonNumber.includes(input)
                )
                .map(
                    (filteredWagons) =>
                        ({
                            label: filteredWagons.wagonNumber,
                            value: filteredWagons.wagonId,
                        }) as Suggestion<string>
                )
                .sort((first, second) =>
                    first.label.localeCompare(second.label)
                )
        }),
        filter(isTruthy)
    )

    clearWagonInput() {
        this.wagonNumberInputControl?.setValue(null)
    }

    //-------------------------
    // Validators
    //-------------------------

    validateMinLength(input: string): ValidationErrors | null {
        if (input.length < this.groupManagementService.minInputLength) {
            return { minLength: true }
        } else {
            return null
        }
    }

    validateMaxLength(input: string): ValidationErrors | null {
        if (input.length > this.groupManagementService.maxInputLength) {
            return { maxLength: true }
        } else {
            return null
        }
    }

    maxLengthValidator(): ValidatorFn {
        return (): ValidationErrors | null =>
            this.validateMaxLength(this.getSanitizedControlValue())
    }

    validatePattern(input: string): ValidationErrors | null {
        if (input.length === 0) return null

        if (wagonNumberValidationRegex.test(input)) {
            return null
        } else {
            return {
                pattern: true,
            }
        }
    }

    patternValidator(): ValidatorFn {
        return (): ValidationErrors | null =>
            this.validatePattern(this.getSanitizedControlValue())
    }

    //-------------------------
    // functions
    //-------------------------

    trackAction(action: GroupManagementAction) {
        if (this.trackingBaseData) {
            this.trackingService.track({
                category: this.trackingBaseData.category,
                area: this.trackingBaseData.area,
                action,
            })
        }
    }

    selectWagonFromSuggestion(optionValue: Suggestion<string>) {
        this.trackAction(GroupManagementAction.wagonNumberSelected)
        this.triggerSelectWagon$.next(optionValue.value)
        this.clearWagonInput()
    }

    onPaste(event: ClipboardEvent) {
        const clipBoardData = event.clipboardData?.getData('text')

        this.trackAction(GroupManagementAction.wagonNumberPasted)

        if (!clipBoardData) return

        if (
            clipBoardData &&
            !this.isSingleInput(this.sanitize(clipBoardData))
        ) {
            this.multiWagonInput$.next(clipBoardData)

            setTimeout(() => {
                this.clearWagonInput()
            }, 0)
        }
    }

    formatUic(uicClass: string) {
        return formatUicClass(uicClass)
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
