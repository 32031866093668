import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { Component, OnInit } from '@angular/core'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { AuthzService } from '@auth-util-lib/authz.service'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { TrackingCategory } from '@tracking-lib/tracking.model'
import {
    ServiceDeskAction,
    ServiceDeskArea,
} from '@tracking-lib/use-case-tracking-models/service-desk.tracking.model'

@Component({
    selector: 'app-contact',
    styleUrls: ['./contact.component.scss'],
    templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {
    constructor(
        public breakpointObserver: BreakpointObserver,
        private authzService: AuthzService,
        private authnService: AuthnService
    ) {}

    showServiceDeskStatus$ = combineLatest([
        this.authnService.stateChanges(),
        this.authzService.authorizedForTraigo$,
    ]).pipe(
        map(
            ([authState, authorizedForTraigo]) =>
                authState === AuthenticationState.Authenticated &&
                authorizedForTraigo
        )
    )

    isSmall = false

    ngOnInit() {
        this.breakpointObserver
            .observe(['(min-width: 480px)'])
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.isSmall = true
                } else {
                    this.isSmall = false
                }
            })
    }

    protected readonly TrackingCategory = TrackingCategory
    protected readonly ServiceDeskArea = ServiceDeskArea
    protected readonly ServiceDeskAction = ServiceDeskAction
}
