import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { GroupManagementAccessPeriodSelectionComponent } from '@group-management-lib/components/group-management-access-period-selection/group-management-access-period-selection.component'
import { GroupManagementGeofenceSelectionComponent } from '@group-management-lib/components/group-management-geofence-selection/group-management-geofence-selection.component'
import { GroupManagementQuitDialogModule } from '@group-management-lib/components/group-management-quit-dialog/group-management-quit-dialog.module'
import { GroupManagementUserSelectionComponent } from '@group-management-lib/components/group-management-user-selection/group-management-user-selection.component'
import { GroupManagementWagonNumberInputComponent } from '@group-management-lib/components/group-management-wagon-number-input/group-management-wagon-number-input.component'
import {
    Geofence,
    ParsedAccessPeriod,
    User,
    Wagon,
    WagonId,
} from '@group-management-lib/group-management.model'
import {
    createGroup,
    loadMyAvailableResources,
} from '@group-management-lib/redux/group-management.actions'
import {
    selectAvailableResources,
    selectAvailableResourcesLoading,
    selectCreateGroupError,
    selectCreateGroupLoading,
    selectUserSelectionForNewGroup,
} from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { resetSteps } from '@group-management-lib/util/stepperControls'
import { validateGroupName } from '@group-management-lib/validators/validateGroupName'
import { LoadingModule } from '@loading-lib/loading.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { Store, select } from '@ngrx/store'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { OverlayService } from '@shared-ui-lib/overlay/overlay-service'
import { SystemResponseModule } from '@shared-ui-lib/system-response/system-response.module'
import { formGroupControlsValidator } from '@shared-util-lib/validators/formGroupControls.validator'
import { TrackingCategory } from '@tracking-lib/tracking.model'
import {
    GroupManagementAction,
    GroupManagementArea,
} from '@tracking-lib/use-case-tracking-models/group-management.tracking.model'
import { isTruthy } from '@util-lib/isTruthy'
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription,
    combineLatest,
    filter,
    withLatestFrom,
} from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
    selector: 'app-group-management-creator',
    templateUrl: './group-management-creator.component.html',
    styleUrls: ['./group-management-creator.component.scss'],
    standalone: true,
    imports: [
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        FormsModule,
        GroupManagementAccessPeriodSelectionComponent,
        GroupManagementQuitDialogModule,
        GroupManagementUserSelectionComponent,
        GroupManagementGeofenceSelectionComponent,
        GroupManagementWagonNumberInputComponent,
        LoadingModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatStepperModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule,
        SystemResponseModule,
        TranslationModule,
    ],
})
export class GroupManagementCreatorComponent implements OnDestroy, OnInit {
    // delegate shared functions
    protected readonly resetSteps = resetSteps

    groupNameControl = this.formBuilder.control<string>('', [
        Validators.required,
        validateGroupName(),
    ])
    accessPeriodControl = this.formBuilder.control<ParsedAccessPeriod>({
        beginOn: null,
        endOn: null,
    })
    userIdsControl = this.formBuilder.control<string[]>([])
    geofenceIdsControl = this.formBuilder.control<string[]>([])
    wagonControl = this.formBuilder.control<Wagon[]>([])
    formGroup = this.formBuilder.group(
        {
            groupName: this.groupNameControl,
            accessPeriod: this.accessPeriodControl,
            userIds: this.userIdsControl,
            geofenceIds: this.geofenceIdsControl,
            wagons: this.wagonControl,
        },
        {
            validators: formGroupControlsValidator([
                this.groupNameControl,
                this.accessPeriodControl,
                this.userIdsControl,
                this.geofenceIdsControl,
                this.wagonControl,
            ]),
        }
    )

    readonly colleagues = this.store.pipe(
        select(selectUserSelectionForNewGroup)
    )

    private readonly saveTriggered$ = new Subject()
    createGroupError$ = this.store.pipe(select(selectCreateGroupError))
    createGroupLoading$ = this.store.pipe(select(selectCreateGroupLoading))
    loading$ = this.store.pipe(select(selectAvailableResourcesLoading))
    savingErrorOccurred$ = new BehaviorSubject<boolean>(false)

    availableResources$ = this.store.select(selectAvailableResources)

    users$: Observable<User[]> = this.availableResources$.pipe(
        map((resources) => resources?.users),
        filter(isTruthy)
    )
    geofences$: Observable<Geofence[]> = this.availableResources$.pipe(
        map((resources) => resources?.resources?.geofences),
        filter(isTruthy)
    )
    isAccessPeriodVisible$ = this.availableResources$.pipe(
        map((resources) => resources?.periodVisible)
    )
    usersVisible$ = this.availableResources$.pipe(
        map((resources) => resources?.usersVisible),
        filter(isTruthy)
    )

    private subscriptions = new Subscription()

    TrackingCategory = TrackingCategory
    GroupManagementArea = GroupManagementArea
    GroupManagementAction = GroupManagementAction

    numberOfSteps = 3
    readonly stepIndex = new BehaviorSubject(0)
    readonly hasNextStep$ = this.stepIndex.pipe(
        map((index) => index < this.numberOfSteps - 1)
    )
    readonly hasPreviousStep$ = this.stepIndex.pipe(map((index) => index > 0))

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<GroupManagementState>,
        private overlayService: OverlayService
    ) {
        this.subscriptions.add(
            combineLatest([this.createGroupError$]).subscribe(
                ([createGroupError]) => {
                    this.savingErrorOccurred$.next(createGroupError !== null)
                }
            )
        )

        this.subscriptions.add(
            this.createGroupLoading$.subscribe((createGroupLoading) =>
                createGroupLoading
                    ? this.formGroup.disable()
                    : this.formGroup.enable()
            )
        )

        this.subscriptions.add(
            this.saveTriggered$
                .pipe(withLatestFrom(this.availableResources$))
                .subscribe(([_, availableResources]) => {
                    const formValues = this.formGroup.value

                    if (
                        formValues.groupName &&
                        formValues.wagons &&
                        formValues.userIds &&
                        formValues.accessPeriod &&
                        formValues.geofenceIds
                    ) {
                        const groupName = formValues.groupName
                        const selectedUserIds = formValues.userIds
                        const selectedGeofenceIds = formValues.geofenceIds
                        const selectedAccessPeriod = formValues.accessPeriod
                        const selectedWagons = formValues.wagons?.map(
                            (wagon) => ({ id: wagon.wagonId }) as WagonId
                        )

                        this.store.dispatch(
                            createGroup({
                                groupName,
                                areWagonsChangeable:
                                    availableResources?.resources
                                        .wagonsChangeable ?? false,
                                selectedWagons,
                                areUsersChangeable:
                                    availableResources?.users.some(
                                        (user) => user
                                    ) ?? false,
                                selectedUserIds,
                                isAccessPeriodChangeable:
                                    availableResources?.periodChangeable ??
                                    false,
                                selectedAccessPeriod,
                                areGeofencesChangeable:
                                    availableResources?.resources
                                        .geofencesChangeable ?? false,
                                selectedGeofenceIds,
                            })
                        )
                    }
                })
        )
    }

    ngOnInit() {
        this.store.dispatch(loadMyAvailableResources({}))
    }

    disableCreateButton() {
        return this.formGroup.errors !== null
    }

    requestClose() {
        // if nothing has changed close without prompt
        if (
            (this.groupNameControl.value === '' &&
                this.wagonControl.value?.length === 0) ||
            this.savingErrorOccurred$.value
        ) {
            this.overlayService.closeLayer(true)
        } else {
            this.overlayService.closeLayer()
        }
    }

    handleFormSubmit() {
        this.saveTriggered$.next(true)
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
