import { ControlValueAccessor } from '@angular/forms'

export class BaseControlValueAccessor<T> implements ControlValueAccessor {
    public value!: T

    public touched = false

    public disabled = false

    public onChanged = (value: T) => {}

    public onTouched = () => {}

    public writeValue(value: T) {
        this.value = value
    }

    public registerOnChange(onChange: (value: T) => void) {
        this.onChanged = onChange
    }

    public registerOnTouched(onTouched: () => void) {
        this.onTouched = onTouched
    }

    public markAsTouched() {
        if (!this.touched) {
            this.onTouched()
            this.touched = true
        }
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled
    }
}
