import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { Action } from '@auth-util-lib/auth.model'
import { AuthzService } from '@auth-util-lib/authz.service'
import { SystemResponseModule } from '@shared-ui-lib/system-response/system-response.module'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-not-allowed',
    templateUrl: './not-allowed.component.html',
    styleUrls: ['./not-allowed.component.scss'],
    standalone: true,
    imports: [CommonModule, SystemResponseModule],
})
export class NotAllowedComponent {
    isAdmin$: Observable<boolean> = this.auth.hasAction$(
        Action.ShowUserManagement
    )

    constructor(
        private auth: AuthzService,
        private router: Router
    ) {}

    navigateTo(url: string) {
        this.router.navigate([url])
    }
}
