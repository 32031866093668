<header>
    <h4>traigo Platform ‑ Privacy Policy</h4>
    <p class="subtitle">(Version 1.8 as of 12.03.2025)</p>
    <p>
        The traigo Platform (“<b>Platform</b>”) enables individual users
        (“<b>User</b>” or “<b>you</b>”) to make use of different tools of The
        Platform in the name and on the behalf of commercial customers of VTG
        Rail Europe GmbH, Nagelsweg 34, 20097 Hamburg, phone: +49 40 2354-0,
        email: info&#64;vtg.com (“<b>VTG</b>”, “<b>we</b>” or “<b>us</b>”)
        provided that the commercial customer has previously entered into a
        platform agreement with VTG (“<b>Platform Agreement</b>”). In connection
        with the use of the Platform certain personal information in the sense
        of the EU Regulation 679/2016 (“<b>GDPR</b>”) are collected.
    </p>
    <p>
        This Privacy Policy applies to the processing of personal information by
        VTG in connection with the operation of the Platform and the landing
        page, as applicable. In the following paragraphs we inform you in detail
        about the handling of personal information. Personal information is
        collected and processed solely in compliance with the statutory
        provisions of the GDPR.
    </p>
    <p>
        Operator of the Platform and the landing page and controller in the
        sense of the GDPR is VTG.
    </p>
    <p>
        In the event of questions regarding the processing of personal
        information by VTG in connection with the Platform the User may use the
        contact details in Section 5 of this Privacy Policy.
    </p>
</header>
<section>
    <ol>
        <li class="privacy__li__headline">
            <h4>Collection, processing and use of personal information</h4>
            <ol>
                <li>
                    <p class="privacy__li__sub_headline">Technical Data</p>
                    <p>
                        Error-free operation and provision of the Platform
                        requires capture of certain technical information, which
                        is automatically transferred from the computer or device
                        of the User. In this context we store in particular
                        access data like the IP Address, browser type and
                        version, name of the operating system, name of the
                        internet access provider of the User, the referring page
                        from which the Platform is visited or the name of the
                        requested files as well as data and time of the access.
                        This data is solely used for enabling the access and use
                        of the Platform and for the assessment and improvement
                        of our offer without allowing a direct conclusion on the
                        person of the User. This data is not matched to other
                        sets of data provided by you. The processing of this
                        data is necessary for fulfilling our contractual
                        obligations towards the User and his employer in
                        accordance with Art. 6 para. 1 lit. b) GDPR as well as
                        for the protection of our justified interest in the
                        provision of our services and improvement of the
                        Platform in accordance with Art. 6 para. 1 lit. f) GDPR.
                        This data is deleted by us as soon as it is not required
                        for these purposes anymore, which is normally the case
                        after the User has left the Platform. This does also
                        apply to other visitors of the landing page of the
                        Platform which is freely accessible on the internet.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">Account Data</p>
                    <p>
                        The use of the Platform is only possible via a
                        personalized account of the User (“<b>Account</b>”)
                        which is set up by VTG after finalizing the registration
                        process. The Account can be requested via a form sheet
                        on the landing page of the Platform where the name,
                        email address, company name and potentially VTG customer
                        number and phone number must be indicated. This data
                        will only be used for the assessment whether access to
                        the Platform will be granted (Art. 6 para. 1 lit. b)
                        GDPR). If VTG rejects the request, the data will be
                        deleted immediately. Otherwise, the data will become
                        part of the Account.
                    </p>
                    <p>
                        After setting up the Account personal information of the
                        User will be stored. This data comprises the name,
                        business email address and phone number of the User and
                        information concerning his or her employer as well as an
                        individual user name and password.
                    </p>
                    <p>
                        It is also possible to add further personal information
                        to an Account, e.g. photos. The User provides such data
                        voluntarily and in his or her own discretion and such
                        data is not required for the use of the Platform.
                    </p>
                    <p>
                        It may be necessary for our support or commercial team
                        members to access the Account of a User for support
                        purposes and validate the visible information in the
                        Account.
                    </p>
                    <p>
                        This data is solely processed for the purpose of
                        providing the Account and enabling the User to use the
                        Platform and is thus necessary for the execution of the
                        Platform Agreement according to Art. 6 para. 1 lit. b)
                        GDPR or for protecting our justified interests in
                        providing the Platform to the Users in accordance with
                        Art. 6 para. 1 lit. f) GDPR.
                    </p>
                    <p>
                        The Account data of the User is deleted as soon as the
                        Account of the User is terminated or the User corrects
                        or changes the relevant data.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">Contract Data</p>
                    <p>
                        For the use of the Platform it is required to log in
                        with a personal Account. This requires provision of the
                        user name and the personal password.
                    </p>
                    <p>
                        The individual activities if the User on the Platform
                        (in particular purchase of certain tools or features
                        against compensation) will be matched to the specific
                        Account.
                    </p>
                    <p>
                        We use this data solely for the fulfillment of the
                        Platform Agreement and/or the individual tool agreement
                        according to Art. 6 para. 1 lit. b) GDPR.
                    </p>
                    <p>
                        This contract-related data will be stored by VTG even
                        after the respective activity has ended. This
                        information only be deleted after the termination of the
                        respective Account or the expiration of statutory
                        retention periods (whichever is later). This data may
                        potentially be used in anonymized or aggregated format
                        in order to better understand the use of the Platform
                        and to improve the Platform.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">Payment Data</p>
                    <p>
                        To the extent additional fee-bearing tools have been
                        purchased via the Account of the User it is require to
                        provide payment data information (e.g. credit card or
                        bank account number, credit institute, etc.). We will
                        use this data solely for the purpose of executing the
                        payment transfer and delete the data as soon as the
                        statutory retention periods have expired.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">
                        Location of Data Processing
                    </p>
                    <p>
                        The above data will be stored solely on servers in
                        Germany.
                    </p>
                </li>
            </ol>
        </li>
        <li class="privacy__li__headline">
            <h4>Marketing</h4>
            <p>
                From time to time we may use the email address obtained from the
                User in the course of contract performance for informing the
                User about news regarding us or our products.
            </p>
            <p>
                The User can at any time notify as by email that he does not
                want to receive any further communication. This will not affect
                such communication which is relevant for the contract
                performance. We will notify the User about this opportunity in
                each marketing email.
            </p>
        </li>
        <li class="privacy__li__headline">
            <h4>User Surveys</h4>
            <p>
                We may at any time in the future reach out to you and ask if you
                would like to participate in a survey relating to your
                experience with the Platform or similar topics. Participating in
                such survey is always voluntary. Some of the information
                requested during the survey are voluntary, others may be
                compulsory and participating in the survey is not possible
                without providing this information. Compulsory fields are
                highlighted respectively.
            </p>
            <p>
                We use the information provided by you only for the purpose of
                analyzing the use of the Platform by Users and improving the
                Platform as well as for the purpose of contact you and
                potentially ask further questions or provide helpful information
                regarding the use pf the Platform to you. We will not share the
                information provided by you in the context of the survey with
                third parties but only use this information for internal
                purposes at VTG.
            </p>
            <p>
                Upon submitting the survey you will be asked to provide consent
                for the use of your provided personal data for the above
                purposes. Provision of consent is voluntary, but participating
                in the survey is not possible without giving consent. In case
                you provide personal data relating to other individuals than
                yourself, you are responsible to ensure that this individual has
                consented to the use of his or her data.
            </p>
            <p>
                You may withdraw your consent at any time with effect for the
                future by email to datenschutz&#64;vtg.com. As soon as we
                receive your withdrawal notice, we will delete or anonymize the
                personal data we have received from you in connection with the
                survey and we will stop contacting you. This does not apply to
                messages sent to you in connection with your regular use of your
                User account.
            </p>
        </li>
        <li class="privacy__li__headline">
            <h4>Use of Cookies</h4>
            <p>
                For the provision of certain functions of the Platform we use so
                called cookies, being small text files which are stored on the
                device of the User when accessing the Platform and provide us
                with information about the previous settings and use of the
                Platform. These cookies are necessary for using the Platform and
                will be deleted after the end of the browser session, i.e. after
                closing the browser (Session Cookies). Other cookies remain on
                the device of the User and enable us to identify the browser of
                the User at the next visit (Persistent Cookies). The use of
                these cookies is necessary for the provision of the Platform and
                thus necessary for the execution of the Platform Agreement as
                well as for the protection of our justified interest in
                providing the Platform and as such justified under Art. 6 para.
                1 lit. b) and f) GDPR. This also comprises cookies of Microsoft
                Azure upon logging in into the Account in order to enable the
                access protected use of the Platform. These cookies remain
                stored for a period of up to one hour on the device after
                logging in to the Platform.
            </p>
            <p>
                We also use a cookie which stores the privacy settings of the
                User and us about the privacy settings of the User and for which
                cookies he has given his consent when visiting the Platform in
                the future. This cookie is necessary for complying with our
                legal obligations regarding the documentation of consent
                declarations (Art. 6 para. 1 lit. c) GDPR).
            </p>
            <p>
                Further, we use cookies provided by Klokan Technologies GmbH
                (maptiler) and which are necessary to provide our mapping and
                tracking services for wagons and are thus necessary for the
                fulfillment of our contractual obligations (Art. 6 para. 1 lit.
                b) GDPR).
            </p>
            <p>
                Mixpanel sets cookies to identify unique Users that visit the
                Platform.
            </p>
            <p>
                The User can at any time delete all cookies stored on his
                device.
            </p>
            <p>
                The User can also change his browser settings so that he will be
                informed before cookies are being placed and decide in each
                single case about accepting the cookie or reject the cookie in
                specific cases or in general. In case of rejection of cookies
                the functionalities of the Platform may be restricted.
            </p>
        </li>
        <li class="privacy__li__headline">
            <h4>Transfer of Personal Data</h4>
            <p>
                The personal information of Users will only be forwarded to
                technical service providers which support us in providing the
                Platform and process data only under the instruction of VTG
                (e.g. hosting provider). Currently, the Platform is hosted by
                Amazon Web Services on servers located in Germany. To the extent
                the User has purchased fee-bearing additional tool or feature we
                may share the payment data for executing the payment transfer
                with a credit institute or payment service provider.
            </p>
            <p>
                We use the services of Microsoft Azure, a service of the
                Microsoft Corporation, as identity provider for our Platform.
                For this purpose it is necessary to forward the User to a
                landing page of Microsoft during the login and Microsoft
                verifies the login data with our database. It may be necessary
                that Microsoft transfers the data to servers in the US.
                Microsoft will use the data only in compliance with our
                instructions and for the purpose of verifying the identity for
                the access to the Platform.
            </p>
            <p>
                Our website uses Mixpanel, a web analytics service provided by
                Mixpanel, Inc. (hereinafter ‘Mixpanel’), to analyse user
                behaviour and continuously improve our services. Mixpanel
                enables us to collect anonymised data such as page views,
                interactions with the website and navigation patterns. This data
                helps us to optimise the user experience and design targeted
                content.
            </p>
            <ol>
                <li>
                    <p class="privacy__li__sub_headline">Processed data</p>
                    <p></p>
                    <p>Mixpanel processes data such as:</p>
                    <ul>
                        <li>
                            <p>Browser type and version</p>
                        </li>
                        <li>
                            <p>Operating system</p>
                        </li>
                        <li>
                            <p>Pages visited and their duration</p>
                        </li>
                        <li>
                            <p>
                                Click behaviour and other interactions with the
                                website
                            </p>
                        </li>
                        <li>
                            <p>IP address (shortened and anonymised)</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">Use of data</p>
                    <p></p>
                    <p>
                        Mixpanel uses cookies to collect information about user
                        behaviour. These cookies do not store any personal data,
                        but only anonymised usage data. Further information on
                        the cookies used by Mixpanel can be found in
                        <a
                            target="_blank"
                            href="https://mixpanel.com/legal/privacy-policy/"
                            >Mixpanel's privacy policy</a
                        >.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">Opt-Out</p>
                    <p></p>
                    <p>
                        You can object to data collection by Mixpanel at any
                        time. To deactivate data collection, please use the
                        following opt-out link:
                        <a
                            (click)="deactivateTrackingOnThisDevice()"
                            class="deactivate-user-tracking-link"
                            >Clicking here</a
                        >.
                    </p>
                </li>
                <li>
                    <p class="privacy__li__sub_headline">Legal basis</p>
                    <p></p>
                    <p>
                        The data is processed on the basis of Art. 6 para. 1
                        lit. f GDPR. Our legitimate interest lies in improving
                        and analysing our website and services.
                    </p>
                </li>
                <li>
                    <p>
                        We use a service called “LaunchDarkly” of Catamorphic,
                        Co. („Catamorphic“) to update the Platform, add new
                        features and to perform version checks. Therefore, it
                        can be necessary to transfer personal data (email,
                        UserID of Azure and Cognito, language, time zone, the
                        authorized actions of the User on the Platform, and
                        information about activated feature flags (which provide
                        information about activated parts of the source code of
                        the Platform)) to servers of Catamorphic which may be
                        located in countries outside the European Economic Area
                        (EEA). Catamorphic will process the data only in
                        compliance with our instructions and only for the
                        purposes described and in aggregated form for
                        statistical evaluation. The privacy policy for
                        LaunchDarkly can be accessed
                        <a
                            target="_blank"
                            href="https://launchdarkly.com/policies/privacy"
                            >here</a
                        >.
                    </p>
                    <p>
                        The Platform uses the mapping service of OpenStreetMap.
                        For displaying the mapping functions on the Platform
                        certain technical data (in particular IP addresses,
                        browser and device type, operating system, referring web
                        page, date and time of page visit) may be automatically
                        transferred from the device of the User to the operator
                        of OpenStreetMap, the OpenStreetMap Foundation in order
                        to enable the User the use of the mapping services. The
                        data may be transferred to servers located outside the
                        European Economic Area. This transfer of data is
                        required to provide the tools of the Platform to the
                        User which are based on the mapping service and are thus
                        to fulfill our contractual obligations (Art. 6 para. 1
                        lit. b) GDPR). The OpenStreetMap Foundation will not use
                        the data for other purposes and will usually delete or
                        anonymize the data directly after the provision of the
                        service. Further information regarding the data
                        processing practice of the OpenStreetMap Foundation can
                        be found
                        <a
                            target="_blank"
                            href="https://wiki.osmfoundation.org/wiki/Privacy_Policy#Detailed_Information"
                            >here</a
                        >.
                    </p>
                    <p>
                        The Platform implements web fonts via the Google Fonts
                        API, a service of Google LLC in Mountain View, USA.
                        These fonts are provided directly from the servers of
                        Google in the US to the device of the User when
                        accessing the Platform in order to enable the User the
                        correct display of the Platform (Art. 6 para. 1 lit. f)
                        GDPR). For this purpose i.a. the IP address is being
                        forwarded to the Google server. This information is not
                        matched to other data that Google may collect from you.
                        Google may assess the use of certain fonts in anonymized
                        form in order to analyze the popularity of individual
                        fonts.
                    </p>
                    <p>
                        We also use the service Datadog, a cloud service of
                        Datadog, Inc. in the USA for function monitoring of the
                        Platform as well as the analysis and avoidance of
                        software bugs in the future. For this purpose, it is
                        required to transmit certain technical data of the
                        devices of users encountering bugs automatically to
                        servers of Datadog in the US. This data includes the IP
                        address as well as the browser and operating system
                        version of the device. The transmitting IP address of
                        the individual User will not be stored by Datadog and
                        will not be disclosed to us at any time. Collection and
                        analysis is necessary to ensure the functionality and
                        security of the Platform (Art. 6 para. 1 lit. f) GDPR).
                        The User can further provide feedback (anonymous or
                        voluntarily with name indication) in case he encountered
                        any bugs on the Platform. Providing feedback is
                        voluntary. However, should the User decide for providing
                        feedback, this will also be transferred under Art. 6
                        para. 1 lit. f) GDPR to Datadog and helps us to analyze
                        and rectify the bug.
                    </p>
                    <p>
                        The User can also decide to provide voluntary feedback
                        (anonymous or voluntarily with name indication) on the
                        Platform without a bug. This feedback will also be
                        transferred under Art. 6 para. 1 lit. f) GDPR to Datadog
                        along with the above anonymous technical data and will
                        be used by us for the improvement of the Platform. For
                        this purpose no personal data is collected or shared
                        with Datadog.
                    </p>
                    <p>
                        If we transfer personal data to recipients outside the
                        European Economic Area (EEA) and the recipient country
                        does not ensure an adequate level of protection for
                        personal data as determined by the European Commission,
                        we take appropriate measures ourselves to ensure an
                        adequate level of protection. These measures may
                        include, for example, the conclusion of so-called EU
                        Standard Contractual Clauses. Where these are not
                        sufficient to ensure an adequate level of protection, we
                        take appropriate additional safeguards. We will gladly
                        provide you with copies of the Standard Contractual
                        Clauses concluded and descriptions of the additional
                        safeguards upon request. If you are interested in these
                        copies and descriptions, please feel free to contact us
                        at
                        <a href="mailto:datenschutz@vtg.com"
                            >datenschutz&#64;vtg.com</a
                        >.
                    </p>
                </li>
            </ol>
        </li>

        <li class="privacy__li__headline">
            <h4>Rights of the User</h4>
            <p>
                The User has the right to request access to his personal
                information stored by VTG pursuant to Art. 15 GDPR, to request
                correction of incorrect data pursuant to Art. 16 GDPR as well as
                the right to request deletion pursuant to Art. 17 GDPR or
                restriction of use pursuant to Art. 18 GDPR. Further, the User
                has pursuant to Art. 21 para. 1 GDPR the right to object to the
                processing if his personal situation provides for a specific
                reason and personal information is affected that we process for
                the protection of our legitimate interests (Art. 6 para. 1 lit.
                f) GDPR). A right to object also applies if personal data is
                being used for direct marketing (Art. 21 para. 2 GDPR). The User
                has the right to request that his personal information provided
                by him are transferred in a machine-readable format. The User
                can also lodge a complaint with any competent supervisory
                authority pursuant to Art. 77 GDPR if he believes that we
                process his personal information not in compliance with
                applicable law.
            </p>
        </li>
        <li class="privacy__li__headline">
            <h4>Contact Details for Data Protection</h4>
            <p>
                In case of questions related to the collection, processing or
                use of personal information as well as for claims for access,
                correction, restriction of use, deletion of data or revocation
                of consent or any other rights the User may refer to:
            </p>
            <p>
                <a href="mailto:datenschutz@vtg.com">datenschutz&#64;vtg.com</a>
                or under our postal address with the referrer “the Data
                Protection Officer”.
            </p>
        </li>
        <li class="privacy__li__headline">
            <h4>Data Security</h4>
            <p>
                The personal information of the User when using the Platform
                will be transmitted in encrypted format via SSL/TLS.
            </p>
            <p>
                We secure the Platform and our other systems by technical and
                organizational means against loss, destruction, access, change
                or disclosure of data by unauthorized persons.
            </p>
        </li>
    </ol>
</section>
