export enum FleetOverviewArea {
    fleetTab = 'fleetTab',
    fleetTable = 'fleetTable',
    idleTimesTab = 'idleTimesTab',
    inactiveTab = 'inactiveTab',
    mileageStatus = 'mileageStatus',
    mileageTab = 'mileageTab',
    mileageTable = 'mileageTable',
    revisionProjection = 'revisionProjection',
    revisionStatus = 'revisionStatus',
}

export enum FleetOverviewAction {
    chartClicked = 'chartClicked',
    exportExcelClicked = 'exportExcelClicked',
    filterDateSet = 'filterDateSet',
    quickFilterClicked = 'quickFilterClicked',
    tabClicked = 'tabClicked',
    tableExportClicked = 'tableExportClicked',
    tableFilterApplied = 'tableFilterApplied',
    tableFilterReset = 'tableFilterReset',
    tableFilterSorted = 'tableFilterSorted',
    tableHeaderNameClicked = 'tableHeaderNameClicked',
    tableResetClicked = 'tableResetClicked',
    wagonNumberClicked = 'wagonNumberClicked',
}
