<app-dialog-wrapper>
    <div title>
        <translate
            [params]="{ groupName: data.groupName }"
            [translate]="data.title"
        ></translate>
    </div>
    <div class="pb-4" body>
        <span [translate]="data.body"></span>
    </div>
    <app-button-group actions alignment="right">
        <app-button
            buttonType="basic-danger"
            [breakLines]="true"
            (buttonClick)="onCloseClick()"
        >
            <span [translate]="data.continueButton"></span>
        </app-button>

        <app-button
            buttonType="flat-danger"
            [breakLines]="true"
            (buttonClick)="onQuitClick()"
        >
            <span [translate]="data.quitButton"></span>
        </app-button>
    </app-button-group>
</app-dialog-wrapper>
