/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ComponentType,
    Overlay,
    OverlayConfig,
    OverlayRef,
} from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Injectable } from '@angular/core'
import { DialogData } from '@shared-ui-lib/dialog/dialog.model'
import { DialogService } from '@shared-ui-lib/dialog/dialog.service'
import { OverlayAnimatedPortalComponent } from '@shared-ui-lib/overlay/overlay-animated-portal/overlay-animated-portal.component'
import { OverlayCloseDialogComponent } from '@shared-ui-lib/overlay/overlay-animated-portal/overlay-close-dialog/overlay-close-dialog.component'
import { OverlayContentRef } from '@shared-ui-lib/overlay/overlay-content-ref'
import { BehaviorSubject } from 'rxjs'

export const defaultOverlayConfig: OverlayConfig = {
    height: 'calc(100% - 56px)', // HEADER
    width: '45%',
    maxWidth: '720px',
    minWidth: '440px',
    panelClass: 'animated-overlay',
}

@Injectable({
    providedIn: 'root',
})
export class OverlayService {
    private _overlayRef?: OverlayRef | null = null
    private _overlayContentRef?: OverlayContentRef<ComponentType<any>> | null =
        null

    private getOverlayConfig(customConfig?: OverlayConfig): OverlayConfig {
        return new OverlayConfig({
            ...defaultOverlayConfig,
            ...customConfig,
            positionStrategy: this.overlay.position().global().right().bottom(),
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        })
    }

    private closeRequestData?: DialogData | null

    overlayData$ = new BehaviorSubject<any>(undefined)

    constructor(
        private overlay: Overlay,
        private dialogService: DialogService
    ) {}

    private checkBeforeClose(callbackFn: () => void) {
        // if not set close directly
        if (this.closeRequestData !== undefined) {
            const requestTitle =
                this.closeRequestData?.title ?? 'OverlayCloseRequestTitle'
            const requestBodyText =
                this.closeRequestData?.bodyText ?? 'OverlayCloseRequestBodyText'
            const requestActionButtonText =
                this.closeRequestData?.actionButtonText ?? 'Close'
            const requestCancelButtonText =
                this.closeRequestData?.cancelButtonText ?? 'Cancel'

            this.dialogService
                .openDialog(OverlayCloseDialogComponent, {
                    title: requestTitle,
                    bodyText: requestBodyText,
                    actionButtonText: requestActionButtonText,
                    cancelButtonText: requestCancelButtonText,
                    actionButtonTracking:
                        this.closeRequestData?.actionButtonTracking,
                    cancelButtonTracking:
                        this.closeRequestData?.cancelButtonTracking,
                } as DialogData)
                .afterClosed()
                .subscribe((closeNote) => {
                    if (closeNote.actionButtonClicked) {
                        callbackFn()
                    } else {
                        return
                    }
                })
        } else {
            callbackFn()
        }
    }

    // "core" open layer function
    private openLayer(component: ComponentType<any>) {
        const overlayAnimatedPortal = new ComponentPortal(
            OverlayAnimatedPortalComponent
        )

        this._overlayRef = this.overlay.create(this.getOverlayConfig())
        this._overlayContentRef = new OverlayContentRef(this._overlayRef)
        const animatedPortalRef = this._overlayRef.attach(overlayAnimatedPortal)
        this._overlayContentRef.componentInstance = animatedPortalRef.instance

        const componentPortal = new ComponentPortal(component)

        animatedPortalRef.instance.componentPortal = componentPortal
    }

    openAnimatedLayer(
        component: ComponentType<any>,
        data?: object,
        closeRequestData?: DialogData | null
    ) {
        if (this._overlayRef) {
            this.checkBeforeClose(() => {
                this.closeLayerDirectly()
                // emit data after check
                this.overlayData$.next(data)
                this.openLayer(component)
            })
        } else {
            this.overlayData$.next(data)
            this.openLayer(component)
        }

        // set close request data for currently open dialog,
        // so when the next dialog will be opened, this function knows the
        // close request setup of the previous layer
        // e.g. #1 open editor with close request data
        //      #2 open creator, check for open editor #1 and display dialog with the right text
        this.closeRequestData = closeRequestData
    }

    private closeLayerDirectly() {
        this._overlayRef = null
        this._overlayContentRef?.close()
        this._overlayContentRef = null
        this.overlayData$.next(undefined)
    }

    public closeLayer(forceClose?: boolean) {
        if (this._overlayRef) {
            // for automated closing e.g. submit form and close editor
            if (forceClose) {
                this.closeLayerDirectly()
            } else {
                this.checkBeforeClose(() => {
                    this.closeLayerDirectly()
                })
            }
        }
    }
}
