import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Action } from '@auth-util-lib/auth.model'
import { AuthzService } from '@auth-util-lib/authz.service'
import { StatusType } from '@datadog/browser-logs'
import { DatadogService } from '@error-util-lib/datadog/datadog.service'
import { GroupManagementApiService } from '@group-management-lib/group-management.api.service'
import {
    ChangeGroupSelectionRequest,
    ResourceType,
} from '@group-management-lib/group-management.model'
import { GroupManagementOverlayService } from '@group-management-lib/group-management.overlay.service'
import {
    changeGroupSelection,
    changeGroupSelectionError,
    changeGroupSelectionSuccess,
    createGroup,
    createGroupError,
    createGroupSuccess,
    deleteGroup,
    deleteGroupError,
    deleteGroupSuccess,
    displayedEditGroupSnackBar,
    editGroup,
    editGroupError,
    editGroupSuccess,
    loadColleaguesSuccess,
    loadGroupsFromBackend,
    loadGroupsFromBackendError,
    loadGroupsFromBackendSuccess,
    loadGroupsWithResources,
    loadGroupsWithResourcesError,
    loadGroupsWithResourcesSuccess,
    loadMyAvailableResources,
    loadMyAvailableResourcesError,
    loadMyAvailableResourcesSuccess,
    refreshBackend,
    triggerDataReload,
} from '@group-management-lib/redux/group-management.actions'
import { observeIf } from '@group-management-lib/util/observable.functions'
import { TranslationService } from '@localization-lib/language/translation.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { SnackbarComponent } from '@shared-ui-lib/snackbar/snackbar.component'
import { SnackbarData } from '@shared-ui-lib/snackbar/snackbar.model'
import { Observable, combineLatestWith, forkJoin, of } from 'rxjs'
import {
    catchError,
    concatMap,
    delay,
    map,
    mergeMap,
    shareReplay,
    switchMap,
} from 'rxjs/operators'
import { selectGroupManagement } from './group-management.selectors'
import { GroupManagementState } from './group-management.state'

@Injectable()
export class GroupManagementEffects {
    // #######################
    // General
    // #######################
    private readonly isAdmin$: Observable<boolean> = this.authzService
        .hasAction$(Action.ShowUserManagement)
        .pipe(shareReplay(1))

    groupManagementState$ = this.store$.pipe(select(selectGroupManagement))

    loadGroupsFromBackend$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadGroupsFromBackend),
            mergeMap(() =>
                this.groupManagementService.getMyGroupsMetaData().pipe(
                    map((response) =>
                        loadGroupsFromBackendSuccess({
                            groupsFromBackend: response.groups,
                        })
                    ),
                    catchError((error) => {
                        this.datadogService.log(error, StatusType.error)
                        return of(loadGroupsFromBackendError({ error }))
                    })
                )
            )
        )
    )

    triggerDelayedDataReload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(editGroupSuccess),
            delay(5000), //delay reload for 5s so that the user can read the snackbar
            map((action) => triggerDataReload())
        )
    )

    // #######################
    // Group Management Page
    // #######################

    loadColleagues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadGroupsWithResources),
            combineLatestWith(this.isAdmin$),
            mergeMap(([action, isAdmin]) => {
                if (isAdmin) {
                    return this.groupManagementService.getMyColleagues().pipe(
                        map((r) =>
                            loadColleaguesSuccess({ colleagues: r.users })
                        )
                        // @todo: catch me if you can
                    )
                }
                return of(loadColleaguesSuccess({ colleagues: [] }))
            })
        )
    )

    loadGroupsWithResources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadGroupsWithResources),
            mergeMap(() =>
                this.groupManagementService.getMyGroupsWithResources().pipe(
                    map((response) =>
                        loadGroupsWithResourcesSuccess({
                            groupsWithResources: response.groups,
                            hasSynchronizedGroups:
                                response.hasSynchronizedGroups,
                        })
                    ),
                    catchError((error) => {
                        this.datadogService.log(error, StatusType.error)
                        return of(loadGroupsWithResourcesError({ error }))
                    })
                )
            )
        )
    )

    // #######################
    // Dropdown / Header
    // #######################

    changeGroupSelection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeGroupSelection),
            switchMap(({ groupSelection }) =>
                this.groupManagementService
                    .changeGroupSelection({
                        groups: groupSelection,
                    } as ChangeGroupSelectionRequest)
                    .pipe(
                        map(() => {
                            this.store$.dispatch(
                                refreshBackend({ refresh: false })
                            )
                            this.store$.dispatch(loadGroupsFromBackend())
                            return changeGroupSelectionSuccess()
                        }),
                        catchError((error) => {
                            this.datadogService.log(error, StatusType.error)
                            return of(changeGroupSelectionError({ error }))
                        })
                    )
            )
        )
    )

    // #######################
    // General Group Overlay
    // #######################

    createGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createGroup),
            switchMap(
                ({
                    groupName,
                    areWagonsChangeable,
                    selectedWagons,
                    areUsersChangeable,
                    selectedUserIds,
                    isAccessPeriodChangeable,
                    selectedAccessPeriod,
                    areGeofencesChangeable,
                    selectedGeofenceIds,
                }) =>
                    this.groupManagementService
                        .createGroup({
                            name: groupName,
                        })
                        .pipe(
                            switchMap((response) => {
                                const groupId = response.id
                                const requests = [
                                    ...observeIf(areWagonsChangeable, () =>
                                        this.groupManagementService.changeResourcesForGroup(
                                            groupId,
                                            {
                                                resources: selectedWagons,
                                                resourceType:
                                                    ResourceType.WAGON,
                                            }
                                        )
                                    ),
                                    ...observeIf(areUsersChangeable, () =>
                                        this.groupManagementService.changeMembersGroup(
                                            groupId,
                                            {
                                                members: selectedUserIds.map(
                                                    (id) => ({ id })
                                                ),
                                            }
                                        )
                                    ),
                                    ...observeIf(isAccessPeriodChangeable, () =>
                                        this.groupManagementService.changeAccessPeriod(
                                            groupId,
                                            selectedAccessPeriod
                                        )
                                    ),
                                    ...observeIf(areGeofencesChangeable, () =>
                                        this.groupManagementService.changeResourcesForGroup(
                                            groupId,
                                            {
                                                resources:
                                                    selectedGeofenceIds?.map(
                                                        (id) => ({ id })
                                                    ) ?? [],
                                                resourceType:
                                                    ResourceType.GEOFENCE,
                                            }
                                        )
                                    ),
                                ]
                                return forkJoin(requests)
                            }),
                            mergeMap(() => {
                                this.groupManagementOvetlayService.closeLayer(
                                    true
                                )
                                return of(
                                    loadGroupsWithResources(),
                                    loadGroupsFromBackend(),
                                    createGroupSuccess()
                                )
                            }),
                            catchError((error) => {
                                this.datadogService.log(error, StatusType.error)
                                return of(createGroupError({ error }))
                            })
                        )
            )
        )
    )

    // #######################
    // Group Editor
    // #######################

    loadMyAvailableResources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadMyAvailableResources),
            switchMap(({ groupId }) =>
                this.groupManagementService
                    .getMyAvailableResourcesForGroup(groupId)
                    .pipe(
                        map((response) =>
                            loadMyAvailableResourcesSuccess({
                                availableResources: response,
                            })
                        ),
                        catchError((error) => {
                            this.datadogService.log(error, StatusType.error)
                            return of(loadMyAvailableResourcesError({ error }))
                        })
                    )
            )
        )
    )

    editGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(editGroup),
            switchMap(
                ({
                    groupId,
                    isGroupNameChangeable,
                    groupName,
                    areWagonsChangeable,
                    selectedWagons,
                    areGeofencesChangeable,
                    selectedGeofenceIds,
                    areUsersChangeable,
                    selectedUserIds,
                    isAccessPeriodChangeable,
                    selectedAccessPeriod,
                    isDefaultGroup,
                }) => {
                    const observableRequests = [
                        ...observeIf(
                            !isDefaultGroup && isGroupNameChangeable,
                            () =>
                                this.groupManagementService
                                    .editGroup(groupId, {
                                        name: groupName,
                                    })
                                    .pipe(
                                        concatMap(() => {
                                            // hotfix for DIP-17037
                                            // only make this call after the group name has been changed
                                            if (
                                                isAccessPeriodChangeable &&
                                                !isDefaultGroup
                                            ) {
                                                return this.groupManagementService.changeAccessPeriod(
                                                    groupId,
                                                    selectedAccessPeriod
                                                )
                                            } else {
                                                return of(null)
                                            }
                                        })
                                    )
                        ),
                        ...observeIf(
                            !isDefaultGroup && areWagonsChangeable,
                            () =>
                                this.groupManagementService.changeResourcesForGroup(
                                    groupId,
                                    {
                                        resources: selectedWagons,
                                        resourceType: ResourceType.WAGON,
                                    }
                                )
                        ),
                        ...observeIf(
                            !isDefaultGroup && areGeofencesChangeable,
                            () =>
                                this.groupManagementService.changeResourcesForGroup(
                                    groupId,
                                    {
                                        resources:
                                            selectedGeofenceIds?.map((id) => ({
                                                id,
                                            })) ?? [],
                                        resourceType: ResourceType.GEOFENCE,
                                    }
                                )
                        ),
                        ...observeIf(areUsersChangeable, () =>
                            this.groupManagementService.changeMembersGroup(
                                groupId,
                                {
                                    members: selectedUserIds.map((id) => ({
                                        id,
                                    })),
                                }
                            )
                        ),
                    ]
                    return forkJoin(observableRequests)
                }
            ),
            mergeMap(() => {
                this.groupManagementOvetlayService.closeLayer(true)
                return of(
                    loadGroupsWithResources(),
                    loadGroupsFromBackend(),
                    editGroupSuccess()
                )
            }),
            catchError((error) => {
                this.datadogService.log(error, StatusType.error)
                return of(editGroupError({ error }))
            })
        )
    )

    editGroupSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(editGroupSuccess),
            map(() => {
                this.snackBar.openFromComponent(SnackbarComponent, {
                    duration: 5000,
                    panelClass: ['primary'],
                    data: {
                        contentText: 'EditGroupSnackBarContent',
                        snackbarType: 'primary',
                        iconName: 'check-filled',
                    } as SnackbarData,
                })

                return displayedEditGroupSnackBar()
            })
        )
    )

    deleteGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteGroup),
            switchMap(({ groupId }) =>
                this.groupManagementService.deleteGroup(groupId).pipe(
                    map(() => {
                        this.store$.dispatch(loadGroupsWithResources())
                        this.store$.dispatch(loadGroupsFromBackend())
                        return deleteGroupSuccess()
                    }),
                    catchError((error) => {
                        this.datadogService.log(error, StatusType.error)
                        return of(deleteGroupError({ error }))
                    })
                )
            )
        )
    )

    constructor(
        private store$: Store<GroupManagementState>,
        private actions$: Actions,
        private groupManagementService: GroupManagementApiService,
        private groupManagementOvetlayService: GroupManagementOverlayService,
        private datadogService: DatadogService,
        private snackBar: MatSnackBar,
        private translationService: TranslationService,
        private authzService: AuthzService
    ) {}
}
