import { GroupSource } from '@group-management-lib/group-management.model'
import { checkTokenIdsEqualsBackendIds } from '@group-management-lib/util/checkTokenIdsEqualsBackendIds'
import { Action, createReducer, on } from '@ngrx/store'
import * as Actions from './group-management.actions'
import {
    GroupManagementState,
    initialGroupManagementState,
} from './group-management.state'

const _groupManagementReducer = createReducer(
    // #######################
    // General
    // #######################

    initialGroupManagementState,

    on(Actions.setGroupsFromToken, (state, { groupsFromToken }) => ({
        ...state,
        groupsFromToken,
        checkGroupsEqual: checkTokenIdsEqualsBackendIds(
            groupsFromToken,
            state.groupsFromBackend
        ),
        lastGroupSource: GroupSource.TOKEN,
    })),

    on(Actions.loadGroupsFromBackend, (state) => ({
        ...state,
        groupsFromBackendLoading: true,
        groupsFromBackendError: null,
    })),

    on(Actions.loadGroupsFromBackendError, (state, { error }) => ({
        ...state,
        groupsFromBackendLoading: false,
        groupsFromBackendError: error,
        groupsFromBackend: null,
        checkGroupsEqual: checkTokenIdsEqualsBackendIds(
            state.groupsFromToken,
            null
        ),
        lastGroupSource: GroupSource.BACKEND,
    })),

    on(
        Actions.loadGroupsFromBackendSuccess,
        (state, { groupsFromBackend }) => ({
            ...state,
            groupsFromBackendLoading: false,
            groupsFromBackendError: null,
            groupsFromBackend,
            checkGroupsEqual: checkTokenIdsEqualsBackendIds(
                state.groupsFromToken,
                groupsFromBackend
            ),
            lastGroupSource: GroupSource.BACKEND,
        })
    ),

    on(Actions.loadColleaguesSuccess, (state, { colleagues }) => ({
        ...state,
        colleagues,
    })),

    on(Actions.refreshBackend, (state, { refresh }) => ({
        ...state,
        refreshBackend: refresh,
    })),

    // #######################
    // Group Management Page
    // #######################

    on(Actions.loadGroupsWithResources, (state) => ({
        ...state,
        triggerLoadGroupsWithResources: false,
        groupsWithResourcesLoading: true,
        groupsWithResourcesError: null,
    })),

    on(Actions.loadGroupsWithResourcesError, (state, { error }) => ({
        ...state,
        groupsWithResourcesLoading: false,
        groupsWithResourcesError: error,
        groupsWithResources: null,
    })),

    on(
        Actions.loadGroupsWithResourcesSuccess,
        (state, { groupsWithResources, hasSynchronizedGroups }) => ({
            ...state,
            groupsWithResourcesLoading: false,
            groupsWithResourcesError: null,
            groupsWithResources,
            hasSynchronizedGroups,
        })
    ),

    // #######################
    // Dropdown / Header
    // #######################

    on(Actions.changeGroupSelection, (state) => ({
        ...state,
        changeGroupSelectionLoading: true,
        changeGroupSelectionError: null,
    })),

    on(Actions.changeGroupSelectionSuccess, (state) => ({
        ...state,
        changeGroupSelectionLoading: false,
        changeGroupSelectionError: null,
    })),

    on(Actions.changeGroupSelectionError, (state, { error }) => ({
        ...state,
        changeGroupSelectionLoading: false,
        changeGroupSelectionError: error,
    })),

    // #######################
    // General Group Overlay
    // #######################

    on(Actions.createGroup, (state) => ({
        ...state,
        createGroupLoading: true,
        createGroupError: null,
    })),

    on(Actions.createGroupSuccess, (state) => ({
        ...state,
        createGroupLoading: false,
        createGroupError: null,
    })),

    on(Actions.createGroupError, (state, { error }) => ({
        ...state,
        createGroupLoading: false,
        createGroupError: error,
    })),

    // #######################
    // Group Editor
    // #######################

    on(Actions.setGroupIdToEdit, (state, { groupIdToEdit }) => ({
        ...state,
        groupIdToEdit,
    })),

    on(Actions.loadMyAvailableResources, (state) => ({
        ...state,
        availableResources: null,
        availableResourcesLoading: true,
        availableResourcesError: null,
    })),

    on(
        Actions.loadMyAvailableResourcesSuccess,
        (state, { availableResources }) => ({
            ...state,
            availableResources,
            availableResourcesLoading: false,
            availableResourcesError: null,
        })
    ),

    on(Actions.loadMyAvailableResourcesError, (state, { error }) => ({
        ...state,
        availableResources: null,
        availableResourcesLoading: false,
        availableResourcesError: error,
    })),

    on(Actions.editGroup, (state) => ({
        ...state,
        editGroupLoading: true,
        editGroupError: null,
    })),

    on(Actions.editGroupSuccess, (state) => ({
        ...state,
        editGroupLoading: false,
        editGroupError: null,
    })),

    on(Actions.editGroupError, (state, { error }) => ({
        ...state,
        editGroupLoading: false,
        editGroupError: error,
    })),

    on(Actions.deleteGroup, (state) => ({
        ...state,
        groupIdToEdit: null,
        deleteGroupLoading: true,
        deleteGroupError: null,
    })),

    on(Actions.deleteGroupSuccess, (state) => ({
        ...state,
        deleteGroupLoading: false,
        deleteGroupError: null,
    })),

    on(Actions.deleteGroupError, (state, { error }) => ({
        ...state,
        deleteGroupLoading: false,
        deleteGroupError: error,
    }))
)

export const groupManagementReducer = (
    state: GroupManagementState | undefined,
    action: Action
) => _groupManagementReducer(state, action)
