@if (isAdmin$ | async) {
    <app-system-response
        small
        customIcon="error-unauthorized"
        responseType="empty"
        title="Sorry, you don't seem to have access to this tool!"
        message="Try logging on again or change permissions for you and your users in User Management."
        flatPrimaryButtonText="Go to user management"
        (flatPrimaryButtonClick)="navigateTo('/user-management')"
    ></app-system-response>
} @else {
    <app-system-response
        small
        customIcon="error-unauthorized"
        responseType="empty"
        title="Sorry, you don't seem to have access!"
        mess
        age="Try logging on again or contact your administrator."
        flatPrimaryButtonText="Go to dashboard"
        (flatPrimaryButtonClick)="navigateTo('/dashboard')"
    ></app-system-response>
}
