import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { GroupManagementQuitDialogComponent } from './group-management-quit-dialog.component'

import { MatDialogModule } from '@angular/material/dialog'
import { DialogWrapperComponent } from '@shared-ui-lib/dialog/dialog-wrapper.component'

@NgModule({
    declarations: [GroupManagementQuitDialogComponent],
    exports: [GroupManagementQuitDialogComponent],
    imports: [
        CommonModule,
        ButtonModule,
        ButtonGroupModule,
        TranslationModule,
        MatDialogModule,
        DialogWrapperComponent,
    ],
})
export class GroupManagementQuitDialogModule {}
