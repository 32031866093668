import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { UserMenuComponent } from '@platform-lib/components/user-menu/user-menu.component'
import { TrackingClickDirective } from '@tracking-lib/tracking-click/tracking-click.directive'

@NgModule({
    declarations: [UserMenuComponent],
    exports: [UserMenuComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        TranslationModule,
        TrackingClickDirective,
    ],
})
export class UserMenuModule {}
