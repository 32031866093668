import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { AppIcon } from '@portal-lib/app-icon.service'
import {
    ButtonStatus,
    ButtonType,
    IconPosition,
} from '@shared-ui-lib/button/button.model'
import { TrackingData } from '@tracking-lib/tracking.model'
import { TrackingService } from '@tracking-lib/tracking.service'
import { BehaviorSubject, combineLatest, map } from 'rxjs'

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    status$ = new BehaviorSubject<ButtonStatus>('default')

    @Input()
    set status(status: ButtonStatus) {
        this.status$.next(status)
    }

    disabled$ = new BehaviorSubject<boolean>(false)

    @Input()
    set disabled(disabled: boolean) {
        this.disabled$.next(coerceBooleanProperty(disabled))
    }

    uppercase$ = new BehaviorSubject<boolean>(false)

    @Input()
    set uppercase(uppercase: boolean) {
        this.uppercase$.next(coerceBooleanProperty(uppercase))
    }

    buttonType$ = new BehaviorSubject<ButtonType | undefined>(undefined)

    @Input()
    set buttonType(buttonType: ButtonType) {
        this.buttonType$.next(buttonType)
    }

    iconButton$ = new BehaviorSubject<boolean>(false)

    @Input()
    set iconButton(iconButton: boolean) {
        this.iconButton$.next(coerceBooleanProperty(iconButton))
    }

    submit$ = new BehaviorSubject<boolean>(false)

    @Input()
    set submit(submit: boolean) {
        this.submit$.next(coerceBooleanProperty(submit))
    }

    breakLines$ = new BehaviorSubject<boolean>(false)

    @Input()
    set breakLines(breakLines: boolean) {
        this.breakLines$.next(breakLines)
    }

    toggled$ = new BehaviorSubject<boolean>(false)

    @Input()
    set toggled(toggled: boolean) {
        this.toggled$.next(coerceBooleanProperty(toggled))
    }

    icon$ = new BehaviorSubject<AppIcon | undefined>(undefined)

    @Input()
    set icon(icon: AppIcon) {
        this.icon$.next(icon)
    }

    iconPosition$ = new BehaviorSubject<IconPosition | undefined>(undefined)

    @Input()
    set iconPosition(iconPosition: IconPosition) {
        this.iconPosition$.next(iconPosition)
    }

    @Input()
    trackingData?: TrackingData

    @Output() public readonly buttonClick = new EventEmitter<MouseEvent>()

    constructor(private trackingService: TrackingService) {}

    disabledOrLoading$ = combineLatest([
        this.disabled$,
        this.status$.pipe(),
    ]).pipe(map(([disabled, status]) => disabled || status === 'loading'))

    handleClick(event: MouseEvent) {
        if (!this.submit$.getValue()) event.preventDefault()

        if (this.trackingData) {
            this.trackingService.track(this.trackingData)
        }

        this.buttonClick.emit(event)
    }
}
