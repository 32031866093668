import { ComponentPortal, PortalModule } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { OverlayAnimatedContent } from '@shared-ui-lib/overlay/overlay-animated-content'
import { Animations } from '@shared-util-lib/animations/animations'

@Component({
    standalone: true,
    imports: [CommonModule, PortalModule],
    selector: 'app-overlay-animated-portal',
    styleUrls: ['./overlay-animated-portal.component.scss'],
    templateUrl: './overlay-animated-portal.component.html',
    animations: [Animations.slideContentRight],
})
export class OverlayAnimatedPortalComponent extends OverlayAnimatedContent {
    @Input() componentPortal?: ComponentPortal<unknown>
}
