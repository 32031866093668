<span class="text-body-3" translate>Users</span>
<div class="flex flex-col flex-1 justify-between overflow-auto py-4">
    <span translate class="text-accent-medium py-2">
        Specify who can see this group
    </span>
    <div (click)="onAnyCheckBoxClick()">
        <mat-checkbox
            color="primary"
            [checked]="allSelected$ | async"
            [indeterminate]="someSelected$ | async"
            [disabled]="
                (selectAllDisabled$ | async) || (allCheckboxesDisabled$ | async)
            "
            (change)="onSelectAllClick($event.checked)"
        >
            <translate class="text-body-3">Select all</translate>
        </mat-checkbox>
        @for (user of selectedUsers$ | async; track user.userId) {
            <div
                [matTooltip]="
                    user.changeable
                        ? ''
                        : ('AdminAlwaysInDefaultGroup' | translate)
                "
                class="pl-10"
            >
                <mat-checkbox
                    color="primary"
                    [checked]="user.selected"
                    [disabled]="
                        !user.changeable || (allCheckboxesDisabled$ | async)
                    "
                    (change)="onCheckBoxClick(user.userId, $event.checked)"
                >
                    {{ user.firstName }} {{ user.lastName }}
                </mat-checkbox>
            </div>
        }
    </div>
</div>
