import { Injectable, OnDestroy } from '@angular/core'
import { UserProfileService } from '@auth-util-lib/user-profile.service'
import { ApiService } from '@env-lib/api/api.service'
import { isDevelopment } from '@env-lib/isDevelopment'
import { isIntEnvironment } from '@env-lib/isIntEnvironment'
import { FeatureFlags } from '@feature-flag-lib/feature-flags'
import { FeatureFlagsService } from '@feature-flag-lib/feature-flags.service'
import { getEventName } from '@tracking-lib/getEventName'
import { TrackingData } from '@tracking-lib/tracking.model'
import mixpanel from 'mixpanel-browser'
import { combineLatest, Subscription } from 'rxjs'
import SHA3 from 'sha3'
import { CONSOLE_GREEN, CONSOLE_GREY, CONSOLE_YELLOW } from 'src/styles/console'
import { AuthzService } from '@auth-util-lib/authz.service'
import { Action } from '@auth-util-lib/auth.model'

@Injectable({ providedIn: 'root' })
export class TrackingService implements OnDestroy {
    private subscriptions = new Subscription()

    private mixpanelToken = this.api.mixpanel?.token

    private isTrackingEnabled = false
    private isMixpanelInitialized = false

    constructor(
        private api: ApiService,
        private userProfileService: UserProfileService,
        private featureFlagService: FeatureFlagsService,
        private authzService: AuthzService
    ) {
        this.subscriptions.add(
            combineLatest([
                this.featureFlagService.getFeatureFlag(
                    FeatureFlags.MixpanelDebugMode
                ),
                this.userProfileService.profileChanges(),
                this.authzService.hasAction$(Action.PreventTracking),
            ]).subscribe(([shouldDebug, userProfile, preventTracking]) => {
                this.isTrackingEnabled = false
                if (userProfile?.userId && !preventTracking) {
                    this.isTrackingEnabled = true
                    this.initMixpanel(shouldDebug)
                    const hash = new SHA3(512)
                    hash.update(userProfile?.userId)

                    mixpanel.identify(hash.digest('hex'))
                } else if (!userProfile?.userId && this.mixpanelToken) {
                    // do not or abort tracking when no userid is provided
                    mixpanel.disable()
                }
            })
        )
    }

    private initMixpanel(shouldDebug: boolean) {
        if (this.mixpanelToken && !this.isMixpanelInitialized) {
            mixpanel.init(this.mixpanelToken, {
                api_host: 'https://api-eu.mixpanel.com',
                persistence: 'localStorage',
                track_pageview: 'full-url',
                debug: shouldDebug,
            })
            this.isMixpanelInitialized = true
        }
    }

    track(trackingData: TrackingData) {
        if (isDevelopment() || isIntEnvironment()) {
            console.log(
                '%cmixpanel' +
                    '%c' +
                    getEventName(
                        trackingData.category,
                        trackingData.area,
                        trackingData.action
                    ) +
                    (trackingData.attributes
                        ? '%c' + JSON.stringify(trackingData.attributes)
                        : ''),
                CONSOLE_GREY,
                CONSOLE_GREEN,
                trackingData.attributes ? CONSOLE_YELLOW : ''
            )
        }

        if (this.isTrackingEnabled && this.isMixpanelInitialized) {
            mixpanel.track(
                getEventName(
                    trackingData.category,
                    trackingData.area,
                    trackingData.action
                ),
                trackingData.attributes
            )
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    deactivateTrackingOnThisDevice() {
        this.initMixpanel(false)
        mixpanel.opt_out_tracking()
    }
}
