import { CommonModule } from '@angular/common'
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core'
import { FormBuilder, ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { Router } from '@angular/router'
import { GroupMetaData } from '@group-management-lib/group-management.model'
import { GroupManagementOverlayService } from '@group-management-lib/group-management.overlay.service'
import { GroupManagementService } from '@group-management-lib/group-management.service'
import { changeGroupSelection } from '@group-management-lib/redux/group-management.actions'
import {
    hasGroupLoadingError,
    selectChangeGroupSelectionError,
    selectChangeGroupSelectionLoading,
    selectGroupOptions,
    selectGroupsFromBackendLoading,
    selectIsMemberOfDefaultGroup,
} from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { Store, select } from '@ngrx/store'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { SystemResponseModule } from '@shared-ui-lib/system-response/system-response.module'
import { DataQaTagPipeModule } from '@shared-util-lib/pipes/dataQaTag/data-qa-tag-pipe.module'
import { TrackingCategory } from '@tracking-lib/tracking.model'
import { TrackingService } from '@tracking-lib/tracking.service'
import {
    GroupManagementAction,
    GroupManagementArea,
} from '@tracking-lib/use-case-tracking-models/group-management.tracking.model'
import { deepEqual } from '@util-lib/deepEqual'
import { isTruthy } from '@util-lib/isTruthy'
import {
    BehaviorSubject,
    Observable,
    Subscription,
    combineLatest,
    filter,
} from 'rxjs'

@Component({
    selector: 'app-group-management-dropdown',
    styleUrls: ['./group-management-dropdown.component.scss'],
    templateUrl: './group-management-dropdown.component.html',
    standalone: true,
    imports: [
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        DataQaTagPipeModule,
        MatCheckboxModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        ReactiveFormsModule,
        SystemResponseModule,
        TranslationModule,
    ],
})
export class GroupManagementDropdownComponent implements OnDestroy {
    readonly searchFieldThreshold = 5

    isGroupManagementAllowed = false
    readonly isGroupManagementAllowed$ =
        this.groupManagementService.isGroupManagementAllowed$

    readonly userIsInRootGroup$: Observable<boolean> = this.store.pipe(
        select(selectIsMemberOfDefaultGroup)
    )

    subscriptions = new Subscription()

    TrackingCategory = TrackingCategory
    GroupManagementArea = GroupManagementArea
    GroupManagementAction = GroupManagementAction

    initialGroupOptions$ = new BehaviorSubject<GroupMetaData[] | undefined>(
        undefined
    )

    groupOptions: GroupMetaData[] | undefined
    groupOptions$ = new BehaviorSubject<GroupMetaData[] | undefined>(undefined)

    showSearchField$ = new BehaviorSubject<boolean>(false)
    filteredGroups$ = new BehaviorSubject<GroupMetaData[] | undefined>(
        undefined
    )

    hasGroupSelectionChanged = false

    isAnyGroupSelected = false

    readonly searchControl = this.formBuilder.control('')

    @Output()
    readonly emitCloseDialog = new EventEmitter()

    loading$ = this.store.pipe(select(selectGroupsFromBackendLoading))
    groupsLoadingError$ = this.store.pipe(select(hasGroupLoadingError))

    groupSelectionChangeLoading$ = this.store.pipe(
        select(selectChangeGroupSelectionLoading)
    )
    groupSelectionChangeError$ = this.store.pipe(
        select(selectChangeGroupSelectionError)
    )

    constructor(
        private router: Router,
        private store: Store<GroupManagementState>,
        private groupManagementService: GroupManagementService,
        private groupManagementOverlayService: GroupManagementOverlayService,
        private formBuilder: FormBuilder,
        private trackingService: TrackingService
    ) {
        this.subscriptions.add(
            this.store
                .pipe(select(selectGroupOptions))
                .pipe(filter(isTruthy))
                .pipe(filter((g) => g.length >= 1))
                .subscribe((initialGroups) => {
                    this.initialGroupOptions$.next(initialGroups)
                    this.groupOptions$.next(initialGroups)
                    this.filteredGroups$.next(initialGroups)
                })
        )

        this.subscriptions.add(
            this.isGroupManagementAllowed$.subscribe((isAllowed) => {
                this.isGroupManagementAllowed = isAllowed
            })
        )

        this.subscriptions.add(
            this.searchControl.valueChanges.subscribe((value) => {
                if (value) {
                    // track only the first letter input to see if this search has been used
                    if (value.length === 1) {
                        this.trackingService.track({
                            category: TrackingCategory.groupManagement,
                            area: GroupManagementArea.overlay,
                            action: GroupManagementAction.filterValueEntered,
                        })
                    }

                    this.filteredGroups$.next(
                        this.groupOptions$.value?.filter((groupOption) =>
                            groupOption.name
                                .toLowerCase()
                                .includes(value?.toLowerCase())
                        )
                    )
                } else {
                    this.filteredGroups$.next(this.groupOptions$.value)
                }
            })
        )

        this.subscriptions.add(
            this.groupOptions$.subscribe((groups) => {
                this.groupOptions = groups
                if (
                    groups &&
                    groups.length >= this.searchFieldThreshold &&
                    !this.showSearchField$.value
                ) {
                    this.showSearchField$.next(true)
                } else if (
                    (!groups || groups.length < this.searchFieldThreshold) &&
                    !this.showSearchField$.value
                ) {
                    this.showSearchField$.next(false)
                }
            })
        )

        this.subscriptions.add(
            combineLatest([
                this.initialGroupOptions$,
                this.groupOptions$,
            ]).subscribe(([initialGroups, selectedGroups]) => {
                if (initialGroups && selectedGroups) {
                    this.hasGroupSelectionChanged = deepEqual(
                        initialGroups,
                        selectedGroups
                    )
                }
            })
        )

        this.subscriptions.add(
            this.groupOptions$.subscribe(
                (groups) =>
                    (this.isAnyGroupSelected =
                        !!groups &&
                        groups?.filter((group) => group.isSelected).length > 0)
            )
        )
    }

    toggleGroup(id: string) {
        this.groupOptions$.next(
            this.groupOptions?.map((group) => ({
                ...group,
                isSelected:
                    group.id === id ? !group.isSelected : group.isSelected,
            }))
        )
    }

    viewGroupDetails(id: string) {
        this.emitCloseDialog.emit()

        this.trackingService.track({
            category: TrackingCategory.groupManagement,
            area: GroupManagementArea.overlay,
            action: this.isGroupManagementAllowed
                ? GroupManagementAction.groupEditClicked
                : GroupManagementAction.groupDetailsClicked,
        })

        this.groupManagementOverlayService.openLayer(id)
    }

    openSettings() {
        this.trackingService.track({
            category: TrackingCategory.groupManagement,
            area: GroupManagementArea.overlay,
            action: GroupManagementAction.settingsClicked,
        })
        this.router.navigate(['group-management'])
    }

    createNewGroup() {
        this.emitCloseDialog.emit()

        this.trackingService.track({
            category: TrackingCategory.groupManagement,
            area: GroupManagementArea.overlay,
            action: GroupManagementAction.newGroupClicked,
        })

        this.groupManagementOverlayService.openLayer()
    }

    onClearButtonClicked() {
        this.trackingService.track({
            category: TrackingCategory.groupManagement,
            area: GroupManagementArea.overlay,
            action: GroupManagementAction.filterValueDeleted,
        })
        this.searchControl.setValue('')
    }

    handleReset() {
        this.emitCloseDialog.emit()

        this.groupOptions$.next(
            this.groupOptions?.map((group) => ({
                ...group,
                isSelected: false,
            }))
        )
        this.applyChanges()
    }

    applyChanges() {
        this.emitCloseDialog.emit()

        this.store.dispatch(
            changeGroupSelection({
                groupSelection:
                    this.groupOptions$.value?.map((groupOption) => ({
                        id: groupOption.id,
                        selected: groupOption.isSelected,
                    })) ?? [],
            })
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
