import { Directive, HostListener, Input } from '@angular/core'
import { TrackingData } from '@tracking-lib/tracking.model'
import { TrackingService } from '@tracking-lib/tracking.service'

@Directive({
    standalone: true,
    selector: '[clickTracking]', // eslint-disable-line @angular-eslint/directive-selector
})
export class TrackingClickDirective<Event extends TrackingData> {
    @Input()
    trackingCategory?: Event['category']

    @Input()
    trackingArea?: Event['area']

    @Input()
    trackingAction?: Event['action']

    @Input()
    trackingAttributes?: Event['attributes']

    @HostListener('click', ['$event.target'])
    onClick() {
        if (this.trackingCategory && this.trackingArea && this.trackingAction) {
            this.trackingService.track({
                category: this.trackingCategory,
                area: this.trackingArea,
                action: this.trackingAction,
                attributes: this.trackingAttributes,
            })
        }
    }

    constructor(private trackingService: TrackingService) {}
}
