import { Injectable, OnDestroy } from '@angular/core'
import { Action } from '@auth-util-lib/auth.model'
import { AuthnService } from '@auth-util-lib/authn.service'
import { AuthzService } from '@auth-util-lib/authz.service'
import {
    refreshBackend,
    setGroupsFromToken,
} from '@group-management-lib/redux/group-management.actions'
import {
    selectAvailableResources,
    selectGroupsIdsCheck,
} from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { Store, select } from '@ngrx/store'
import { isTruthy } from '@util-lib/isTruthy'
import {
    Observable,
    Subscription,
    combineLatest,
    distinctUntilChanged,
} from 'rxjs'
import { filter, map, shareReplay, startWith } from 'rxjs/operators'
import { GroupSource } from './group-management.model'

@Injectable({
    providedIn: 'root',
})
export class GroupManagementService implements OnDestroy {
    private subscriptions = new Subscription()
    previousGroupsCheck: boolean | null = null

    readonly minInputLength = 3
    readonly maxInputLength = 12

    private readonly isGroupAccessControlEnabled$ =
        this.authzService.isGroupAccessControlEnabled$

    readonly isAdmin$: Observable<boolean> = this.authzService
        .hasAction$(Action.ShowUserManagement)
        .pipe(shareReplay(1))

    /**
     * Managing groups is allowed if groupAccessControl is disabled.
     * If it is enabled, the user must be admin.
     */
    readonly isGroupManagementAllowed$: Observable<boolean> = combineLatest([
        this.isGroupAccessControlEnabled$,
        this.isAdmin$,
    ]).pipe(
        map(
            ([isGroupAccessControlEnabled, isAdmin]) =>
                !isGroupAccessControlEnabled || isAdmin
        ),
        shareReplay(1)
    )

    /**
     * Assigning individual users or access periods to groups is only possible if
     * groupAccessControl is enabled and the user is admin.
     */
    readonly isAdminInGacClient$: Observable<boolean> = combineLatest([
        this.isGroupAccessControlEnabled$,
        this.isAdmin$,
    ]).pipe(
        map(
            ([isGroupAccessControlEnabled, isAdmin]) =>
                isGroupAccessControlEnabled && isAdmin
        ),
        shareReplay(1)
    )

    constructor(
        private authzService: AuthzService,
        private authnService: AuthnService,
        private store: Store<GroupManagementState>
    ) {
        // token - load (reacts to changes)
        this.subscriptions.add(
            this.authzService
                .getClaim$('group_ids')
                .pipe(
                    map((groupIds) => {
                        if (groupIds === null || typeof groupIds === 'number') {
                            return []
                        }
                        return groupIds.split(',').filter(isTruthy)
                    }),
                    distinctUntilChanged()
                )
                .subscribe((groupsFromToken) => {
                    this.store.dispatch(
                        setGroupsFromToken({
                            groupsFromToken: groupsFromToken,
                        })
                    )
                })
        )

        // listen to check group ids changes
        // if changes from valid to invalid, then
        // reload either token or backend depending of
        // which source has triggered the check
        this.subscriptions.add(
            this.store
                .pipe(select(selectGroupsIdsCheck))
                .subscribe(({ check, source }) => {
                    if (this.previousGroupsCheck === true && check === false) {
                        if (source === GroupSource.BACKEND) {
                            this.authnService.refreshAccessToken()
                        }

                        if (source === GroupSource.TOKEN) {
                            this.store.dispatch(
                                refreshBackend({ refresh: true })
                            )
                        }
                        // in case someone else added or deleted a group
                    } else if (
                        this.previousGroupsCheck === false &&
                        check === false &&
                        source === GroupSource.BACKEND
                    ) {
                        this.authnService.refreshAccessToken()
                    }
                    this.previousGroupsCheck = check
                })
        )
    }

    private availableResources$ = this.store.select(selectAvailableResources)

    private availableWagons$ = this.availableResources$.pipe(
        map((resources) => resources?.resources.wagons),
        filter(isTruthy)
    )

    private isEmptyGroup$ = this.availableWagons$.pipe(
        map((wagons) => wagons.length === 0),
        startWith(false)
    )

    public isGroupEmptyAndNormalUserInGacClient$ = combineLatest([
        this.isAdmin$,
        this.isEmptyGroup$,
        this.isGroupAccessControlEnabled$,
    ]).pipe(
        map(
            ([isAdmin, isEmptyGroup, isGacEnabled]) =>
                !isAdmin && isEmptyGroup && isGacEnabled
        )
    )

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
