import { CommonModule } from '@angular/common'
import { Component, Input, forwardRef } from '@angular/core'
import {
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatTooltipModule } from '@angular/material/tooltip'
import { Geofence } from '@group-management-lib/group-management.model'
import { selectAvailableResources } from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { Store } from '@ngrx/store'
import { BaseControlValueAccessor } from '@shared-ui-lib/base-control-value-accessor/base-control-value-accessor.component'
import { InlineMessageComponent } from '@shared-ui-lib/inline-message/inline-message.component'
import { InlineMessageType } from '@shared-ui-lib/inline-message/inline-message.model'
import { SystemResponseModule } from '@shared-ui-lib/system-response/system-response.module'
import { TrackingCategory } from '@tracking-lib/tracking.model'
import { TrackingService } from '@tracking-lib/tracking.service'
import {
    GroupManagementAction,
    GroupManagementArea,
} from '@tracking-lib/use-case-tracking-models/group-management.tracking.model'
import { BehaviorSubject, Subscription, map } from 'rxjs'

@Component({
    selector: 'app-group-management-geofence-selection',
    templateUrl: './group-management-geofence-selection.component.html',
    styleUrls: ['./group-management-geofence-selection.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        InlineMessageComponent,
        MatCheckboxModule,
        MatTooltipModule,
        ReactiveFormsModule,
        SystemResponseModule,
        TranslationModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(
                () => GroupManagementGeofenceSelectionComponent
            ),
            multi: true,
        },
    ],
})
export class GroupManagementGeofenceSelectionComponent extends BaseControlValueAccessor<
    string[]
> {
    InlineMessageType = InlineMessageType

    selectedGeofences$ = new BehaviorSubject<Geofence[]>([])
    @Input()
    set geofences(value: Geofence[] | null) {
        if (value) {
            this.selectedGeofences$.next(
                value.sort((a, b) => a.name.localeCompare(b.name))
            )
        }
    }

    @Input()
    trackingArea?: GroupManagementArea

    subscriptions = new Subscription()

    constructor(
        private trackingService: TrackingService,
        private store: Store<GroupManagementState>
    ) {
        super()

        this.subscriptions.add(
            this.store
                .select(selectAvailableResources)
                .subscribe((availableResources) => {
                    this.isDefaultGroup$.next(
                        availableResources?.defaultGroup ?? true
                    )

                    this.selectAllDisabled$.next(
                        !availableResources?.resources?.geofencesChangeable ??
                            true
                    )
                })
        )

        this.subscriptions.add(
            this.selectedGeofences$.subscribe((selectedGeofences) => {
                if (!this.disabled) {
                    const selectedGeofenceIds = selectedGeofences
                        .filter((geofence) => geofence.selected)
                        .map((geofence) => geofence.geofenceId)
                    this.value = selectedGeofenceIds
                    this.onChanged(selectedGeofenceIds)
                    this.onTouched()
                }
            })
        )
    }

    someSelected$ = this.selectedGeofences$.pipe(
        map((users) => {
            const numberOfAllGeofences = users.length
            const numberOfSelectedGeofences = users.filter(
                (user) => user.selected
            ).length
            return (
                numberOfSelectedGeofences > 0 &&
                numberOfSelectedGeofences !== numberOfAllGeofences
            )
        })
    )

    allSelected$ = this.selectedGeofences$.pipe(
        map(
            (geofences) =>
                geofences.length ===
                geofences.filter((geofence) => geofence.selected).length
        )
    )

    selectAllDisabled$ = new BehaviorSubject<boolean>(false)

    allCheckboxesDisabled$ = new BehaviorSubject<boolean>(false)

    isDefaultGroup$ = new BehaviorSubject<boolean>(true)

    trackingCounter = 0

    noGeofencesAvailable$ = this.selectedGeofences$.pipe(
        map((geofences) => geofences.length === 0)
    )

    onAnyCheckBoxClick() {
        if (this.trackingCounter === 0 && this.trackingArea) {
            this.trackingCounter = 1
            this.trackingService.track({
                category: TrackingCategory.groupManagement,
                area: this.trackingArea,
                action: GroupManagementAction.groupUsersSelected,
            })
        }
    }

    onCheckBoxClick(geofenceId: string, checked: boolean) {
        const currentUsers = this.selectedGeofences$.getValue()
        this.selectedGeofences$.next(
            currentUsers.map((geofence) => ({
                ...geofence,
                selected:
                    geofence.geofenceId === geofenceId
                        ? checked
                        : geofence.selected,
            }))
        )
    }

    onSelectAllClick(checked: boolean) {
        const currentUsers = this.selectedGeofences$.getValue()
        this.selectedGeofences$.next(
            currentUsers.map((geofence) => ({
                ...geofence,
                selected: checked,
            }))
        )
    }

    public setDisabledState(disabled: boolean): void {
        this.allCheckboxesDisabled$.next(disabled)
    }
}
