import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, OnDestroy } from '@angular/core'
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTabsModule } from '@angular/material/tabs'
import { FeatureFlagsService } from '@feature-flag-lib/feature-flags.service'
import { GroupManagementAccessPeriodSelectionComponent } from '@group-management-lib/components/group-management-access-period-selection/group-management-access-period-selection.component'
import { GroupManagementGeofenceSelectionComponent } from '@group-management-lib/components/group-management-geofence-selection/group-management-geofence-selection.component'
import { GroupManagementUserSelectionComponent } from '@group-management-lib/components/group-management-user-selection/group-management-user-selection.component'
import { GroupManagementWagonNumberInputComponent } from '@group-management-lib/components/group-management-wagon-number-input/group-management-wagon-number-input.component'

import {
    ParsedAccessPeriod,
    Wagon,
    WagonId,
} from '@group-management-lib/group-management.model'
import { GroupManagementOverlayService } from '@group-management-lib/group-management.overlay.service'
import {
    editGroup,
    loadMyAvailableResources,
} from '@group-management-lib/redux/group-management.actions'
import {
    selectAvailableResources,
    selectAvailableResourcesLoading,
    selectEditGroupError,
    selectEditGroupLoading,
} from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { validateGroupName } from '@group-management-lib/validators/validateGroupName'
import dayjs from '@localization-lib/date-time/dayjs'
import { defaultDate } from '@localization-lib/date-time/dayjs/dayjsHelper'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { Store } from '@ngrx/store'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { OverlayService } from '@shared-ui-lib/overlay/overlay-service'
import { SystemResponseModule } from '@shared-ui-lib/system-response/system-response.module'
import { formGroupControlsValidator } from '@shared-util-lib/validators/formGroupControls.validator'
import { TrackingCategory } from '@tracking-lib/tracking.model'
import {
    GroupManagementAction,
    GroupManagementArea,
} from '@tracking-lib/use-case-tracking-models/group-management.tracking.model'
import { deepEqual } from '@util-lib/deepEqual'
import { isTruthy } from '@util-lib/isTruthy'

import {
    BehaviorSubject,
    ReplaySubject,
    Subject,
    Subscription,
    combineLatest,
    filter,
    map,
    startWith,
    withLatestFrom,
} from 'rxjs'

@Component({
    standalone: true,
    imports: [
        ButtonModule,
        ButtonGroupModule,
        CommonModule,
        FormsModule,
        GroupManagementAccessPeriodSelectionComponent,
        GroupManagementGeofenceSelectionComponent,
        GroupManagementUserSelectionComponent,
        GroupManagementWagonNumberInputComponent,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        ReactiveFormsModule,
        SystemResponseModule,
        TranslationModule,
    ],
    selector: 'app-group-management-editor',
    templateUrl: './group-management-editor.component.html',
    styleUrls: ['./group-management-editor.component.scss'],
})
export class GroupManagementEditorComponent
    implements OnDestroy, AfterViewInit
{
    TrackingCategory = TrackingCategory
    GroupManagementArea = GroupManagementArea
    GroupManagementAction = GroupManagementAction

    subscriptions = new Subscription()

    groupId: string = ''

    savingErrorOccurred$ = new BehaviorSubject<boolean>(false)

    initialGroupData: {
        groupName: string
        period: ParsedAccessPeriod
        userIds: string[]
        wagons: Wagon[]
        geofenceIds: string[]
    } = {
        groupName: '',
        period: { beginOn: null, endOn: null },
        userIds: [],
        wagons: [],
        geofenceIds: [],
    }

    hasValueChanged$ = new BehaviorSubject<boolean>(false)

    constructor(
        private groupManagementOverlayService: GroupManagementOverlayService,
        private formBuilder: FormBuilder,
        private store: Store<GroupManagementState>,
        private overlayService: OverlayService,
        private featureFlagService: FeatureFlagsService
    ) {
        this.subscriptions.add(
            this.overlayService.overlayData$.subscribe((data) => {
                if (data && data.groupId) {
                    this.groupId = data.groupId
                    this.store.dispatch(
                        loadMyAvailableResources({ groupId: data.groupId })
                    )
                }
            })
        )

        // set initial data to check for changes
        this.subscriptions.add(
            this.groupName$.subscribe((groupName) => {
                this.initialGroupData = { ...this.initialGroupData, groupName }
                this.groupNameControl.setValue(groupName, { emitEvent: false })
            })
        )

        this.subscriptions.add(
            this.parsedAccessPeriod$.subscribe((period) => {
                this.initialGroupData = { ...this.initialGroupData, period }
                this.accessPeriodControl.setValue(period, { emitEvent: false })
            })
        )

        this.subscriptions.add(
            this.users$
                .pipe(
                    map((users) =>
                        users
                            .filter((user) => user.selected)
                            .map((user) => user.userId)
                    )
                )
                .subscribe((userIds) => {
                    this.initialGroupData = {
                        ...this.initialGroupData,
                        userIds,
                    }
                    this.userIdsControl.setValue(userIds, { emitEvent: false })
                })
        )

        this.subscriptions.add(
            this.selectedWagons$.subscribe((wagons) => {
                this.initialGroupData = { ...this.initialGroupData, wagons }
                this.wagonControl.setValue(wagons, { emitEvent: false })
            })
        )

        this.subscriptions.add(
            this.geofences$
                .pipe(
                    map((geofences) =>
                        geofences
                            .filter((geofences) => geofences.selected)
                            .map((geofence) => geofence.geofenceId)
                    )
                )
                .subscribe((geofenceIds) => {
                    this.initialGroupData = {
                        ...this.initialGroupData,
                        geofenceIds,
                    }
                    this.geofenceIdsControl.setValue(geofenceIds, {
                        emitEvent: false,
                    })
                })
        )

        this.subscriptions.add(
            this.updateGroupTrigger$
                .pipe(
                    withLatestFrom(
                        this.isDefaultGroup$,
                        this.isGroupNameChangeable$,
                        this.isAccessPeriodChangeable$,
                        this.areWagonsChangeable$,
                        this.areUsersChangeable$,
                        this.areGeofencesChangeable$
                    )
                )
                .subscribe(
                    ([
                        _,
                        isDefaultGroup,
                        isGroupNameChangeable,
                        isAccessPeriodChangeable,
                        areWagonsChangeable,
                        areUsersChangeable,
                        areGeofencesChangeable,
                    ]) => {
                        if (this.formGroup.valid) {
                            const updateValues = this.formGroup.value

                            if (isDefaultGroup !== undefined) {
                                this.store.dispatch(
                                    editGroup({
                                        groupId: this.groupId,
                                        isGroupNameChangeable,
                                        groupName: updateValues.groupName ?? '',
                                        areWagonsChangeable,
                                        selectedWagons:
                                            updateValues.wagons?.map(
                                                (wagon) =>
                                                    ({
                                                        id: wagon.wagonId,
                                                    }) as WagonId
                                            ) ?? [],
                                        areGeofencesChangeable,
                                        selectedGeofenceIds:
                                            updateValues.geofenceIds ?? [],
                                        areUsersChangeable,
                                        selectedUserIds:
                                            updateValues.userIds ?? [],
                                        isAccessPeriodChangeable,
                                        selectedAccessPeriod:
                                            updateValues.accessPeriod ?? {
                                                beginOn: null,
                                                endOn: null,
                                            },
                                        isDefaultGroup: isDefaultGroup,
                                    })
                                )
                            }
                        }
                    }
                )
        )

        this.subscriptions.add(
            this.editGroupLoading$.subscribe((editGroupLoading) =>
                editGroupLoading
                    ? this.formGroup.disable()
                    : this.formGroup.enable()
            )
        )
    }

    ngAfterViewInit(): void {
        this.hasValueChanged$.next(false)

        this.subscriptions.add(
            this.formGroup.valueChanges.subscribe((formValues) => {
                this.hasValueChanged$.next(
                    !deepEqual(
                        {
                            ...this.initialGroupData,
                            userIds: this.initialGroupData.userIds.sort(),
                            wagons: this.initialGroupData.wagons.sort(
                                (wagon1, wagon2) =>
                                    wagon1.wagonId.localeCompare(wagon2.wagonId)
                            ),
                            geofenceIds:
                                this.initialGroupData.geofenceIds.sort(),
                        },
                        {
                            // if control is disabled, it returns undefined as value
                            groupName: this.groupNameControl.enabled
                                ? formValues.groupName
                                : this.initialGroupData.groupName,
                            period: this.accessPeriodControl.enabled
                                ? formValues.accessPeriod
                                : this.initialGroupData.period,
                            userIds: this.userIdsControl.enabled
                                ? formValues.userIds?.sort()
                                : this.initialGroupData.userIds.sort(),
                            wagons: (this.wagonControl.enabled
                                ? formValues.wagons
                                : this.initialGroupData.wagons
                            )?.sort((wagon1, wagon2) =>
                                wagon1.wagonId.localeCompare(wagon2.wagonId)
                            ),
                            geofenceIds: this.geofenceIdsControl.enabled
                                ? formValues.geofenceIds?.sort()
                                : this.initialGroupData.geofenceIds.sort(),
                        }
                    )
                )
            })
        )

        this.subscriptions.add(
            this.isGroupNameChangeable$.subscribe((isGroupNameChangeable) => {
                if (isGroupNameChangeable) {
                    this.groupNameControl.enable()
                } else {
                    this.groupNameControl.disable()
                }
            })
        )

        this.subscriptions.add(
            this.isAccessPeriodChangeable$.subscribe((isPeriodChangeable) => {
                if (isPeriodChangeable) {
                    this.accessPeriodControl.enable()
                } else {
                    this.accessPeriodControl.disable()
                }
            })
        )

        this.subscriptions.add(
            this.areWagonsChangeable$.subscribe((areWagonsChangeable) => {
                if (areWagonsChangeable) {
                    this.wagonControl.enable()
                } else {
                    this.wagonControl.disable()
                }
            })
        )

        this.subscriptions.add(
            this.areGeofencesChangeable$.subscribe((areGeofencesChangeable) => {
                if (areGeofencesChangeable) {
                    this.geofenceIdsControl.enable()
                } else {
                    this.geofenceIdsControl.disable()
                }
            })
        )
    }

    private availableResources$ = this.store.select(selectAvailableResources)

    //-------------------------
    // Period
    //-------------------------

    selectedAccessPeriod$ = new ReplaySubject<ParsedAccessPeriod>(1)

    isAccessPeriodVisible$ = this.availableResources$.pipe(
        map((resources) => resources?.periodVisible)
    )

    isAccessPeriodChangeable$ = this.availableResources$.pipe(
        map((resources) => resources?.periodChangeable ?? false)
    )

    currentAccessPeriod$ = this.availableResources$.pipe(
        map((resources) => resources?.period),
        filter(isTruthy)
    )

    parsedAccessPeriod$ = this.currentAccessPeriod$.pipe(
        map(
            (period) =>
                ({
                    beginOn: period.beginOn
                        ? defaultDate(period.beginOn)
                        : null,
                    endOn: period.endOn ? defaultDate(period.endOn) : null,
                }) as ParsedAccessPeriod
        )
    )

    formattedPeriodBeginOn$ = this.parsedAccessPeriod$.pipe(
        map((parsedAccessPeriod) =>
            parsedAccessPeriod.beginOn?.format('DD.MM.YYYY')
        )
    )

    formattedPeriodEndOn$ = this.parsedAccessPeriod$.pipe(
        map((parsedAccessPeriod) =>
            parsedAccessPeriod.endOn?.format('DD.MM.YYYY')
        )
    )

    public async selectAccessPeriod(selectedAccessPeriod: ParsedAccessPeriod) {
        this.selectedAccessPeriod$.next(selectedAccessPeriod)
    }

    //-------------------------
    // Group
    //-------------------------

    isDefaultGroup$ = this.availableResources$.pipe(
        map((resources) => resources?.defaultGroup)
    )

    isGroupNameChangeable$ = this.availableResources$.pipe(
        map((resources) => resources?.groupNameChangeable ?? false)
    )

    groupName$ = this.availableResources$.pipe(
        map((resources) => resources?.groupName),
        filter(isTruthy)
    )

    isActiveGroup$ = this.parsedAccessPeriod$.pipe(
        map((period) =>
            dayjs().isBetween(
                period?.beginOn ?? dayjs().subtract(1, 'day'),
                period?.endOn ?? dayjs().add(1, 'day')
            )
        ),
        startWith(true)
    )

    isGroupEmpty$ = this.availableResources$.pipe(
        map((resources) => resources?.resources.wagons.length === 0)
    )

    deleteGroup() {
        if (!this.initialGroupData.groupName || !this.groupId) return

        this.groupManagementOverlayService.openDeleteConfirmDialog(
            this.initialGroupData.groupName,
            this.groupId
        )
    }

    //-------------------------
    // User
    //-------------------------

    usersVisible$ = this.availableResources$.pipe(
        map((resources) => resources?.usersVisible),
        filter(isTruthy)
    )

    users$ = this.availableResources$.pipe(
        map((resources) => resources?.users),
        filter(isTruthy)
    )

    areUsersChangeable$ = this.availableResources$.pipe(
        map(
            (resources) =>
                resources?.users.findIndex((user) => user.changeable) !== -1
        )
    )

    //-------------------------
    // Wagons
    //-------------------------

    areWagonsChangeable$ = this.availableResources$.pipe(
        map((resources) => resources?.resources?.wagonsChangeable ?? false)
    )

    selectedWagons$ = this.availableResources$.pipe(
        map(
            (resources) =>
                resources?.resources.wagons.filter((wagon) => wagon.selected) ??
                []
        )
    )

    //-------------------------
    // Geofences
    //-------------------------

    areGeofencesChangeable$ = this.availableResources$.pipe(
        map((resources) => resources?.resources?.geofencesChangeable ?? false)
    )

    geofences$ = this.availableResources$.pipe(
        map((resources) => resources?.resources.geofences),
        filter(isTruthy)
    )

    numberOfSelectedGeofences$ = this.geofences$.pipe(
        map(
            (geofences) =>
                geofences.filter((geofence) => geofence.selected).length
        )
    )

    //-------------------------
    // Component states
    //-------------------------

    loading$ = this.store.select(selectAvailableResourcesLoading)

    editGroupLoading$ = this.store.select(selectEditGroupLoading)

    editGroupError$ = this.store.select(selectEditGroupError)

    //-------------------------
    // Form
    //-------------------------

    groupNameControl = this.formBuilder.control<string>('', [
        Validators.required,
        validateGroupName(),
    ])

    accessPeriodControl = this.formBuilder.control<ParsedAccessPeriod>({
        beginOn: null,
        endOn: null,
    })

    userIdsControl = this.formBuilder.control<string[]>([])

    wagonControl = this.formBuilder.control<Wagon[]>([])

    geofenceIdsControl = this.formBuilder.control<string[]>([])

    formGroup = this.formBuilder.group(
        {
            groupName: this.groupNameControl,
            accessPeriod: this.accessPeriodControl,
            userIds: this.userIdsControl,
            wagons: this.wagonControl,
            geofenceIds: this.geofenceIdsControl,
        },
        {
            validators: formGroupControlsValidator([
                this.groupNameControl,
                this.accessPeriodControl,
                this.userIdsControl,
                this.wagonControl,
                this.geofenceIdsControl,
            ]),
        }
    )

    updateGroupTrigger$ = new Subject<void>()

    handleFormSubmit() {
        this.updateGroupTrigger$.next()
    }

    changeable$ = combineLatest([
        this.isGroupNameChangeable$,
        this.isAccessPeriodChangeable$,
        this.areUsersChangeable$,
        this.areWagonsChangeable$,
        this.areGeofencesChangeable$,
    ]).pipe(map((allChangeables) => allChangeables.some((element) => element)))

    //-------------------------
    // functions
    //-------------------------

    requestClose() {
        this.groupManagementOverlayService.closeLayer(
            !this.hasValueChanged$.getValue()
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
