<app-page>
    <ng-container [ngSwitch]="language$ | async">
        <app-privacy-content-de
            *ngSwitchCase="Language.DE"
        ></app-privacy-content-de>
        <app-privacy-content-en *ngSwitchDefault></app-privacy-content-en>
    </ng-container>
    <div class="ml-auto text-primary flex justify-end pt-8">
        <app-back-to-top></app-back-to-top>
    </div>
</app-page>
