import { Dayjs } from 'dayjs'

export interface Claims {
    auth_time: number
    'cognito:username': string
    consents: string
    first_name: string
    exp?: number
    iat?: number // apparently an acronym for initiatedAt
    impersonation_label?: string
    is_authorized_for_traigo: string
    last_name: string
    permitted_actions: string
    group_ids: string
    client_id: string
    client_configs: string
}

export const Unprotected = 'UNPROTECTED'

export enum Action {
    ShowClientManagement = 'SERVICE_DESK',
    ShowContracts = 'CONTRACT',
    ShowDispatchManagement = 'DISPATCH',
    ShowEta = 'ETA',
    ShowFastTrackAdmin = 'FASTTRACK:ADMIN',
    ShowFastTrackUser = 'FASTTRACK:USER',
    ShowFleet = 'FLEET',
    ShowFleetMileagePrediction = 'FLEET:MILEAGE:PREDICTION',
    ShowPerformance = 'PERFORMANCE',
    ShowSalesCare = 'SALES_CARE',
    ShowUserManagement = 'USER',
    ShowWagonTracking = 'TRACKING',
    ShowWagonHire = 'WAGON_RENTAL',
    ShowAdminCenter = 'TRAIGO_ADMIN',
    PreventTracking = 'TRACKING_PREVENTION',
}

export enum ClientConfig {
    EnableGroupAccessControl = 'GROUP_MANAGEMENT_AUTHORIZATION',
}

export interface ClientInformation {
    clientId: string
    clientConfigs: ClientConfig[]
}

export interface PermittedAction {
    action: Action
    activeSince?: Dayjs
}

export type ActionsMap = {
    [action in Action]?: boolean
}
