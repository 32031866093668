import { CommonModule } from '@angular/common'
import { Component, Input, forwardRef } from '@angular/core'
import {
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatTooltipModule } from '@angular/material/tooltip'
import { User } from '@group-management-lib/group-management.model'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { BaseControlValueAccessor } from '@shared-ui-lib/base-control-value-accessor/base-control-value-accessor.component'
import { TrackingCategory } from '@tracking-lib/tracking.model'
import { TrackingService } from '@tracking-lib/tracking.service'
import {
    GroupManagementAction,
    GroupManagementArea,
} from '@tracking-lib/use-case-tracking-models/group-management.tracking.model'
import { BehaviorSubject, Subscription, map } from 'rxjs'

@Component({
    selector: 'app-group-management-user-selection',
    templateUrl: './group-management-user-selection.component.html',
    styleUrls: ['./group-management-user-selection.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatCheckboxModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslationModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(
                () => GroupManagementUserSelectionComponent
            ),
            multi: true,
        },
    ],
})
export class GroupManagementUserSelectionComponent extends BaseControlValueAccessor<
    string[]
> {
    selectedUsers$ = new BehaviorSubject<User[]>([])
    @Input()
    set users(value: User[] | null) {
        if (value) {
            this.selectedUsers$.next(
                value.sort((a, b) => {
                    const result = a.firstName.localeCompare(b.firstName)
                    return result !== 0
                        ? result
                        : a.lastName.localeCompare(b.lastName)
                })
            )
        }
    }

    trackingArea?: GroupManagementArea
    @Input()
    set setTrackingArea(area: GroupManagementArea) {
        this.trackingArea = area
    }

    subscriptions = new Subscription()

    constructor(private trackingService: TrackingService) {
        super()

        this.subscriptions.add(
            this.selectedUsers$.subscribe((selectedUsers) => {
                if (!this.disabled) {
                    const selectedUserIds = selectedUsers
                        .filter((user) => user.selected)
                        .map((user) => user.userId)
                    this.value = selectedUserIds
                    this.onChanged(selectedUserIds)
                    this.onTouched()
                }
            })
        )
    }

    someSelected$ = this.selectedUsers$.pipe(
        map((users) => {
            const numberOfAllUsers = users.length
            const numberOfSelectedUsers = users.filter(
                (user) => user.selected
            ).length
            return (
                numberOfSelectedUsers > 0 &&
                numberOfSelectedUsers !== numberOfAllUsers
            )
        })
    )

    allSelected$ = this.selectedUsers$.pipe(
        map(
            (users) =>
                users.length === users.filter((user) => user.selected).length
        )
    )

    selectAllDisabled$ = this.selectedUsers$.pipe(
        map((users) => !users.find((user) => user.changeable))
    )

    allCheckboxesDisabled$ = new BehaviorSubject<boolean>(false)

    trackingCounter = 0

    onAnyCheckBoxClick() {
        if (this.trackingCounter === 0 && this.trackingArea) {
            this.trackingCounter = 1
            this.trackingService.track({
                category: TrackingCategory.groupManagement,
                area: this.trackingArea,
                action: GroupManagementAction.groupUsersSelected,
            })
        }
    }

    onCheckBoxClick(userId: string, checked: boolean) {
        const currentUsers = this.selectedUsers$.getValue()
        this.selectedUsers$.next(
            currentUsers.map((user) => ({
                ...user,
                selected: user.userId === userId ? checked : user.selected,
            }))
        )
    }

    onSelectAllClick(checked: boolean) {
        const currentUsers = this.selectedUsers$.getValue()
        this.selectedUsers$.next(
            currentUsers.map((user) => ({
                ...user,
                selected: checked,
            }))
        )
    }

    public setDisabledState(disabled: boolean): void {
        this.allCheckboxesDisabled$.next(disabled)
    }
}
