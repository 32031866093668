export enum DashboardArea {
    widgetWelcome = 'widgetWelcome',
    widgetFleetStatus = 'widgetFleetStatus',
    widgetFastTrack = 'widgetFastTrack',
    widgetContracts = 'widgetContracts',
    widgetMileage = 'widgetMileage',
    widgetWagonTracking = 'widgetWagonTracking',
    widgetIdleTimesSummary = 'widgetIdleTimesSummary',
    widgetConnectService = 'widgetConnectService',
    widgetNews = 'widgetNews',
    arrangeWidgets = 'arrangeWidgets',
}

export enum DashboardAction {
    bookAppointmentClicked = 'bookAppointmentClicked',
    editAppointmentClicked = 'editAppointmentClicked',
    cancelAppointmentClicked = 'cancelAppointmentClicked',
    dateSelected = 'dateSelected',
    timeSelected = 'timeSelected',
    cancelClicked = 'cancelClicked',
    sendClicked = 'sendClicked',
    closeDialogClicked = 'closeDialogClicked',
    updateDateSelected = 'updateDateSelected',
    updateTimeSelected = 'updateTimeSelected',
    confirmKeepAppointmentClicked = 'confirmKeepAppointmentClicked',
    confirmUpdateAppointmentClicked = 'confirmUpdateAppointmentClicked',
    confirmCancelAppointmentClicked = 'confirmCancelAppointmentClicked',
    feedbackClicked = 'feedbackClicked',
    feedbackDialogCanceled = 'feedbackDialogCanceled',
    feedbackDialogSend = 'feedbackDialogSend',
    feedbackDialogClosed = 'feedbackDialogClosed',
    widgetClicked = 'widgetClicked',
    quickFilterClicked = 'quickFilterClicked',
    buttonClicked = 'buttonClicked',
    widgetsMoved = 'widgetsMoved',
    inactiveConnectorsClicked = 'inactiveConnectorsClicked',
}
