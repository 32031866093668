export enum EtaArea {
    etaCalculation = 'etaCalculation',
}

export enum EtaAction {
    calculateClicked = 'calculateClicked',
    dateSelected = 'dateSelected',
    departureSelected = 'departureSelected',
    destinationSelected = 'destinationSelected',
    resetClicked = 'resetClicked',
    timeSelected = 'timeSelected',
}
