import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { InlineMessageType } from '@shared-ui-lib/inline-message/inline-message.model'
import { BehaviorSubject, map } from 'rxjs'

@Component({
    selector: 'app-inline-message',
    templateUrl: './inline-message.component.html',
    styleUrls: ['./inline-message.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule],
})
export class InlineMessageComponent {
    inlineMessageType$ = new BehaviorSubject<InlineMessageType | null>(null)
    @Input({ required: true })
    set inlineMessageType(type: InlineMessageType) {
        this.inlineMessageType$.next(type)
    }

    icon$ = this.inlineMessageType$.pipe(
        map((responseType) => {
            switch (responseType) {
                case InlineMessageType.WARNING:
                    return 'warning'
                case InlineMessageType.SUCCESS:
                    return 'check-filled'
                case InlineMessageType.INFO:
                    return 'info'
                default:
                    return null
            }
        })
    )
}
