import { Injectable } from '@angular/core'
import { GroupManagementEditorComponent } from '@group-management-lib/components/group-management-editor/group-management-editor.component'
import { GroupManagementQuitDialogComponent } from '@group-management-lib/components/group-management-quit-dialog/group-management-quit-dialog.component'
import { deleteGroup } from '@group-management-lib/redux/group-management.actions'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { Store } from '@ngrx/store'
import { DialogService } from '@shared-ui-lib/dialog/dialog.service'
import { OverlayService } from '@shared-ui-lib/overlay/overlay-service'
import { TrackingCategory } from '@tracking-lib/tracking.model'
import { TrackingService } from '@tracking-lib/tracking.service'
import {
    GroupManagementAction,
    GroupManagementArea,
} from '@tracking-lib/use-case-tracking-models/group-management.tracking.model'
import { GroupManagementCreatorComponent } from './components/group-management-creator/group-management-creator.component'

@Injectable({
    providedIn: 'root',
})
export class GroupManagementOverlayService {
    constructor(
        private overlayService: OverlayService,
        private store: Store<GroupManagementState>,
        private dialogService: DialogService,
        private trackingService: TrackingService
    ) {}

    openLayer(groupId?: string) {
        if (groupId) {
            this.overlayService.openAnimatedLayer(
                GroupManagementEditorComponent,
                { groupId },
                {
                    title: 'Quit editing?',
                    bodyText: 'Your changes will not be saved.',
                    actionButtonText: 'Quit',
                    actionButtonTracking: {
                        category: TrackingCategory.groupManagement,
                        area: GroupManagementArea.quitDialog,
                        action: GroupManagementAction.quitted,
                    },
                    cancelButtonText: 'Continue editing',
                    cancelButtonTracking: {
                        category: TrackingCategory.groupManagement,
                        area: GroupManagementArea.quitDialog,
                        action: GroupManagementAction.continued,
                    },
                }
            )
        } else {
            this.overlayService.openAnimatedLayer(
                GroupManagementCreatorComponent,
                undefined,
                {
                    title: 'Quit creating a new group?',
                    bodyText: 'The group will not be created.',
                    actionButtonText: 'Quit',
                    actionButtonTracking: {
                        category: TrackingCategory.groupManagement,
                        area: GroupManagementArea.quitDialog,
                        action: GroupManagementAction.quitted,
                    },
                    cancelButtonText: 'Continue creating',
                    cancelButtonTracking: {
                        category: TrackingCategory.groupManagement,
                        area: GroupManagementArea.quitDialog,
                        action: GroupManagementAction.continued,
                    },
                }
            )
        }
    }

    closeLayer(forceClose = false) {
        this.overlayService.closeLayer(forceClose)
    }

    openDeleteConfirmDialog(groupName: string, groupId: string) {
        this.dialogService
            .openDialog(GroupManagementQuitDialogComponent, {
                title: `Delete $$groupName$$ for you and your colleagues?`,
                body: 'GroupManagementDeleteDialog',
                quitButton: 'Delete for everyone',
                continueButton: 'Cancel',
                groupName: groupName,
            })
            .afterClosed()
            .subscribe((deleteConfirmed: boolean) => {
                if (deleteConfirmed) {
                    this.trackingService.track({
                        category: TrackingCategory.groupManagement,
                        area: GroupManagementArea.deleteDialog,
                        action: GroupManagementAction.confirmed,
                    })
                    this.store.dispatch(
                        deleteGroup({
                            groupId: groupId,
                        })
                    )
                    this.closeLayer(true)
                } else {
                    this.trackingService.track({
                        category: TrackingCategory.groupManagement,
                        area: GroupManagementArea.deleteDialog,
                        action: GroupManagementAction.canceled,
                    })
                }
            })
    }
}
