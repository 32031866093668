<div class="flex flex-row flex-0 items-center px-6 py-2">
    <translate class="text-subtitle-1 flex-1">New Group</translate>
    <app-button
        buttonType="ghost"
        class="close-dialog"
        iconButton
        icon="close"
        [trackingData]="{
            category: TrackingCategory.groupManagement,
            area: GroupManagementArea.creator,
            action: GroupManagementAction.closeClicked,
        }"
        (buttonClick)="requestClose()"
    ></app-button>
</div>

@if (loading$ | async) {
    <div class="flex flex-col flex-1 justify-between">
        <div class="p-6">
            <div class="flex flex-col gap-2">
                <div class="skeleton-loading w-[336px] h-7"></div>
                <div class="skeleton-loading w-[336px] h-[60px] mb-12"></div>
                <div class="skeleton-loading w-162 h-7"></div>
                <div class="skeleton-loading w-162 h-7"></div>
                <div class="skeleton-loading w-162 h-[60px]"></div>
            </div>
        </div>
    </div>
} @else if (savingErrorOccurred$ | async) {
    <app-system-response
        small
        responseType="error"
        title="CreateGroupError"
    ></app-system-response>
} @else {
    <div class="flex flex-col flex-1 overflow-auto">
        <form [formGroup]="formGroup">
            <mat-stepper
                #stepper
                (selectionChange)="resetSteps(stepper)"
                (selectedIndexChange)="stepIndex.next(stepper.selectedIndex)"
            >
                <mat-step>
                    <ng-template matStepLabel>
                        <translate>Basics</translate>
                    </ng-template>

                    <div
                        class="flex flex-col px-8 border-t border-0 border-t-accent-light border-solid"
                    >
                        <span translate class="text-body-3 py-4">Name</span>
                        <mat-form-field appearance="outline" class="w-80 pb-6">
                            <mat-label translate>Set a group name</mat-label>
                            <input
                                matInput
                                required
                                [placeholder]="'Set a group name' | translate"
                                formControlName="groupName"
                                [maxLength]="30"
                            />
                            <mat-error
                                *ngIf="
                                    groupNameControl?.errors?.required ||
                                    groupNameControl?.errors?.validateGroupName
                                "
                                translate
                            >
                                Please provide a group name
                            </mat-error>
                        </mat-form-field>
                        @if (isAccessPeriodVisible$ | async) {
                            <app-group-management-access-period-selection
                                [formControl]="accessPeriodControl"
                                [setTrackingArea]="GroupManagementArea.creator"
                            >
                            </app-group-management-access-period-selection>
                        }
                        @if (usersVisible$ | async) {
                            <app-group-management-user-selection
                                [users]="users$ | async"
                                [formControl]="userIdsControl"
                                [setTrackingArea]="GroupManagementArea.creator"
                            ></app-group-management-user-selection>
                        }
                    </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>
                        <translate>Wagons</translate>
                    </ng-template>
                    <div
                        class="border-t border-0 border-t-accent-light border-solid"
                    >
                        <app-group-management-wagon-number-input
                            [formControl]="wagonControl"
                            [setTrackingArea]="GroupManagementArea.creator"
                        ></app-group-management-wagon-number-input>
                    </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>
                        <translate>Geofences</translate>
                    </ng-template>
                    <div
                        class="border-t border-0 border-t-accent-light border-solid"
                    >
                        <app-group-management-geofence-selection
                            [geofences]="geofences$ | async"
                            [formControl]="geofenceIdsControl"
                            [trackingArea]="GroupManagementArea.creator"
                        ></app-group-management-geofence-selection>
                    </div>
                </mat-step>
            </mat-stepper>
        </form>
    </div>
    <mat-divider />
    <div class="flex flex-col flex-0 p-6">
        <app-button-group
            [alignment]="(hasPreviousStep$ | async) ? 'space-between' : 'right'"
        >
            @if (hasPreviousStep$ | async) {
                <app-button
                    class="mr-4"
                    buttonType="basic-primary"
                    [trackingData]="{
                        category: TrackingCategory.groupManagement,
                        area: GroupManagementArea.creator,
                        action: GroupManagementAction.backClicked,
                    }"
                    (buttonClick)="stepper.previous()"
                    disabled
                >
                    <translate>Back</translate>
                </app-button>
            }

            @if (hasNextStep$ | async) {
                <app-button
                    class="ml-4"
                    buttonType="flat-primary"
                    [trackingData]="{
                        category: TrackingCategory.groupManagement,
                        area: GroupManagementArea.creator,
                        action: GroupManagementAction.nextClicked,
                    }"
                    [disabled]="formGroup.errors !== null"
                    (buttonClick)="stepper.next()"
                >
                    <translate>Next</translate>
                </app-button>
            } @else {
                <app-button
                    buttonType="flat-primary"
                    (buttonClick)="handleFormSubmit()"
                    [trackingData]="{
                        category: TrackingCategory.groupManagement,
                        area: GroupManagementArea.creator,
                        action: GroupManagementAction.createClicked,
                    }"
                    [disabled]="disableCreateButton()"
                    [status]="
                        (createGroupLoading$ | async) || (loading$ | async)
                            ? 'loading'
                            : 'default'
                    "
                >
                    <translate>Create</translate>
                </app-button>
            }
        </app-button-group>
    </div>
}
