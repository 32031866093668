import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { ButtonGroupModule } from '@shared-ui-lib/button-group/button-group.module'
import { ButtonModule } from '@shared-ui-lib/button/button.module'
import { DialogWrapperComponent } from '@shared-ui-lib/dialog/dialog-wrapper.component'
import { DialogData } from '@shared-ui-lib/dialog/dialog.model'

@Component({
    standalone: true,
    imports: [
        ButtonGroupModule,
        ButtonModule,
        CommonModule,
        DialogWrapperComponent,
        TranslationModule,
    ],
    selector: 'app-overlay-close-dialog',
    templateUrl: './overlay-close-dialog.component.html',
})
export class OverlayCloseDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<OverlayCloseDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: DialogData
    ) {}

    onCancelClick() {
        this.dialogRef.close({ actionButtonClicked: false })
    }

    onCloseClick() {
        this.dialogRef.close({ actionButtonClicked: true })
    }
}
