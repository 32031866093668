import { Stage } from '@env-lib/stage'
import { ApiConfigModel } from './api-config.model'

const baseUrl = 'https://dev.traigo.com'
const authDomain = 'https://traigo-dev.auth.eu-central-1.amazoncognito.com'
const clientId = '4l5d79ehpek15t8785nvkfs0t8'
const cognitoPoolId = 'eu-central-1_tog4jZ28Q'
const adLogoutUrl = `https://vtgb2c.b2clogin.com/vtgb2c.onmicrosoft.com/B2C_1_Traigo_SignIn_Dev/oauth2/v2.0/logout?post_logout_redirect_uri=${baseUrl}/logout`

export const apiConfig: ApiConfigModel = {
    account: {
        backendUrl: 'https://auth.dev.traigo.com',
    },
    assets: { backendUrl: 'https://assets.dev.traigo.com' },
    auth: {
        backendUrl: 'https://auth.dev.traigo.com',
        clientId,
        cognitoLogoutUrl: `${authDomain}/logout?client_id=${clientId}&logout_uri=${adLogoutUrl}`,
        issuer: `https://cognito-idp.eu-central-1.amazonaws.com/${cognitoPoolId}`,
        loginUrl: `${authDomain}/oauth2/authorize`,
        redirectUrl: `${baseUrl}/auth`,
        tokenEndpoint: `${authDomain}/oauth2/token`,
    },
    connectEvent: {
        backendUrl: 'https://connect-event.dev.traigo.com',
    },
    contracts: {
        backendUrl: 'https://contracts.dev.traigo.com',
    },
    dashboard: {
        backendUrl: 'https://dashboard.dev.traigo.com',
    },
    dataForwarding: {
        backendUrl: 'https://message-relay.dev.traigo.com',
    },
    dispatch: {
        backendUrl: 'https://dispatch.dev.traigo.com',
    },
    fastTrack: {
        backendUrl: 'https://fasttrack.dev.traigo.com',
    },
    fleet: {
        backendUrl: 'https://fleet.dev.traigo.com',
    },
    performance: {
        backendUrl: 'https://performance.dev.traigo.com',
    },
    salesCare: {
        backendUrl: 'https://sales-care.dev.traigo.com',
    },
    launchDarkly: {
        clientSideId: '5e5e65cb9e25d309ca9fde4b',
        useStreaming: true,
    },
    releasenotes: {
        backendUrl: 'https://releasenotes.dev.traigo.com',
    },
    datadog: {
        environmentName: Stage.Dev,
    },
    transport: {
        backendUrl: 'https://transport.dev.traigo.com',
    },
    e2eEta: {
        backendUrl: 'https://e2e-eta.dev.traigo.com',
    },
    userInterest: {
        backendUrl: 'https://user-interest.dev.traigo.com',
    },
    wagon: {
        backendUrl: 'https://wagon.dev.traigo.com',
    },
    pathfinder: {
        backendUrl: 'https://pathfinder.dev.traigo.com',
    },
    virtuwall: {
        backendUrl: 'https://virtuwall.dev.traigo.com',
    },
    wagonHire: {
        backendUrl: 'https://wagon-rental.dev.traigo.com',
    },
    notification: {
        backendUrl: 'https://notification.dev.traigo.com',
    },
    sensor: {
        backendUrl: 'https://sensor.dev.traigo.com',
    },
}
