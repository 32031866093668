export enum WagonDetailsArea {
    documentsTab = 'documentsTab',
    maintenanceRepair = 'maintenanceRepair',
    mileageTab = 'mileageTab',
    sensorTab = 'sensorTab',
    wagonData = 'wagonData',
    wagonTrackingTab = 'wagonTrackingTab',
}

export enum WagonDetailsAction {
    contractClicked = 'contractClicked',
    dataPeriodSelected = 'dataPeriodSelected',
    dataPersiodSelected = 'dataPersiodSelected',
    documentDownloaded = 'documentDownloaded',
    missingDataButtonClicked = 'missingDataButtonClicked',
    quickFilterClicked = 'quickFilterClicked',
    refreshClicked = 'refreshClicked',
    reloadClicked = 'reloadClicked',
    sensorDataCollapsed = 'sensorDataCollapsed',
    sensorDataExpanded = 'sensorDataExpanded',
    tabClicked = 'tabClicked',
    tableFilterApplied = 'tableFilterApplied',
    tableFilterReset = 'tableFilterReset',
    tableFilterSorted = 'tableFilterSorted',
    tableHeaderNameClicked = 'tableHeaderNameClicked',
    tableResetClicked = 'tableResetClicked',
    yearSwitcherClicked = 'yearSwitcherClicked',
}
