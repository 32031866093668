<div
    class="sidebar-container scrollbar-primary"
    [@slideContentLeft]="animationState"
    (@slideContentLeft.start)="onAnimationStart($event)"
    (@slideContentLeft.done)="onAnimationDone($event)"
>
    <div class="logo-container">
        <a
            class="logo-link"
            [routerLink]="['/']"
            clickTracking
            [trackingCategory]="TrackingCategory.navigation"
            [trackingArea]="NavigationArea.menu"
            [trackingAction]="NavigationAction.traigoLogoClicked"
        >
            <img src="assets/images/traigo-logo.svg" />
        </a>
    </div>
    <ng-container
        *ngFor="
            let entries of entriesList$ | async | keyvalue: originalOrder;
            index as i
        "
    >
        <mat-divider *ngIf="entries.value.length" class="divider"></mat-divider>

        <p class="title">{{ entries.key }}</p>

        <div class="flex flex-col">
            <!-- tabindex is neccessary so the first button will not be marked initially, for further info look into mr242 -->
            <ng-container *ngFor="let entry of entries.value; index as j">
                <a
                    *ngIf="!entry?.external; else externalLink"
                    class="entry text-button"
                    routerLinkActive="selected"
                    [routerLink]="[entry.route]"
                    [target]="entry?.target"
                    [tabindex]="i === 0 && j === 0 ? 1 : (i + 1) * 100 + j + 1"
                    [autofocus]="i === 0 && j === 0 ? true : false"
                    clickTracking
                    [trackingCategory]="TrackingCategory.navigation"
                    [trackingArea]="NavigationArea.menu"
                    [trackingAction]="NavigationAction.useCaseClicked"
                    [trackingAttributes]="{ useCase: entry.label }"
                >
                    {{ entry.translatedLabel }}
                </a>
                <ng-template #externalLink>
                    <a
                        class="entry text-button"
                        [href]="[entry.route]"
                        [target]="entry?.target"
                        [tabindex]="(i + 1) * 100 + j"
                    >
                        {{ entry.translatedLabel }}
                        <mat-icon
                            class="text-body-1 text-current leading-none h-4 w-4 ml-2"
                            svgIcon="new-tab"
                        >
                        </mat-icon>
                    </a>
                </ng-template>
            </ng-container>
        </div>
    </ng-container>
</div>
