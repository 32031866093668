import { NgModule } from '@angular/core'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { PrivacyContentDeComponent } from '@platform-lib/legal/privacy/privacy-content/privacy-content-de.component'
import { PrivacyContentEnComponent } from '@platform-lib/legal/privacy/privacy-content/privacy-content-en.component'
import { PrivacyContentComponent } from '@platform-lib/legal/privacy/privacy-content/privacy-content.component'
import { SnackbarModule } from '@shared-ui-lib/snackbar/snackbar.module'

@NgModule({
    declarations: [
        PrivacyContentComponent,
        PrivacyContentDeComponent,
        PrivacyContentEnComponent,
    ],
    exports: [PrivacyContentDeComponent, PrivacyContentEnComponent],
    imports: [MatSnackBarModule, SnackbarModule, TranslationModule],
})
export class PrivacyContentModule {}
