export const CONSOLE_ORANGE =
    'background: #ffc300; color: #000; padding: 4px; border-radius: 2px;'

export const CONSOLE_GREY =
    'background: #ddd; color: #000; padding: 4px; border-radius: 2px;'

export const CONSOLE_GREEN =
    'background: #6f6; color: #000; padding: 4px; border-radius: 2px;'

export const CONSOLE_YELLOW =
    'background: #ff0; color: #000; padding: 4px; border-radius: 2px;'

export const CONSOLE_RED =
    'background: #f00; color: #000; padding: 4px; border-radius: 2px;'

export const CONSOLE_BLUE =
    'background: #5dade2; color: #fff; padding: 4px; border-radius: 2px;'
