export enum WagonTrackingArea {
    createGeofenceModal = 'createGeofenceModal',
    editGeofenceModal = 'editGeofenceModal',
    eventsTab = 'eventsTab',
    filter = 'filter',
    geofenceDetailPanel = 'geofenceDetailPanel',
    geofencesTab = 'geofencesTab',
    map = 'map',
    search = 'search',
    sidePanel = 'sidePanel',
    wagonDetailPanel = 'wagonDetailPanel',
    wagonsTab = 'wagonsTab',
}

export enum WagonTrackingAction {
    addClicked = 'addClicked',
    closeClicked = 'closeClicked',
    closeConfirmed = 'closeConfirmed',
    collapsibleOpened = 'collapsibleOpened',
    contractNumberClicked = 'contractNumberClicked',
    createClicked = 'createClicked',
    deleteClicked = 'deleteClicked',
    deleteConfirmed = 'deleteConfirmed',
    detailPageOpened = 'detailPageOpened',
    editClicked = 'editClicked',
    eventItemClicked = 'eventItemClicked',
    eventsTabClicked = 'eventsTabClicked',
    filterApplied = 'filterApplied',
    filterDateSet = 'filterDateSet',
    filterModalOpened = 'filterModalOpened',
    geofenceClicked = 'geofenceClicked',
    geofenceInfoIconClicked = 'geofenceInfoIconClicked',
    geofenceItemClicked = 'geofenceItemClicked',
    geofenceResultClicked = 'geofenceResultClicked',
    geofencesTabClicked = 'geofencesTabClicked',
    geofenceTemplateSelected = 'geofenceTemplateSelected',
    infoModalOpened = 'infoModalOpened',
    journeyDateSet = 'journeyDateSet',
    panelClosed = 'panelClosed',
    resetClicked = 'resetClicked',
    saveClicked = 'saveClicked',
    searchFieldClicked = 'searchFieldClicked',
    showGeofenceDetailsClicked = 'showGeofenceDetailsClicked',
    showWagonsClicked = 'showWagonsClicked',
    valueSelected = 'valueSelected',
    wagonClusterClicked = 'wagonClusterClicked',
    wagonItemClicked = 'wagonItemClicked',
    wagonPinClicked = 'wagonPinClicked',
    wagonResultClicked = 'wagonResultClicked',
    wagonsTabClicked = 'wagonsTabClicked',
}
