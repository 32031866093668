import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { DateInDefaultTimezonePipeModule } from '@localization-lib/date-time/pipes/date-in-default-timezone/date-in-default-timezone-pipe.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { SkeletonModule } from '@shared-util-lib/directives/skeleton/skeleton.module'
import { NotificationItemComponent } from './notification-item.component'
import { TrackingClickDirective } from '@tracking-lib/tracking-click/tracking-click.directive'

@NgModule({
    declarations: [NotificationItemComponent],
    exports: [NotificationItemComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatTooltipModule,

        DateInDefaultTimezonePipeModule,
        SkeletonModule,
        TranslationModule,
        TrackingClickDirective,
    ],
})
export class NotificationItemModule {}
