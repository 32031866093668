export enum FeedbackArea {
    feedbackDialog = 'feedbackDialog',
}

export enum FeedbackAction {
    dialogOpened = 'dialogOpened',
    cancelClicked = 'cancelClicked',
    ratingClicked = 'ratingClicked',
    textChanged = 'textChanged',
    sendClicked = 'sendClicked',
    successCloseClicked = 'successCloseClicked',
}
