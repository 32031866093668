<div class="h-20 flex pl-6 pr-2 items-center justify-between">
    <translate class="text-subtitle-1">Groups</translate>
    <app-button
        iconButton
        icon="settings"
        [matTooltip]="'Group Management' | translate"
        matTooltipClass="tooltip"
        (buttonClick)="openSettings()"
    ></app-button>
</div>

<mat-divider></mat-divider>

<ng-container *ngIf="loading$ | async; else error">
    <div class="p-4">
        <div class="h-5 w-full skeleton-loading mb-4"></div>
        <div class="h-5 w-[66%] skeleton-loading mb-4"></div>
        <div class="h-5 w-[33%] skeleton-loading mb-2"></div>
    </div>
</ng-container>

<ng-template #error>
    <ng-container *ngIf="groupsLoadingError$ | async; else groups">
        <app-system-response
            class="pb-4"
            responseType="error"
            small
            title="GroupManagementLoadGroupsError"
        >
        </app-system-response>
    </ng-container>
</ng-template>

<ng-template #groups>
    <ng-container *ngIf="filteredGroups$ | async as groups; else emptyState">
        <mat-form-field
            *ngIf="showSearchField$ | async"
            appearance="outline"
            class="pl-4 pr-4 pt-4 w-[331px]"
        >
            <mat-icon matPrefix class="search-icon" svgIcon="search"></mat-icon>
            <input
                [formControl]="searchControl"
                matInput
                type="text"
                appAutofocus="true"
            />
            <mat-label translate>Search</mat-label>
            <app-button
                *ngIf="searchControl.value !== ''"
                matSuffix
                aria-label="clear search input"
                icon="close"
                (buttonClick)="onClearButtonClicked()"
                iconButton
            >
            </app-button>
        </mat-form-field>

        <div class="flex flex-col pl-4 pb-4 pt-1 pr-2 scrollbar group-content">
            <ng-container *ngFor="let group of groups">
                <div class="flex items-center justify-between">
                    <mat-checkbox
                        disableRipple
                        color="primary"
                        [checked]="group.isSelected"
                        [attr.data-qa]="
                            'top-menu_groups_dropdown-menu_group-name-checkbox_' +
                                group.name | dataQaTag
                        "
                        (change)="toggleGroup(group.id)"
                        ><span>{{ group.name }}</span></mat-checkbox
                    >
                    <app-button
                        *ngIf="
                            isGroupManagementAllowed$ | async;
                            else groupDetailsButton
                        "
                        iconButton
                        icon="edit"
                        buttonType="ghost"
                        (buttonClick)="viewGroupDetails(group.id)"
                    ></app-button>
                    <ng-template #groupDetailsButton>
                        <app-button
                            iconButton
                            icon="chevron-right"
                            buttonType="ghost"
                            (buttonClick)="viewGroupDetails(group.id)"
                        ></app-button>
                    </ng-template>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="isGroupManagementAllowed$ | async">
            <mat-divider></mat-divider>

            <app-button
                buttonType="basic-primary"
                icon="plus"
                iconPosition="left"
                (buttonClick)="createNewGroup()"
            >
                <translate>New group</translate>
            </app-button>

            <mat-divider></mat-divider>
        </ng-container>

        <app-button-group alignment="right" class="p-4">
            <app-button
                buttonType="basic-primary"
                [disabled]="!isAnyGroupSelected"
                (buttonClick)="handleReset()"
                [trackingData]="{
                    category: TrackingCategory.groupManagement,
                    area: GroupManagementArea.overlay,
                    action: GroupManagementAction.reset,
                }"
            >
                <translate>Reset</translate>
            </app-button>
            <app-button
                buttonType="flat-primary"
                (buttonClick)="applyChanges()"
                [disabled]="
                    hasGroupSelectionChanged &&
                    (groupSelectionChangeError$ | async) === null
                "
                [status]="
                    (groupSelectionChangeLoading$ | async)
                        ? 'loading'
                        : 'default'
                "
                [trackingData]="{
                    category: TrackingCategory.groupManagement,
                    area: GroupManagementArea.overlay,
                    action: GroupManagementAction.applied,
                }"
                data-qa="top-menu_groups_dropdown-menu_apply-button"
            >
                <translate>Apply</translate>
            </app-button>
        </app-button-group>
    </ng-container>
</ng-template>

<ng-template #emptyState>
    <ng-container
        *ngIf="isGroupManagementAllowed$ | async; else askAdminAboutFirstGroup"
    >
        <div class="flex flex-col items-center">
            <app-system-response
                small
                title="Create your first group"
                message="GroupManagementFirstGroupDescription"
                responseType="empty"
                flatPrimaryButtonText="New group"
                flatPrimaryButtonIcon="plus"
                flatPrimaryButtonIconPosition="left"
                (flatPrimaryButtonClick)="createNewGroup()"
            >
            </app-system-response>
        </div>
    </ng-container>

    <ng-template #askAdminAboutFirstGroup>
        <ng-container
            *ngIf="userIsInRootGroup$ | async; else userIsNotInRootGroup"
        >
            <div class="flex flex-col items-center">
                <app-system-response
                    small
                    title="Ask your admin about groups"
                    message="GroupManagementAskAdminForFirstGroupDescription"
                    responseType="empty"
                >
                </app-system-response>
            </div>
        </ng-container>
        <ng-template #userIsNotInRootGroup>
            <ng-container>
                <div class="flex flex-col items-center p-4">
                    <app-system-response
                        small
                        title="Get in touch with your admin to see wagons"
                        message="GroupManagementAskAdminForGroupShare"
                        responseType="empty"
                    >
                    </app-system-response>
                </div>
            </ng-container>
        </ng-template>
    </ng-template>
</ng-template>
